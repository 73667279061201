import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription, timer } from 'rxjs';
import { map, scan, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'tdms-idle-time-out-modal',
  templateUrl: './idle-time-out-modal.component.html',
  styleUrls: ['./app.component.scss'],
})
export class IdleTimeOutModalComponent implements OnInit, OnDestroy {

  onLogout = new EventEmitter();
  onExtendSession = new EventEmitter();
  timerSub: Subscription;
  timer = 0;


  timer$ = timer(0, 1000).pipe(
    scan(acc => --acc, 60),
    takeWhile(x => x >= 0)
  )
  constructor(
    public dialogRef: MatDialogRef<IdleTimeOutModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idleState: string, countdown: number}) 
    { }


  ngOnInit() {
    this.timerSub = this.timer$.subscribe(
      val =>  {
        this.timer = val;
        if(val == 0) {
          this.timerSub.unsubscribe();
          console.log('Unsubscribing before logout.');

          this.logout();
        }
      }
    )
  }

  logout() {
    this.onLogout.emit();
  }

  extendSession() {
    this.onExtendSession.emit();
  }

  ngOnDestroy(): void {
      this.timerSub.unsubscribe();
      console.log('Unsubscribing in the modal window ....timer subscription...');
  }

}
