
import { Component, HostListener, Input, OnInit, SystemJsNgModuleLoaderConfig } from '@angular/core';
import { Router } from '@angular/router';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { Entry } from 'src/app/_shared/models/publication-results.model';

@Component({
  selector: 'tdms-workbench-pubfacet',
  templateUrl: './workbench-pubfacet.component.html',
  styleUrls: ['./workbench-pubfacet.component.scss']
})
export class WorkbenchPubfacetComponent implements OnInit {
  
  @Input() facets: any[]
  @Input() isPublication: boolean

  screenWidth:number;

  constructor(private router:Router, 
               private dataSetService: DatasetService) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener ('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  getFacet(facet):string{
    //console.log(this.facets);
    let facetName:string = this.isPublication ? facet.displayValue : facet.name;

    if(window.innerWidth > 1279){
      const len = Math.floor(window.innerWidth * 0.019);
      return facetName.length > len ? (facetName.slice(0,len) + '...') : facetName
    } else if(window.innerWidth > 959){
      const len = Math.floor(window.innerWidth * 0.034);
      return facetName.length > len ? (facetName.slice(0,len) + '...') : facetName
    } else {
      const len = Math.floor(window.innerWidth * 0.032);
      return facetName.length > len ? (facetName.slice(0,len) + '...') : facetName
    }

    return facetName;
  }

  goToDataset(){
    this.router.navigateByUrl("/dataset");
  }

  navigateToPublicationPage() {
    localStorage.setItem('pubCartState', JSON.stringify({ showCart: true}))
    this.router.navigate(['/dataset'],  {state: { showCart: true} });
  }

}
