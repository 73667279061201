import { Routes } from '@angular/router';
import { AnalysisFindContentComponent } from './analysis-dataset/analysis-find-content/analysis-find-content.component';
import { AnalysisRefineContentComponent } from './analysis-dataset/analysis-refine-content/analysis-refine-content.component';
import { AnalysisCreateContentComponent } from './analysis-dataset/analysis-create-content/analysis-create-content.component';
import { AnalysisDashboardComponent } from './analysis-dashboard/analysis-dashboard.component';
import { SelectVizComponent } from './analysis-dataset/select-viz/select-viz.component';
import { AnalysisSearchComponent } from './analysis-dataset/analysis-search/analysis-search.component';
const ɵ0 = () => import("./viz/viz.module.ngfactory").then(m => m.VizModuleNgFactory);
const CREATE_ACCOUNT_ROUTES = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'select', component: SelectVizComponent },
    { path: 'search', component: AnalysisSearchComponent },
    { path: 'search0', component: AnalysisFindContentComponent },
    { path: 'refinecontent', component: AnalysisRefineContentComponent },
    { path: 'createdataset', component: AnalysisCreateContentComponent },
    { path: 'dashboard', component: AnalysisDashboardComponent },
    { path: 'viz', loadChildren: ɵ0 },
];
export class AnalysisRoutingModule {
}
export { ɵ0 };
