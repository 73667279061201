import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService, TdmUser } from './auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SignupUserInfo, User } from '../workbenchDashboard/workbench.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean | UrlTree{
      return this.auth.isAuthenticated$.pipe(
        tap(loggedIn => {
          if (!loggedIn) {
            this.auth.login(state.url);
            return false;
          }

          let tdmUser: User =  this.auth.getCurrentlyLoggedInUser();

          if( this.auth.hasAccessToWorkBench()) {
            console.log("user is studio/analysis...User",state.url);
            return true;
          }  else {
            console.log("User is not authorized to given URL --> ", state.url);
            this.router.navigateByUrl("unauthorized");
            return false;
            
            
          }
        })
      );
    }
}
