import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { AnalysisRoutingModule } from './analysis-routing.module';
import { AnalysisDashboardComponent } from './analysis-dashboard/analysis-dashboard.component';
import { AnalysisFindContentComponent } from './analysis-dataset/analysis-find-content/analysis-find-content.component';
import { AnalysisRefineContentComponent } from './analysis-dataset/analysis-refine-content/analysis-refine-content.component';
import { AnalysisCreateContentComponent, ProjectCreateSuccessModal } from './analysis-dataset/analysis-create-content/analysis-create-content.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AnalysisDeleteModal, AnalysisProjectsComponent } from './analysis-dashboard/analysis-projects/analysis-projects.component';
import { DatasetModule } from '../dataset';
import { DocumentationComponent } from './documentation/documentation.component';
import { VizDashboardDocTabComponent } from './documentation/documentation tabs/viz-dashboard-doc-tab/viz-dashboard-doc-tab.component';
import { CreateProjectDocTabComponent } from './documentation/documentation tabs/create-project-doc-tab/create-project-doc-tab.component';
import { GeoAnalysisDocTabComponent } from './documentation/documentation tabs/geo-analysis-doc-tab/geo-analysis-doc-tab.component';
import { TopicModelingDocTabComponent } from './documentation/documentation tabs/topic-modeling-doc-tab/topic-modeling-doc-tab.component';
import { SelectVizComponent } from './analysis-dataset/select-viz/select-viz.component';
import { SelectVizFooterComponent } from './analysis-dataset/select-viz/select-viz-footer/select-viz-footer.component';
import { SentimentAnalysisDocTabComponent } from './documentation/documentation tabs/sa-doc-tab/sa-doc-tab.component';
import { AnalysisSearchComponent } from './analysis-dataset/analysis-search/analysis-search.component';
import { FacetsModule } from '../wb-dataset/facets/facets.module';
;



@NgModule({
  declarations: [
    AnalysisDashboardComponent,
    AnalysisFindContentComponent,
    AnalysisRefineContentComponent,
    AnalysisCreateContentComponent,
    AnalysisProjectsComponent,
    ProjectCreateSuccessModal,
    AnalysisDeleteModal,
    DocumentationComponent,
    VizDashboardDocTabComponent,
    CreateProjectDocTabComponent,
    GeoAnalysisDocTabComponent,
    TopicModelingDocTabComponent,
    SentimentAnalysisDocTabComponent,
    SelectVizComponent,
    SelectVizFooterComponent,
    AnalysisSearchComponent,
    // SubwayMapComponent
  ],
  imports: [
    CommonModule,
    AnalysisRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DatasetModule,
    FacetsModule
  ],
  entryComponents: [
    ProjectCreateSuccessModal,
    AnalysisDeleteModal,
    DocumentationComponent,
    
  ]
})
export class AnalysisModule { }
