/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./callback.component";
import * as i10 from "../auth.service";
var styles_CallbackComponent = [i0.styles];
var RenderType_CallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallbackComponent, data: {} });
export { RenderType_CallbackComponent as RenderType_CallbackComponent };
export function View_CallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(4, 114688, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_0 = "center center"; _ck(_v, 2, 0, currVal_0); var currVal_4 = 50; var currVal_5 = 3; _ck(_v, 4, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 4).diameter; var currVal_3 = i1.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_CallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-callback", [], null, null, null, View_CallbackComponent_0, RenderType_CallbackComponent)), i1.ɵdid(1, 114688, null, 0, i9.CallbackComponent, [i10.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallbackComponentNgFactory = i1.ɵccf("tdms-callback", i9.CallbackComponent, View_CallbackComponent_Host_0, {}, {}, []);
export { CallbackComponentNgFactory as CallbackComponentNgFactory };
