import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _m from 'moment';
import { PublicationService } from '../publication-results/publication-refinement.service';

@Component({
  selector: 'tdms-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss']
})
export class AppliedFiltersComponent implements OnInit {

  @Output() isApplyFilterTriggered: EventEmitter<boolean> = new EventEmitter();

  selectedPubFilterValues:string[] = localStorage.getItem('pubFilters') === null ? [] : JSON.parse(localStorage.getItem('pubFilters'));
  selectedDocFilterValues:string[] = localStorage.getItem('docFilters') === null ? [] : JSON.parse(localStorage.getItem('docFilters'));
  selectedSourceTypeFilterValues:string[] = localStorage.getItem('sourceFilters') === null ? [] : JSON.parse(localStorage.getItem('sourceFilters'));
  fDate:string = localStorage.getItem('fromDate') === null ? '' : JSON.parse(localStorage.getItem('fromDate')).slice(0,10);
  tDate:string = localStorage.getItem('toDate') === null ? '' : JSON.parse(localStorage.getItem('toDate')).slice(0,10);
  isDateFilter:boolean = this.fDate !== '' || this.tDate !== '';

  constructor(private pubservice: PublicationService) { }

  ngOnInit(): void {
    console.log(this.selectedPubFilterValues);
  }

  getDateFilter():string{
    if(this.fDate === ''){
      return "before " + _m(this.tDate).format('MMM DD, YYYY');
    }
    if(this.tDate === ''){
      return "after " + _m(this.fDate).format('MMM DD, YYYY');
    }
    return _m(this.fDate).format('MMM DD, YYYY') + " to " + _m(this.tDate).format('MMM DD, YYYY');
  }

  triggerFilters() {
    this.isApplyFilterTriggered.emit(true);
  }

  clearAllFilters() {
    this.pubservice.clearFilters();
    this.triggerFilters();
  }

  removeSourceTypeFilter(removeFilter:string){
    if(this.selectedSourceTypeFilterValues.filter(filter => filter !== removeFilter).length > 0){
      localStorage.setItem('sourceFilters', JSON.stringify(this.selectedSourceTypeFilterValues.filter(filter => filter !== removeFilter)))
    } else {
      localStorage.removeItem('sourceFilters');
    }
    this.triggerFilters();
  }

  removeDocTypeFilter(removeFilter:string){
    if(this.selectedDocFilterValues.filter(filter => filter !== removeFilter).length > 0){
      localStorage.setItem('docFilters', JSON.stringify(this.selectedDocFilterValues.filter(filter => filter !== removeFilter)))
    } else {
      localStorage.removeItem('docFilters');
    }
    
    this.triggerFilters();
  }

  removePubTypeFilter(removeFilter:string){
    if(this.selectedPubFilterValues.filter(filter => filter !== removeFilter).length > 0){
      localStorage.setItem('pubFilters', JSON.stringify(this.selectedPubFilterValues.filter(filter => filter !== removeFilter)))
    } else {
      localStorage.removeItem('pubFilters');
    }
    
    this.triggerFilters();
  }

  removeDateFilter(){
    localStorage.removeItem('fromDate');
    localStorage.removeItem('toDate');
    this.triggerFilters();
  }

}
