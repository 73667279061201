<div class="container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="40px">
    <h2 class="title">Steps to create an account</h2>
    <div class="subway" fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <div class="subway-row"  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div [ngClass]="step === 'create' ? 'icon-bubble create-step' : 'icon-bubble created-step'"><i class="bi-check2"></i></div>
            <h3 class="label">Provide information for account</h3>
        </div>

        <div class="connector"></div>

        <div class="subway-row"  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div class="icon-bubble"><i class="bi-envelope"></i></div>
            <h3 class="label">Check your email  to verify and log in</h3>
        </div>

        <div class="connector"></div>

        <div class="subway-row"  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div class="icon-bubble"><img src="../../../../assets/images/explore.svg" alt="explore" class="explore-icon"></div>
            <h3 class="label">Explore TDM Studio</h3>
        </div>
    </div>
</div>
