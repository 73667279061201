/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./app-header/admin-header.component.ngfactory";
import * as i3 from "./app-header/admin-header.component";
import * as i4 from "./auth/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "./app-header/app-header.component.ngfactory";
import * as i7 from "./app-header/app-header.component";
import * as i8 from "@angular/common";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./app-footer/app-footer.component.ngfactory";
import * as i11 from "./app-footer/app-footer.component";
import * as i12 from "./app.component";
import * as i13 from "@ng-idle/core";
import * as i14 from "./Pendo/pendo-one-trust-load.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-admin-header", [], null, null, null, i2.View_AdminHeaderComponent_0, i2.RenderType_AdminHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdminHeaderComponent, [i4.AuthService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-header", [], null, [[null, "keydown.escape"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).hideModal() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_AppHeaderComponent_0, i6.RenderType_AppHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i7.AppHeaderComponent, [i4.AuthService, i8.Location, i9.MatDialog, i5.Router, i4.AuthService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "main-container"], ["id", "main-container"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "skip-nav-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["aria-label", "Skip navigation"], ["class", "skip-nav"], ["role", "link"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.skipNav() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.skipNav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Skip navigation"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "main", [["id", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [["id", "app-content"], ["tabindex", "-1"]], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.resetNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "tdms-footer", [["tabindex", "2"]], null, null, null, i10.View_AppFooterComponent_0, i10.RenderType_AppFooterComponent)), i1.ɵdid(12, 114688, null, 0, i11.AppFooterComponent, [i5.ActivatedRoute, i5.Router, i8.Location], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAdmin; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.isAdmin; _ck(_v, 7, 0, currVal_1); _ck(_v, 10, 0); _ck(_v, 12, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-app", [], null, [["window", "beforeunload"], ["window", "load"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).setIdleTimeExpiry() !== false);
        ad = (pd_0 && ad);
    } if (("window:load" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).checkSessionExpiry() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i12.AppComponent, [i4.AuthService, i8.Location, i5.Router, i13.Idle, i9.MatDialog, i14.PendoOneTrustLoadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("tdms-app", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
