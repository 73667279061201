import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, forkJoin, of } from 'rxjs';
import { WorkbenchDataService } from '../services/workbench-dashboard/workbench-data.service';
import { catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private auth: AuthService, private wbService:WorkbenchDataService, private router:Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> {
        return forkJoin([this.auth.isAuthenticated$, this.wbService.getUserInfo().pipe(catchError(error => of(error)))]).pipe(
        map(([loggedIn, UserInfo]) => {
          if (!loggedIn) {
            this.auth.login(state.url);
            return false;
          } else if (UserInfo.pqadmin !== "true"){
            this.router.navigateByUrl("/workbenchdashboard");
            return false;
          }
            return UserInfo.pqadmin === "true";
        }));
    }  
}