import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Inject } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, SortDirection, MatCheckbox, MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import {IPublications} from '../../../_shared/models/pub.model';
import { SelectionModel } from '@angular/cdk/collections';
import * as _m from 'moment';
import { WorkbenchDataService} from 'src/app/services/workbench-dashboard/workbench-data.service';
import { PublicationService } from '../publication-refinecontent/publication-results/publication-refinement.service';
import { Subscription } from 'rxjs';
import { Hit } from 'src/app/_shared/models/publication-results.model';
import { EventEmitter } from 'events';
import { AuthtokenService } from 'src/app/auth/authtoken.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'tdms-publication-select',
  templateUrl: './publication-findcontent.component.html',
  styleUrls: ['./publication-findcontent.component.scss']
})
export class PublicationSelectComponent implements OnInit {

  constructor(private pubService: PublicationService, 
               private wbDataService: WorkbenchDataService,
               private pubservice: PublicationService,
               private dialog: MatDialog,
               private authTokenService: AuthtokenService,
               private titleService: Title) { }

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: string[] = ['select', 'publicationTitle', 'dateRange', 'id', 'sourceType', 'subject', 'fullText' ];
  noOfPubs: number;
  workbench:string;
  workbenchSubscription:Subscription;
  
  numberOfPubsDisplayed:string;
  numberOfPubsAvailable:number;
  currentSort:string = '';

  defaultSelection: any[] = localStorage.getItem('pubList') !== null ? JSON.parse(localStorage.getItem('pubList')) : [];
  selection = new SelectionModel<any>(true, this.defaultSelection);
  selectionSubject = new SelectionModel<string>(true, []);
  selectionSourceType = new SelectionModel<string>(true, []);
  selectionPublicationTitle = new SelectionModel<string>(true, []);
  selectionLocation = new SelectionModel<string>(true, []);

  isLoading:boolean = true;
  isResults:boolean = true;

  defaultPublications:Hit[] = []
  tableRequest: Subscription;
  length:number;
  maxPubCount = 4000;
  pageSize:number = 20;
  pageIndex:number = 0;
  search:string = '';
  isPubIdTip:boolean = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @ViewChild(MatPaginator, {static: true, read:true}) paginator: MatPaginator;

  @ViewChild('filterText', {static: true}) filterText: ElementRef;

  ngOnInit() {
    this.titleService.setTitle("Create new dataset: Choose publications");
    this.setWorkbenchId();
    this.getData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.noOfPubs = this.selection.selected.length;
    console.log('SELECTION MODEL ====> ', JSON.stringify(this.selection, null, 2));
    if(this.noOfPubs > 0){localStorage.setItem('selectedPubs', JSON.stringify(this.selection.selected.map(pub => pub.id)))};
  }

  ngOnDestroy(){
    this.workbenchSubscription.unsubscribe();
    if (this.tableRequest) { this.tableRequest.unsubscribe(); }
  }

  setWorkbenchId(){
    this.workbenchSubscription = this.wbDataService.currentWorkbenchId$.subscribe(currentId => {this.workbench = currentId});
    if (this.workbench === 'default workbenchId') {
      this.workbench = localStorage.getItem('workbench') !== null ? localStorage.getItem('workbench') :  'default workbenchId';
    } else {
      localStorage.setItem('workbench', this.workbench);
    }
  }

  getData(){
      this.tableRequest = this.pubService.getPublications(this.search, this.workbench, this.pageSize, this.pageIndex*this.pageSize).subscribe(resolverResponse => {
        this.isLoading = true;
        this.numberOfPubsAvailable = this.search ? this.numberOfPubsAvailable : resolverResponse.docsFound;
        this.length = resolverResponse.docsFound <= this.maxPubCount ? resolverResponse.docsFound : this.maxPubCount;
        this.dataSource.data = resolverResponse.hits ? resolverResponse.hits : [];        
        this.numberOfPubsDisplayed = resolverResponse.docsFound.toString();
        this.updateDisplayedCount();
        this.defaultPublications = this.dataSource.data;
        this.isLoading = false;
      });
  }

  getPubId(publication: any) {
    return publication.id;
  }

  getDateRange(publication:any){
    var beginDate:string = publication.field.find(element => element.name === 'startDate') ? publication.field.find(element => element.name === 'startDate').value.toString().substring(0,4) : '';
    var endDate:string = publication.field.find(element => element.name === 'endDate') ? publication.field.find(element => element.name === 'endDate').value.toString().substring(0,4) : '';
    endDate = endDate === "9999" ? "present" : endDate;   
    return endDate !== '' ? beginDate + " - " + endDate : beginDate;
  }

  getLocation(publication:any){
    return publication.field.find(element => element.name === 'location') ? publication.field.find(element => element.name === 'location').value.toString() : '';
  }

  isFullText(publication:any){
    return publication.field.find(element => element.name === 'hasFullText') ? publication.field.find(element => element.name === 'hasFullText').value.toString() : 'false';
  }

  getPublicationTitle(publication:any){
    var title =  publication.field.find(element => element.name === 'title') ? publication.field.find(element => element.name === 'title').value.toString() : '';
    return title.length > 100 ? title.substring(0,100) + '...' : title;
  }

  getSubject(publication:any){
    var subjects = publication.field.find(element => element.name === 'subject') ? publication.field.find(element => element.name === 'subject').value.toString() : '';
    return subjects.split('|').join(', ');
  }

  getSourceType(publication:any){
    return publication.field.find(element => element.name === 'sourceType') ? publication.field.find(element => element.name === 'sourceType').value.toString() : '';
  }

  applyFilter(filter: string) {
    this.search = filter ? filter : '';
    this.pageIndex = 0;
    this.isLoading = true;
    this.isResults = true;
    if(!filter){
      this.getData();
    } else {
      this.tableRequest = this.pubService.getPublications(filter, this.workbench, this.pageSize, 0).subscribe(resolverResponse => {
        this.numberOfPubsDisplayed = resolverResponse.docsFound.toString();
        if(this.numberOfPubsDisplayed === '0'){
          this.dataSource.data = this.defaultPublications;
          this.numberOfPubsDisplayed = this.numberOfPubsAvailable.toString()
          this.isResults = false;
          this.search = filter;
        } else {
          this.dataSource.data = resolverResponse.hits;
          this.length = resolverResponse.docsFound;
        }
        this.updateDisplayedCount();
        this.isLoading = false;
      });
    }
    
  }
  
  updateDisplayedCount():string{
    return this.numberOfPubsAvailable.toString() === this.numberOfPubsDisplayed.toString() 
      ? Number(this.numberOfPubsDisplayed).toLocaleString('en-GB') :Number(this.numberOfPubsDisplayed).toLocaleString('en-GB') +' of '+ Number(this.numberOfPubsAvailable).toLocaleString('en-GB');
  }

  isFilteredColumn(publication:IPublications){
      return this.selectionPublicationTitle.selected.lastIndexOf(publication.publicationTitle)!==-1 
      && 
      this.selectionSourceType.selected.lastIndexOf(publication.sourceType)!==-1 
      && this.selectionSubject.selected.lastIndexOf(publication.subject)!==-1 
      && this.selectionLocation.selected.lastIndexOf(publication.location)!==-1;
  }

  isSelected(row){
    return this.selection.selected.filter(sel => sel.id === row.id).length > 0;
  }

  toggleSelected(row){
    if(this.isSelected(row)){
      this.selection.deselect(this.selection.selected.filter(dataset => dataset.id === row.id)[0]);
    } else {
      this.selection.select(row);
    }
  }

  updateCount() {
    this.noOfPubs = this.selection.selected.length;
    localStorage.setItem('selectedPubs', JSON.stringify(this.selection.selected.map(pub => pub.id)));
    localStorage.setItem('pubList',  JSON.stringify(this.selection.selected));

    //Whenever there is a change in publication selection; clear the previous selection releated refinement section. 
    this.pubservice.clearRefinementData();
  }

  onPagination(event){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getData();
  }

  openPubCart(isShowSelected){
    if(isShowSelected){
      const dialogRef: MatDialogRef<PubCartModal> = this.dialog.open(PubCartModal, {  disableClose: true, });
      dialogRef.componentInstance.selected = this.selection.selected;
      dialogRef.afterClosed().subscribe(response => {
        if (response.applyChanges){
          this.selection = new SelectionModel<any>(true,  response.selectionList);
          this.updateCount();
        }
      })
    }
  }


  goToPubPage(event: Event, pubId: number) {
    event.stopPropagation();
    this.authTokenService.openPubPage(pubId);

  }

}

@Component({
  selector: 'pub-cart-modal',
  templateUrl: 'pub-cart-modal.html',
  styleUrls: ['./publication-findcontent.component.scss']
})
export class PubCartModal {

  selected:Hit[] = [];
  editedPubList:Hit[] = [];
  isPubIdTipCart:boolean = false;

  constructor(public dialogRef: MatDialogRef<PubCartModal>, 
              private wbDataService:WorkbenchDataService,
              private authTokenService: AuthtokenService){
    
  }

  ngOnInit() {
    this.selected.sort((a,b) => {
      let first = this.getPublicationTitle(a).startsWith("The ") ? this.getPublicationTitle(a).slice(4) : this.getPublicationTitle(a);
      let second = this.getPublicationTitle(b).startsWith("The ") ? this.getPublicationTitle(b).slice(4) : this.getPublicationTitle(b);
      return first.localeCompare(second, undefined, {
        numeric: true
      })
    });
    this.editedPubList = this.selected;
  }

  toggleSelected(pub:Hit){
    if(this.editedPubList.includes(pub)){
      this.editedPubList = this.editedPubList.filter(ePub => ePub !== pub);
    } else {
      this.editedPubList.push(pub);
    }
  }

  getDateRange(publication:any){
    var beginDate:string = publication.field.find(element => element.name === 'startDate') ? publication.field.find(element => element.name === 'startDate').value.toString().substring(0,4) : '';
    var endDate:string = publication.field.find(element => element.name === 'endDate') ? publication.field.find(element => element.name === 'endDate').value.toString().substring(0,4) : '';
    endDate = endDate === "9999" ? "present" : endDate;   
    return endDate !== '' ? beginDate + " - " + endDate : beginDate;
  }

  applyChanges(){
    this.dialogRef.close({applyChanges:true, selectionList:this.editedPubList});
  }

  getPublicationTitle(publication:Hit):string{
    var title =  publication.field.find(element => element.name === 'title') ? publication.field.find(element => element.name === 'title').value.toString() : '';
    return title.length > 100 ? title.substring(0,100) + '...' : title;
  }

  getSubject(publication:Hit):string{
    var subjects = publication.field.find(element => element.name === 'subject') ? publication.field.find(element => element.name === 'subject').value.toString() : '';
    return subjects.split('|').join(', ');
  }

  getSourceType(publication:Hit):string{
    return publication.field.find(element => element.name === 'sourceType') ? publication.field.find(element => element.name === 'sourceType').value.toString() : '';
  }

  goToPubPage(event: Event, pubId: number) {
    event.stopPropagation();
    this.authTokenService.openPubPage(pubId);

  }

  
}
