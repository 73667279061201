/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-expire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./email-expire.component";
import * as i10 from "../../auth/studio-user-signup.service";
import * as i11 from "@angular/router";
var styles_EmailExpireComponent = [i0.styles];
var RenderType_EmailExpireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailExpireComponent, data: {} });
export { RenderType_EmailExpireComponent as RenderType_EmailExpireComponent };
function View_EmailExpireComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "spinner"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(3, 114688, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_0 = "center"; _ck(_v, 1, 0, currVal_0); var currVal_4 = 50; var currVal_5 = 3; _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 3).diameter; var currVal_3 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
function View_EmailExpireComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "info_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You did not verify your email address."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click below to send a new verification to your university/institution email address"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send verification email"]))], null, null); }
export function View_EmailExpireComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailExpireComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailExpireComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EmailExpireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-email-expire", [], null, null, null, View_EmailExpireComponent_0, RenderType_EmailExpireComponent)), i1.ɵdid(1, 114688, null, 0, i9.EmailExpireComponent, [i10.StudioUserSignupService, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailExpireComponentNgFactory = i1.ɵccf("tdms-email-expire", i9.EmailExpireComponent, View_EmailExpireComponent_Host_0, {}, {}, []);
export { EmailExpireComponentNgFactory as EmailExpireComponentNgFactory };
