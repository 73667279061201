<div class="page" fxLayout="row" fxLayoutAlign="start start">

    
  
  
  <div *ngIf="dateRange$ | async as obsRes" class="results-container">

    <div class="search_header">
      <div class="search_bar" fxLayout="row" fxLayoutAlign="start center">
        <div class="search_div" fxLayout="row" fxLayoutAlign="start center">
            <input #searchBar type="text" 
                              ngClass="search" 
                              ngClass.md="search search-md" 
                              ngClass.lt-md="search search-ltMd"
                              placeholder='e.g. (energy OR “solar power” OR “solar technology” ) AND TITLE(solar)…'
                              [formControl]="searchField"
                              (keyup.enter)="search(searchField.value)"/>
            <span class ='clear'  tabindex="0" (keyup.enter)="clear()" (click)="clear()" aria-label="clear search bar text"><i class="bi bi-x"></i></span>
            <div class="search-options" (click)='openSearchTipsPage()' (keyup.enter)='openSearchTipsPage()'
            tabindex="0" aria-label="Search Tips: this link will open in a new tab">Search Tips</div>                  
          </div>
          <div ngClass="search_button" 
                ngClass.md="search_button-md" 
                ngClass.lt-md="search_button-ltMd" 
                fxLayoutAlign="center"
                tabindex="0" 
                role="button"
                aria-label="Search"
                (click)="search(searchField.value)" (keyup.enter)="search(searchField.value)"><i class="bi bi-search"></i></div>
      </div>
    </div>  
    <div  class="docResults">
      <!--For medium and larger screens-->
      <div ngClass="container" ngClass.md="container-md" fxShow.lt-md="false"
                          fxLayout="row"
                          fxLayoutAlign="start top"
                          fxLayout.xm="column">
  
        <div class="filters" fxFlex="25" fxLayout="column" *ngIf="obsRes[0].type === 'finish' && obsRes[1].type === 'finish'">
          <tdms-filterpanel 
                          [sourceFacets]='sourceFacets(obsRes[0].searchResp?.facets)'
                          [docFacets]='docFacets(obsRes[0].searchResp?.facets)'
                          [dbFacets]='dbFacets(obsRes[0].searchResp?.facets)'
                          [isWb]='false'
                          (isApplyFilterTriggered)='applyFilters(searchField.value)'>
          </tdms-filterpanel>
  
        </div>

        <div class="search-results" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' && isDocs(obsRes[0].searchResp?.docsFound)">

          <!--Top Search is commenting out for visualizations-->
          <div class="results" *ngIf="showTopSearch(obsRes)">
            <div class="top-search-title" tabindex="0">Top Searched By Researchers</div>
            <div class="top-search-wrapper">
              <div class="top-search-column">
                <div class="top-search" *ngFor="let search of topSearchLeft; index as i">
                  <div class="top-search-row">
                    <b>
                      <h2
                        *ngIf="!search.time"
                        tabindex="0" role="link"
                        (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''"
                        (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.name}}
                      </h2>
                      <h2
                        *ngIf="search.time"
                        class="black"
                        tabindex="0">{{search.name}}
                      </h2>
                    </b>
                    <i class="bi bi-info-circle tooltips"
                      tabindex="0"
                      role="contentinfo"
                      aria-label="About this collection"
                      (click)="showInfo(false,'left'+i)"
                      (mouseenter)="showInfo(true,'left'+i)"  
                      (mouseleave)="showInfo(true,'')"
                      (keydown.enter)="$event.target.blur();showInfo(false,'left'+i); ">
                      <span *ngIf="showTooltipHover == 'left'+i || showTooltip == 'left'+i" tabindex="0" (focusout)="showInfo(false,'')" autofocus>
                        <p tabindex="-1" class="tooltip-title">{{search.name}}</p><br>{{search.full_description}}
                      </span>
                    </i>
                    <div class="historic" *ngIf="search.time">
                      <span 
                        tabindex="0"
                        role="link"
                        (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''" 
                        (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.time}} </span>
                      <span *ngIf="search.historicTime">|</span>
                      <span 
                        *ngIf="search.historicTime"
                        tabindex="0"
                        role="link"
                        (click)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''"
                        (keydown.enter)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''">Historic {{search.historicTime}}</span>
                    </div>
                    <!-- <span *ngIf="!search.historicTime">{{search.time}} </span> -->
                  </div>
                  <p>{{search.description}}</p>
                </div>
              </div>
              <div class="top-search-column" >
                <div class="top-search" *ngFor="let search of topSearchRight; index as i">
                  <div class="top-search-row">                    
                    <b>
                      <h2
                        *ngIf="!search.time"
                        tabindex="0" role="link"
                        (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''"
                        (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.name}}
                      </h2>
                      <h2
                        *ngIf="search.time"
                        class="black"
                        tabindex="0">{{search.name}}
                      </h2>
                    </b>
                    <i class="bi bi-info-circle tooltips"
                    tabindex="0"
                    role="contentinfo"
                    aria-label="About this collection"
                    (click)="showInfo(false,'right'+i)"
                    (mouseenter)="showInfo(true,'right'+i)"
                    (mouseleave)="showInfo(true,'')"
                    (keydown.enter)="showInfo(false,'right'+i)">
                    <span *ngIf="showTooltipHover == 'right'+i || showTooltip == 'right'+i" tabindex="0" (focusout)="showInfo(false,'')" autofocus>
                      <p tabindex="-1" class="tooltip-title">{{search.name}}</p><br>{{search.full_description}}
                    </span>
                    </i>
                    <div class="historic" *ngIf="search.time">
                      <span 
                        tabindex="0"
                        role="link"
                        (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''" 
                        (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.time}} </span>
                      <span *ngIf="search.historicTime">|</span>
                      <span 
                        *ngIf="search.historicTime"
                        tabindex="0"
                        role="link"
                        (click)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''"
                        (keydown.enter)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''">Historic {{search.historicTime}}</span>
                    </div>
                    <!-- <span *ngIf="!search.historicTime">{{search.time}} </span> -->
                  </div>
                  <p>{{search.description}}</p>
                </div>
              </div>
            </div>

          </div>
          <!--End of top search -->
          <div class="results-meta-info-wrapper">
            <div class="results-wrapper-left">
              <div class="results-doc-count">
                <h2 tabindex="0" class="results-meta-info-count">{{ obsRes[0].searchResp?.docsFound | number }}</h2>
                <span class="results-meta-info-txt">Documents</span>
              </div>
              <div class="results-save-button">
                <!-- <span class="results-meta-date-published" *ngIf="obsRes[0].searchResp?.docsFound > 0"><span>{{ displayDateRange(obsRes[0].searchResp?.hits, obsRes[1].searchResp?.hits)}}</span> Date Published </span> -->
                <span class="results-meta-date-published" *ngIf="obsRes[0].searchResp?.docsFound <= 0"></span>
              </div>

            </div>
            <button class="create-button" [disabled]="obsRes[0].searchResp?.docsFound <= 0" (click)="createDataset(obsRes[0].searchResp?.docsFound)" (keydown.enter)="createDataset(obsRes[0].searchResp?.docsFound)">
              <span>Save Dataset</span>
              <span class="button-meta-text">Max 10 thousand documents</span>
            </button>
          </div>
          <div ngClass="results" ngClass.md="results-md" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' && obsRes[0].searchResp?.docsFound > 0">
              <tdms-wb-pub-results [results]= obsRes[0].searchResp?.hits 
                                        [docCount] = obsRes[0].searchResp?.docsFound
                                        [resultsAsc]= obsRes[1].searchResp?.hits >
              </tdms-wb-pub-results> 
          </div>
        </div>

        <div class="zero-results" role="alert" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' && !isDocs(obsRes[0].searchResp?.docsFound)">
          <p tabindex="0"><b>Your search found 0 results.</b></p>
          <p tabindex="0">Modify your search terms, date ranges, publication titles or other filters, and try again.</p>
          <span class="link" role="link" tabindex="0" (click)="openSearchTipsPage()" (keyup.enter)="openSearchTipsPage()" aria-label="Search tips, opens in new tab">Search tips</span>
        </div>

        <div class="center" *ngIf="obsRes[0].type === 'start' || obsRes[1].type === 'start'" >
          <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
        </div>

      </div>



      <!--For smaller screens-->
      <div ngClass="results-ltMd" fxShow.gt-sm="false">
        <div class="results" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' && showTopSearch(obsRes)">
          <div class="top-search-title" tabindex="0">Top Searched By Researchers</div>
          <div class="top-search-wrapper">
            <div class="top-search-column">
              <div class="top-search" *ngFor="let search of topSearchLeft; index as i">
                <div class="top-search-row">
                  <b>
                    <h2
                      *ngIf="!search.time"
                      tabindex="0" role="link"
                      (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''"
                      (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.name}}
                    </h2>
                    <h2
                      *ngIf="search.time"
                      class="black"
                      tabindex="0">{{search.name}}
                    </h2>
                  </b>
                  <i class="bi bi-info-circle tooltips"
                    tabindex="0"
                    role="contentinfo"
                    aria-label="About this collection"
                    (click)="showInfo(false,'left'+i)"
                    (mouseenter)="showInfo(true,'left'+i)"  
                    (mouseleave)="showInfo(true,'')"
                    (keydown.enter)="$event.target.blur();showInfo(false,'left'+i); ">
                    <span *ngIf="showTooltipHover == 'left'+i || showTooltip == 'left'+i" tabindex="0" (focusout)="showInfo(false,'')" autofocus>
                      <p tabindex="-1" class="tooltip-title">{{search.name}}</p><br>{{search.full_description}}
                    </span>
                  </i>
                  <div class="historic" *ngIf="search.time">
                    <span 
                      tabindex="0"
                      role="link"
                      (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''" 
                      (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.time}} </span>
                    <span *ngIf="search.historicTime">|</span>
                    <span 
                      *ngIf="search.historicTime"
                      tabindex="0"
                      role="link"
                      (click)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''"
                      (keydown.enter)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''">Historic {{search.historicTime}}</span>
                  </div>
                  <!-- <span *ngIf="!search.historicTime">{{search.time}} </span> -->
                </div>
                <p>{{search.description}}</p>
              </div>
            </div>
            <div class="top-search-column" >
              <div class="top-search" *ngFor="let search of topSearchRight; index as i">
                <div class="top-search-row">                    
                  <b>
                    <h2
                      *ngIf="!search.time"
                      tabindex="0" role="link"
                      (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''"
                      (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.name}}
                    </h2>
                    <h2
                      *ngIf="search.time"
                      class="black"
                      tabindex="0">{{search.name}}
                    </h2>
                  </b>
                  <i class="bi bi-info-circle tooltips"
                  tabindex="0"
                  role="contentinfo"
                  aria-label="About this collection"
                  (click)="showInfo(false,'right'+i)"
                  (mouseenter)="showInfo(true,'right'+i)"
                  (mouseleave)="showInfo(true,'')"
                  (keydown.enter)="showInfo(false,'right'+i)">
                  <span *ngIf="showTooltipHover == 'right'+i || showTooltip == 'right'+i" tabindex="0" (focusout)="showInfo(false,'')" autofocus>
                    <p tabindex="-1" class="tooltip-title">{{search.name}}</p><br>{{search.full_description}}
                  </span>
                  </i>
                  <div class="historic" *ngIf="search.time">
                    <span 
                      tabindex="0"
                      role="link"
                      (click)="applyTopSearch(search.moniker, search.name); searchBar.value = ''" 
                      (keydown.enter)="applyTopSearch(search.moniker, search.name); searchBar.value = ''">{{search.time}} </span>
                    <span *ngIf="search.historicTime">|</span>
                    <span 
                      *ngIf="search.historicTime"
                      tabindex="0"
                      role="link"
                      (click)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''"
                      (keydown.enter)="applyTopSearch(search.historicMoniker, search.name); searchBar.value = ''">Historic {{search.historicTime}}</span>
                  </div>
                  <!-- <span *ngIf="!search.historicTime">{{search.time}} </span> -->
                </div>
                <p>{{search.description}}</p>
              </div>
            </div>
          </div>
        </div>


        <div class="results-meta-info-wrapper" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' && isDocs(obsRes[0].searchResp?.docsFound)">
          <div class="results-wrapper-left">
            <div class="results-doc-count">
              <h2 tabindex="0" class="results-meta-info-count">{{ obsRes[0].searchResp?.docsFound | number }}</h2>
              <span class="results-meta-info-txt">Documents</span>
            </div>
            <div class="results-save-button">
              <!-- <span class="results-meta-date-published" *ngIf="obsRes[0].searchResp?.docsFound > 0"><span>{{ displayDateRange(obsRes[0].searchResp?.hits, obsRes[1].searchResp?.hits)}}</span> Date Published </span> -->
              <span class="results-meta-date-published" *ngIf="obsRes[0].searchResp?.docsFound <= 0"></span>
            </div>

          </div>
          <button class="create-button" [disabled]="obsRes[0].searchResp?.docsFound <= 0" (click)="createDataset(obsRes[0].searchResp?.docsFound)" (keydown.enter)="createDataset(obsRes[0].searchResp?.docsFound)">
            <span>Save Dataset</span>
            <span class="button-meta-text">Max 2 mln documents</span>
          </button>
        </div>
        
        <div class="zero-results"  role="alert" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' && !isDocs(obsRes[0].searchResp?.docsFound)">
          <p tabindex="0"><b>Your search found 0 results.</b></p>
          <p tabindex="0">Modify your search terms, date ranges, publication titles or other filters, and try again.</p>
          <span class="link" role="link" tabindex="0" (click)="openSearchTipsPage()" (keyup.enter)="openSearchTipsPage()" aria-label="Search tips, opens in new tab">Search tips</span>
        </div>

        <!-- <div class="results-meta-info-wrapper">
          <h2 tabindex="0" class="results-meta-info-count">{{ obsRes[0].searchResp?.docsFound | number }}</h2>
          <span class="results-meta-info-txt">Documents</span>
          <span class="results-meta-date-published" *ngIf="obsRes[0].searchResp?.docsFound > 0"><span class="date-bold">{{ displayDateRange(obsRes[0].searchResp?.hits, obsRes[1].searchResp?.hits)}}</span> Date Published </span>
          <span class="results-meta-date-published" *ngIf="obsRes[0].searchResp?.docsFound <= 0"></span>
          <button class="create-button" [disabled]="obsRes[0].searchResp?.docsFound <= 0" (click)="createDataset(obsRes[0].searchResp?.docsFound)" (keydown.enter)="createDataset(obsRes[0].searchResp?.docsFound)">
            <span>Save Dataset</span>
            <span class="button-meta-text">Max 2 mln documents</span>
          </button>
        </div> -->

        <ng-template [ngIf]="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' ">
          <tdms-wb-pub-results [results]= obsRes[0].searchResp?.hits 
                                    [docCount] = obsRes[0].searchResp?.docsFound
                                    [resultsAsc]= obsRes[1].searchResp?.hits>
          </tdms-wb-pub-results>
        </ng-template>
    
        <div class="center">
          <ng-template [ngIf]="obsRes[0].type === 'start' || obsRes[1].type === 'start'" ><mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner></ng-template>
        </div>
    
        <div *ngIf="obsRes[0].type === 'finish' && !isShowFilters" 
              ngClass="filter-tab" 
              (keydown.enter)="onToggleShowFilters()" 
              (click)="onToggleShowFilters()" 
              tabindex="0"> Filter your results
        </div>

        <!--FilterPanel overlay top of results page for small screens-->
        <div *ngIf="obsRes[0].type === 'finish' && obsRes[1].type === 'finish' && isShowFilters" 
                    ngClass="filter-overlay" 
                    fxLayout="column" 
                    fxLayoutAlign="start start" 
                    fxShow.gt-sm="false" #filterOverlay>
        
            <div class="filter-header" fxLayout="row" fxLayoutAlign="space-between center">
              <span class="close" (keydown.enter)="onToggleShowFilters()" (click)="onToggleShowFilters()" tabindex="0">n</span>
            </div>
            
        
            <div ngClass="filters" ngClass.lt-md="filters-ltMd" fxFlex *ngIf="obsRes[0].type === 'finish' && obsRes[1].type === 'finish'" >
              <!-- <tdms-applied-filters *ngIf="isAppliedFilters()" (isApplyFilterTriggered)='applyFilters(searchField.value)' ></tdms-applied-filters> -->
              <tdms-filterpanel 
                            [sourceFacets]=sourceFacets(obsRes[0].searchResp?.facets)
                            [docFacets]=docFacets(obsRes[0].searchResp?.facets)
                            [dbFacets]=dbFacets(obsRes[0].searchResp?.facets)
                            [isWb]='false'
                            (isApplyFilterTriggered)='applyFilters(searchField.value)'></tdms-filterpanel>
        
            </div>
          </div>  
        </div>
    </div>
  </div>    
</div>
