import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
export class InterceptorService {
    constructor(auth) {
        this.auth = auth;
        this.INTEGRATION_KEY = '70f290a6-58b7-4fe6-7e29-f130e698c442.eu';
    }
    intercept(req, next) {
        if (req.url.search('config/TDMStudio') === -1) {
            if (req.url.search('/api/pendo/') !== -1) {
                const pendoHeaders = {
                    'Content-Type': 'application/json',
                    'x-pendo-integration-key': this.INTEGRATION_KEY
                };
                const pendoReq = req.clone({
                    headers: new HttpHeaders(pendoHeaders)
                });
                return next.handle(pendoReq);
            }
            else {
                return this.auth.getTokenSilently$().pipe(mergeMap(token => {
                    const tokenReq = req.clone({
                        setHeaders: { Authorization: `Bearer ${token}` }
                    });
                    return next.handle(tokenReq);
                }), catchError(err => {
                    console.log("error:", err);
                    if (err.error === "login_required") {
                        return next.handle(req);
                    }
                    return throwError(err);
                }));
            }
        }
        return next.handle(req);
    }
}
InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.ɵɵinject(i1.AuthService)); }, token: InterceptorService, providedIn: "root" });
