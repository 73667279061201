<div>

    <div *ngIf="isLoading" class="spinner" fxLayoutAlign="center">
        <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>

    <div *ngIf="!isLoading" class='info_container'>
        <h1 class='title'>{{getTitle()}}</h1>
        <p class='info'>{{getInfo()}}</p>
        <a *ngIf="isLinkExpired" class='link' href="javascript:void(0);" (click)="resendEmail()">Send verification email</a>
        <!-- <a *ngIf="!isLinkExpired" class='link' href="/analysis/dashboard">Log in to TDM Studio</a> -->
        <div  class="login"
        tabindex="0"
        (click)="auth.login()"
        (keyup.enter)="auth.login()" role="button">Log in to TDM Studio</div>

    </div>

</div>
