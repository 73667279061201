import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tdms-geo-analysis-doc-tab',
  templateUrl: './geo-analysis-doc-tab.component.html',
  styleUrls: ['../doc-tab.component.scss']
})
export class GeoAnalysisDocTabComponent implements OnInit {

  selectedTabs:number[] = [];

  constructor() { }

  ngOnInit() {
  }
  
  isOpen(tab:number): boolean{
    if (this.selectedTabs.includes(tab)){ return true; }
    return false;
  }

  toggleTab(tab:number){
    if (this.selectedTabs.includes(tab)) {
      this.selectedTabs = this.selectedTabs.filter(t => t != tab);
    } else {
      this.selectedTabs.push(tab);
    }
  }

}
