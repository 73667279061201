import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { PublicationService } from 'src/app/dataset/selectContent/publication-refinecontent/publication-results/publication-refinement.service';
import * as _m from 'moment';


@Component({
  selector: 'tdms-date-facet',
  templateUrl: './date-facet.component.html',
  styleUrls: ['./date-facet.component.scss']
})
export class DateFacetComponent implements OnInit  {

  displayFilters$ = this.pubservice.displayFilter$;

  fromDate: Date;
  toDate: Date;
  maxDate: Date;
  yearCardSelected;

  toDateMin: Date = null;

  fromDateC: FormControl;
  toDateC: FormControl;

  inputFromDate:string = '';
  inputToDate:string = '';


  currSelfromDate: Date;
  currSelToDate: Date;

  years = [1, 5, 10, 50];
  selectedYear: number | null = null;


  constructor(private pubservice: PublicationService) { }

  ngOnInit() {
    this.fromDateC = new FormControl();
    this.toDateC = new FormControl();
    this.populateValuesFromLocalStorage();
    this.maxDate = new Date();
    this.yearCardSelected = +localStorage.getItem("yearCardSel");
  }

  private populateValuesFromLocalStorage() {
    let fDate = JSON.parse(localStorage.getItem('fromDate'));
    let tDate = JSON.parse(localStorage.getItem('toDate'));
    if(fDate != null && fDate.length>0 ) {

     let tmpFdate = fDate.substring(0,10).split('-');

     tmpFdate = tmpFdate[1] + '/' + tmpFdate[2] + '/' + tmpFdate[0];

      this.fromDateC = new FormControl(new Date(tmpFdate));
      this.toDateMin = new Date(new Date(tmpFdate));
    }



    if(tDate !=null && tDate.length>0) {
      let date = tDate.substring(0,10).split('-');
      date = date[1] + '/' + date[2] + '/' + date[0];
      this.toDateC = new FormControl(new Date(date));

    }
  }

  inputedFromDate(input){
    this.inputFromDate = input.trim();
  }

  inputedToDate(input){
    this.inputToDate = input.trim();
  }

  saveDate(whichDate: string, event: MatDatepickerInputEvent<Date>) {

    if(event.value === null) {
      if(_m(this.inputFromDate, "MM/YYYY").isValid()){
        var date = _m(this.inputFromDate, "MM/YYYY").toDate();
        this.inputFromDate = '';
        event.value = date;
        this.fromDateC = new FormControl(date);
      } else if(_m(this.inputToDate, "MM/YYYY").isValid()){
        var date = _m(this.inputToDate, "MM/YYYY").toDate();
        date = new Date(date.getFullYear(), date.getMonth()+1, 0)
        this.inputFromDate = '';
        event.value = date;
        this.toDateC = new FormControl(date);
      } else {
        localStorage.removeItem(whichDate);
      }
      
    }

    if(event.value !==null) {
      
      if(whichDate === 'fromDate') {

        if(this.inputFromDate.length == 4 && _m(this.inputFromDate, "YYYY").isValid()){
          var date = new Date(this.inputFromDate);
          date.setDate(date.getDate() + 1)
          event.value = date;
          this.fromDateC = new FormControl(date);
        }

        localStorage.setItem('fDate', JSON.stringify(_m(new Date(this.fromDateC.value)).utcOffset(0,true)));
        this.currSelfromDate =this.fromDateC.value;
        this.toDateMin = new Date(this.fromDateC.value);
      }

      if(whichDate === 'toDate') {

        if(this.inputToDate.length == 4 && _m(this.inputToDate, "YYYY").isValid()){
          var date = new Date(this.inputToDate);
          console.log(date);
          date.setFullYear(date.getFullYear() + 1)
          console.log(date);
          event.value = date;
          this.toDateC = new FormControl(date);
        }

        this.currSelToDate = this.toDateC.value;
        localStorage.setItem('tDate', JSON.stringify(_m(new Date(this.toDateC.value)).utcOffset(0,true)));
      }
      localStorage.removeItem('yearCardSel');
      this.yearCardSelected = 0;
      console.log("date change event triggered and captured in SaveDate ");
    }

    this.setToDateMin();


    this.pubservice.setDateFilterChange(true);


    console.log(event.value);
  }

  isYearFormat(input:string){
    if(_m(input, "YYYY").isValid()){
      var date = new Date(input);
      date.setDate(date.getDate() + 1)
      console.log(date);
    }
    
  }

  private setToDateMin() {
    let fDate = localStorage.getItem('fromDate');
    if(fDate !=null) this.toDateMin = new Date(JSON.parse(fDate));

  }

  //event generated from year-card.component
  onSelectionChanged(year: number, event:boolean) {
    //Toggle selected Year
    this.selectedYear = (!event || this.selectedYear === year) ? null : year;
    this.updateDateFields();
  }

  updateDateFields() {
    const currentDate: Date = new Date();

    localStorage.setItem("toDate", JSON.stringify(currentDate));
    localStorage.setItem('yearCardSel', JSON.stringify(this.selectedYear));
    switch (this.selectedYear) {
      case 1:
        console.log('switch year 1 ');
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        this.fromDateC = new FormControl(oneYearAgo.toISOString().split('T')[0]);
        this.toDateC = new FormControl(currentDate);
        localStorage.setItem("fromDate", JSON.stringify(oneYearAgo));
        break;
      case 5:
        console.log('switch year 5 ');
        const fiveYearAgo = new Date();
        fiveYearAgo.setFullYear(fiveYearAgo.getFullYear() - 5);
        this.fromDateC = new FormControl(fiveYearAgo.toISOString().split('T')[0]);
        this.toDateC = new FormControl(currentDate);
        localStorage.setItem("fromDate", JSON.stringify(fiveYearAgo));
        break;
      case 10:
        console.log('switch year 10 ');
        const tenYearAgo = new Date();
        tenYearAgo.setFullYear(tenYearAgo.getFullYear() - 10);
        this.fromDateC = new FormControl(tenYearAgo.toISOString().split('T')[0]);
        this.toDateC = new FormControl(currentDate);
        localStorage.setItem("fromDate", JSON.stringify(tenYearAgo));


        break;
      case 50:
        console.log('switch year 50 ');
        const fiftyYearAgo = new Date();
        fiftyYearAgo.setFullYear(fiftyYearAgo.getFullYear() - 50);
        this.fromDateC = new FormControl(fiftyYearAgo.toISOString().split('T')[0]);
        this.toDateC = new FormControl(currentDate);
        localStorage.setItem("fromDate", JSON.stringify(fiftyYearAgo));

        break;
      default:
        console.log('switch default ');
        this.fromDateC = new FormControl();
        this.toDateC = new FormControl();
        localStorage.removeItem("toDate");
        localStorage.removeItem("fromDate");
        localStorage.removeItem('yearCardSel');

        break;
    }
    


  }

  isCardSelected(yearCard: number): boolean {
    let yearCardSel = +localStorage.getItem("yearCardSel");

    if (yearCard === this.selectedYear || yearCard === yearCardSel) {
      return true;
    } else if (this.selectedYear == null){
      
    }
    return false;

    

  }

}
