import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../workbenchDashboard/workbench.model';
import { Router } from '@angular/router';

@Component({
  selector: 'tdms-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  tdmUser: User;
  email = null;

  constructor(public auth: AuthService, private router:Router) { }

  ngOnInit() {
    this.auth.loginActivity$.subscribe( (activity) => {
      this.setUser();
    })
  }

  setUser(){
    this.tdmUser =  this.auth.getCurrentlyLoggedInUser();
    if(this.tdmUser !==null) {
      this.email = this.tdmUser.email;
    }
  }

  isApi():boolean{
    return location.pathname.includes("api");
  }

  navigateTab(link){
    this.router.navigateByUrl('admin/' + link);
  }

}
