import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoContentRoutingModule } from './no-content.routing';
import { NoContentComponent }   from './no-content.component';

@NgModule({
  imports: [
    CommonModule,
    NoContentRoutingModule
  ],
  exports: [],
  declarations: [ NoContentComponent ],
  providers: [],
})
export class NoContentModule {
}
