/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/extended";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "./admin-header.component";
import * as i7 from "../auth/auth.service";
var styles_AdminHeaderComponent = [i0.styles];
var RenderType_AdminHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminHeaderComponent, data: {} });
export { RenderType_AdminHeaderComponent as RenderType_AdminHeaderComponent };
function View_AdminHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TDM Studio"]))], null, null); }
function View_AdminHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TDM Studio Internal Admin"]))], null, null); }
export function View_AdminHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "left-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["class", "tdm_logo"], ["routerLink", "/workbenchdashboard"], ["src", " ../../assets/images/TDM_logo.svg"], ["title", "Text Data Mining Studio Logo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "left divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "title_div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ProQuest"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminHeaderComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminHeaderComponent_2)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 12, "div", [["class", "middle-section"], ["role", "tablist"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "site-nav"], ["role", "tab"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTab("dashboard") !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.navigateTab("dashboard") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "p", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(18, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["Workbench Overview"])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "site-nav"], ["role", "tab"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTab("api") !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.navigateTab("api") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "p", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(23, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(24, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["Troubleshooting API"])), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["class", "right-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "logout"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.auth.logout() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.auth.logout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"])), (_l()(), i1.ɵeld(29, 0, null, null, 0, "div", [["class", "right divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "div", [["class", "account_info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "img", [["src", " ../../assets/images/PQ_Admin_icon.png"], ["title", "PQ icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/workbenchdashboard"; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.email === null); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.email !== null); _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.isApi() ? "nav" : "nav underline"); _ck(_v, 17, 0, currVal_3); var currVal_4 = (_co.isApi() ? "nav" : "nav underline"); _ck(_v, 18, 0, currVal_4); var currVal_5 = (_co.isApi() ? "nav underline" : "nav"); _ck(_v, 23, 0, currVal_5); var currVal_6 = (_co.isApi() ? "nav underline" : "nav"); _ck(_v, 24, 0, currVal_6); }, null); }
export function View_AdminHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-admin-header", [], null, null, null, View_AdminHeaderComponent_0, RenderType_AdminHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.AdminHeaderComponent, [i7.AuthService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminHeaderComponentNgFactory = i1.ɵccf("tdms-admin-header", i6.AdminHeaderComponent, View_AdminHeaderComponent_Host_0, {}, {}, []);
export { AdminHeaderComponentNgFactory as AdminHeaderComponentNgFactory };
