import { Resolve } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../services/workbench-dashboard/workbench-data.service";
import * as i2 from "../auth/auth.service";
/**
 * This resolver will executed only when user is already assigned to workbench.
 */
export class WorkbenchResolver {
    constructor(workbenchService, authService) {
        this.workbenchService = workbenchService;
        this.authService = authService;
    }
    resolve() {
        let user = this.authService.getCurrentlyLoggedInUser();
        if (this.authService.preLoadWorkbenchs()) {
            return forkJoin([
                this.workbenchService.getWorkbenches()
            ]);
        }
    }
}
WorkbenchResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkbenchResolver_Factory() { return new WorkbenchResolver(i0.ɵɵinject(i1.WorkbenchDataService), i0.ɵɵinject(i2.AuthService)); }, token: WorkbenchResolver, providedIn: "root" });
