import { Component, OnInit } from '@angular/core';
import { AnalysisService } from '../analysis/analysis.service';

@Component({
  selector: 'tdms-unautorized',
  templateUrl: './unautorized.component.html',
  styleUrls: ['./unautorized.component.scss']
})
export class UnautorizedComponent implements OnInit {

  link: string;
  constructor(private analysisService: AnalysisService) { }

  ngOnInit() {
    this.link =  this.getDahboradLink();
  }

  

  getDahboradLink(): string {
    return this.analysisService.getDashboardLink();
  }

}
