<div class='header'>

    <div class="left-section">
        <div class='logo'>
            <img routerLink="/workbenchdashboard" class="tdm_logo" src=" ../../assets/images/TDM_logo.svg" title="Text Data Mining Studio Logo">
        </div>
        <div class='left divider'></div>
        <div class='title_div'>
            <p class="subtitle" >ProQuest</p>
            <h1 class='title' *ngIf="email === null">TDM Studio</h1>
            <h1 class='title' *ngIf="email !== null">TDM Studio Internal Admin</h1>
        </div>
    </div>

    <div class="middle-section" role="tablist">
        <div class="site-nav" tabindex="0" role="tab" (click)="navigateTab('dashboard')" (keydown.enter)="navigateTab('dashboard')">
            <p [ngClass]="isApi() ? 'nav' : 'nav underline'">Workbench Overview</p>
        </div>
        <div class="site-nav" tabindex="0" role="tab" (click)="navigateTab('api')" (keydown.enter)="navigateTab('api')">
            <p [ngClass]="isApi() ? 'nav underline' : 'nav'">Troubleshooting API</p>
        </div>
    </div>

    <div class="right-section">
        <div class='logout' (click)="auth.logout()" (keydown.enter)="auth.logout()" tabindex="0">Logout</div>

        <div class='right divider'></div>
        <div class='account_info'>
            <div class='icon'><img src=" ../../assets/images/PQ_Admin_icon.png" title="PQ icon"></div>
        </div>
    </div>

</div>
