import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tdms-onbording-banner',
  templateUrl: './onbording-banner.component.html',
  styleUrls: ['./onbording-banner.component.scss']
})
export class OnbordingBannerComponent implements OnInit {

  @Output() closeOnBoard: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.closeOnBoard.emit(true);
  }

}
