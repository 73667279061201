<div class="EA_header">
    <div class="close_div">
        <p class="close" (click)="dialogRef.close()">n</p>
    </div>
    <h1 class="EA_title">Awesome, you have an account!</h1>
</div>

<mat-dialog-content >
    <div class="EA_content">
        <p class="EA_text">You already have a ProQuest TDM Studio account registered with this email address that you can use for TDM Studio. You can continue by logging in with this email or cancel and try a different email address.</p>
        <img class="EA_image" src='../../../assets/images/robot.png'>
    </div>
</mat-dialog-content>
<div class="EA_border"></div>
<mat-dialog-actions>
    <p class="EA_cancel_link" (click)="dialogRef.close()">Cancel and try a different email</p>
    <button mat-raised-button class="EA_login_button" color="primary" (click)="onLogin()" >Login and continue</button>
</mat-dialog-actions>