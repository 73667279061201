import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AccountAccessInfo, User, Workbench } from '../workbenchDashboard/workbench.model';
import { WorkbenchService } from '../workbenchDashboard/workbench.service';

@Component({
  selector: 'tdms-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  user: User;
  //studioSignupInfo$ =  this.studioUserSignupService.getSignupUserInfo();
  accessInfoSubscription: Subscription;
  workBenchAccess: { "create": boolean, "read": boolean};
  WB_EXISTS_MSG = 'WORKBENCH ALREADY CREATED';

  isLoading = false;
  errMsg: any;
  notAvailableMsg =  false;

  

  accountAccessInfo: AccountAccessInfo;

  constructor(private titleService: Title, 
              private workbenchService: WorkbenchService,
              private authService: AuthService,
              private router: Router) { 

  }


  ngOnInit() {
    this.titleService.setTitle('Welcome');
    this.user = this.authService.getCurrentlyLoggedInUser();
    //localStorage.setItem('displayWelcomePage', 'false')
  }


  displayDashboardCard() {            
    return this.authService.hasAccessToWorkBench();
  }

  displayVizCard() {
    return this.authService.hasAccessToVisualization();
  }

  retryCreateWb() {
    this.isLoading = true;
    this.notAvailableMsg = false;
    this.navigateToWorkbenchDashboardCheck();
  }
  


  navigateToWorkbenchDashboardCheck() {
    this.errMsg = null;
    let user: User =  JSON.parse(localStorage.getItem('user'))
    

    this.isLoading = true;

    if(user !== null && user.hasWorkbenchAccessResp) {
      if(user.hasWorkbenchAccessResp.read) {
        let route = '/workbenchdashboard';
        this.router.navigate([route]);
        //this.notAvailableMsg = true;
        this.isLoading = false;

        localStorage.setItem('lastDashboard', route);

      } else if( user.hasWorkbenchAccessResp.create) {
        this.createWorkBench(); 

      } else if(user.hasWorkbenchAccessResp && !user.hasWorkbenchAccessResp.read && !user.hasWorkbenchAccessResp.create) {
        window.open('https://proquest.iad1.qualtrics.com/jfe/form/SV_9RZ51zyg0Ow92iW', '_blank');
        this.isLoading = false;
      } else {
        this.errMsg = { error: 'Something wrong with your account \n\n\n'+JSON.stringify(this.user) };
        console.log('errMsg = '+ this.errMsg);
        this.isLoading = false;
      }
    } 
    
  }


  private createWorkBench() {
    this.workbenchService.createWorkBench()
                          .subscribe((res: any) => {
                            console.log('Post response while Creating workbench ', res);
                            let newlyCreatedWb: Workbench = res;
                            localStorage.setItem('newlyCreatedWb', JSON.stringify(newlyCreatedWb));


                            //Workbench status may not be accurate as soon as workbench creation..... Due to delay in the indexing to return the accurate wb status. 
                            //So manually updating the workbenchaccess response
                            this.workbenchService.getWorkbenchAcessCheck(true, true)
                                                  .subscribe(wbAccessRes => {
                                                    let userObj =  {...this.user};
                                                    userObj.hasWorkbenchAccessResp = wbAccessRes;

                                                    if(userObj.hasWorkbenchAccessResp.create) {
                                                      console.log(' Wb/access status is not in sync so UI is updating it manually...to create = false');
                                                      userObj.hasWorkbenchAccessResp.create = false
                                                      userObj.hasWorkbenchAccessResp.read = true

                                                    }
                                                    localStorage.setItem('user', JSON.stringify(userObj));
                                                    console.log('WelcomePage: Getting latest wbAccess after Workbench Creation..[OLD userObj=]', JSON.stringify(this.user, null, 2));
                                                    console.log('New = ', JSON.stringify(userObj, null, 2));

                                                    this.isLoading = false;
                                                    localStorage.setItem('workbench', res.workbenchId);
                                                    localStorage.setItem('lastDashboard', '/workbenchdashboard');
                                                    this.router.navigate(['/workbenchdashboard'])

                                                  });
                          },

                          (error) => {
                            if(error.status === 503) {
                              //this.notAvailableMsg = true;    
                              this.isLoading = false;
                              this.router.navigate(['/workbenchdashboard'])


                            } else if(error.status === 400) {
                              console.log(' Workbench already created...')
                              this.router.navigate(['/workbenchdashboard'])

                            } else if(error.status === 409  || error.status === 429  || error.status === 403 ) {
                              this.errMsg =  error;
                              this.isLoading = false;

                              console.error('Exception while creating workbench [WorkbenchDashboard Page]...', error)

                            } else {
                              this.errMsg =  error;
                              this.isLoading = false;
                            }
                          },

                          () => {
                            console.log("POST RESPONSE COMPLETED....");
                          })

  }

  /**
   * This method is used to check forward user to analysis dashboard page.
   * If the methods user is not exists (most likely for new users after singup process), then create methods user and send it to analysis dashboard page. 
   *  
   */
  navigateToAnalysisDashboardCheck() {
    this.errMsg = null;
    console.log('Inside navigate to AnalysisDashboard Check...');
    let user: User =  JSON.parse(localStorage.getItem('user'));

     if(user!==null && user.hasMethodsAccessResp && user.hasMethodsAccessResp.hasMethodsAccess) {
      this.isLoading = true;

      //Created methods user...
      this.workbenchService.createMethodsUser(true)
                           .subscribe( (resp) => {
                              console.log('Analysis user is created from Welcome page...');
                              let userObj =  {...user};
                              localStorage.setItem('user', JSON.stringify(userObj));
                              this.router.navigate(['/analysis/dashboard']);

                           },
                           (err) => {
                              if(err.status === 409 || err.status === 503 || err.status === 429 ) {
                                console.error('Bad Request: User already exists');
                                this.errMsg = err;
                              }
                              this.isLoading = false;
                           }, 
                           () => {
                              console.log('Analysis User creation complete..');
                              this.isLoading = false;
                              //this.router.navigate(['/analysis/dashboard']);
                           })
                          

    }  else {
      this.errMsg = 'Something went wrong... Please logout and log back in';
    }


    }


  

  ngOnDestroy(): void {
      this.errMsg = null;
      if(this.accessInfoSubscription) 
        this.accessInfoSubscription.unsubscribe();
  }




}
