import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tdmsExternalLink]'
})
export class ExternalLinkDirective {
  @Input('tdmsExternalLink') url: string;

  constructor(private el: ElementRef, private rederer: Renderer2) { }

  @HostBinding('attr.href') get exeternalLink() {
    return this.url;
  }

  @HostBinding('attr.target') get externalLinkTarget() {
    return '_blank';
  }
}
