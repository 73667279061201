import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WorkbenchDataService } from '../services/workbench-dashboard/workbench-data.service';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

/**
 * This resolver will executed only when user is already assigned to workbench. 
 */
@Injectable({ providedIn: 'root'})
export class WorkbenchResolver implements Resolve<any> {

  constructor(private workbenchService: WorkbenchDataService, private authService: AuthService) {}

  resolve() {
    let user =  this.authService.getCurrentlyLoggedInUser();

    if(this.authService.preLoadWorkbenchs()) {
      return forkJoin([
          this.workbenchService.getWorkbenches()
        ]);
    } 

  }
}
