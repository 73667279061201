import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DatasetRoutingModule } from './dataset.routing'
import { DatasetComponent } from './dataset.component';
import { PubCartModal, PublicationSelectComponent } from './selectContent/publication-findcontent/publication-findcontent.component';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule, MatSortModule, MatPaginatorModule, MatCheckboxModule} from '@angular/material';
import { DatasetHeaderComponent } from './dataset-header/dataset-header.component';
import { PublicationRefineContentComponent } from './selectContent/publication-refinecontent/publication-refinecontent.component';
import { DatasetFooterComponent } from './dataset-footer/dataset-footer.component';
import { PublicationCreatedatasetComponent, SuccessModal } from './selectContent/publication-createdataset/publication-createdataset.component';
import { PublicationResultsComponent } from './selectContent/publication-refinecontent/publication-results/publication-results.component';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { SharedModule } from '../shared/shared.module';
import { DocfacetComponent } from './selectContent/publication-refinecontent/facet/docfacet.component';
import { PubfacetComponent } from './selectContent/publication-refinecontent/facet/pubfacet.component';
import { ShowhideCheckboxDirective } from '../directives/showhide-checkbox.directive';
import { CheckboxGroupComponent } from './selectContent/publication-refinecontent/facet/checkbox-group.component';
import { DatefacetComponent } from './selectContent/publication-refinecontent/facet/datefacet.component';
import { DatabaseFindcontentComponent } from './selectContent/database-findcontent/database-findcontent.component';
import { WorkbenchPubfacetComponent } from './selectContent/publication-refinecontent/facet/workbench-pubfacet.component';
import { AppliedFiltersComponent } from './selectContent/publication-refinecontent/facet/applied-filters.component';


@NgModule({
  imports: [
    CommonModule,
    DatasetRoutingModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MaterialModule,
    FlexLayoutModule,
    MatCheckboxModule,
    SharedModule
  ],
  declarations: [
    DatasetComponent,
    DatasetHeaderComponent,
    PublicationSelectComponent,
    DatasetFooterComponent,
    PublicationRefineContentComponent,
    PublicationCreatedatasetComponent,
    PublicationResultsComponent,
    ClickOutsideDirective,
    ShowhideCheckboxDirective,
    DocfacetComponent,
    PubfacetComponent,
    CheckboxGroupComponent,
    SuccessModal,
    DatefacetComponent,
    DatabaseFindcontentComponent,
    PubCartModal,
    WorkbenchPubfacetComponent,
    AppliedFiltersComponent,
  ],
  exports: [
    PubfacetComponent,
    DocfacetComponent,
    PublicationResultsComponent,
    DatasetFooterComponent,
    AppliedFiltersComponent,
    CheckboxGroupComponent,
    DatefacetComponent,
    WorkbenchPubfacetComponent


  ],
  entryComponents: [
    SuccessModal,
    PubCartModal
  ]
})
export class DatasetModule {
}
