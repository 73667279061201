import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { mergeMap, catchError } from 'rxjs/operators';
import { nextTick } from 'q';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private readonly INTEGRATION_KEY = '70f290a6-58b7-4fe6-7e29-f130e698c442.eu';

  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    if (req.url.search('config/TDMStudio') === -1) {

      if (req.url.search('/api/pendo/') !== -1){
        const pendoHeaders = { 
          'Content-Type': 'application/json',
          'x-pendo-integration-key': this.INTEGRATION_KEY }
        const pendoReq = req.clone({
          headers: new HttpHeaders(pendoHeaders)
        })
        return next.handle(pendoReq);

      } else {
        
        return this.auth.getTokenSilently$().pipe(
          mergeMap(token => {
            const tokenReq = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` }
            });
            return next.handle(tokenReq);
          }),
          catchError(err => {
            console.log("error:", err);
            if(err.error === "login_required"){
              return next.handle(req);
            }
            return throwError(err);
          })
        );
      }
    }
    return next.handle(req);

  }

}