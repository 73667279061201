<h2 class="title">Welcome to TDM Studio Visualizations</h2>
<p class="sub_text">TDM Studio Visualizations allows discoveries using text and data mining without coding. It is designed for users of all levels to quickly spot trends and generate insights using newspaper articles available on the ProQuest Platform.</p>


<div class="info_tab" (click)="toggleTab(1)">
    <div class="tab_bar">
        <h3 class="tab_title">Using the Dashboard</h3>
        <div [ngClass]="isOpen(1) ? 'up_arrow' : 'down_arrow'"></div>
    </div>
    
    <div class="tab_content column" *ngIf="isOpen(1)">
        <p class="tab_text">Once you have created projects, they will appear on the Visualization Dashboard where you can have up to five projects at a time. Newly created projects are grayed out until they are ready for exploration. You can use your browser’s refresh button to check on the status of new projects. Each project listing provides a summary of the search, the number of documents, and the newspaper titles being analyzed.</p>
        <img class="img_center img_size" src="../../../../../assets/images/Documentation/dashboard-main.png" alt="Viz Page Screenshot" >
        <p class="tab_text">To view or delete a project, select the project listing you are interested in exploring and then select <b>Show actions</b>. You can open the visualization for a specific project by selecting the appropriate visualization button (e.g. “Open Geographic Visualization”). </p>
        <img class="img_center img_size" src="../../../../../assets/images/Documentation/dashboard-project.png" alt="Viz Project Screenshot" >
    </div>
</div>
