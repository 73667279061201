import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { PublicationService } from '../publication-results/publication-refinement.service';
import { Entry} from 'src/app/_shared/models/publication-results.model';
import { Subscription } from 'rxjs';
import * as JsSearch from 'js-search';
import { CheckBoxItem } from './checkboxItem';

@Component({
  selector: 'tdms-docfacet',
  templateUrl: './docfacet.component.html',
  styleUrls: ['./docfacet.component.scss']
})
export class DocfacetComponent implements OnInit, OnDestroy {


  @Input() pubFacets: Entry[];
  @Input() sourceFacets: Entry[];
  @Input() docFacets: Entry[];
  @Input() isAnalysis: boolean = false;
  @Input() stickyHeight: number = 92;


  displayFilters$ = this.pubservice.displayFilter$;
  showApplyFilters: boolean = false;
  showApplyFiltersForDates: boolean = false;
  showApplyFiltersForFullTextLimiter: boolean = false;


  dataFacetItems = new Array<CheckBoxItem>();
  sourceFacetItems =  new Array<CheckBoxItem>();

  @Output() isApplyFilterTriggered: EventEmitter<boolean> = new EventEmitter();
  @HostListener('window:keydown.space', ['$event'])
    spaceEvent(event: any) {
        if(event.target.className == "checkbox"){
          event.preventDefault();
        }
    }
  
  
  initialLoad: boolean = false;
  docCount:number = 0;
  sourceCount:number = 0;
  currentPubSearch:string = "";
  filterValue: string;
  
  pubFacetSelection = [];
  pubFilterSelection = [];
  docFacetSelection = [];
  docList = [];
  sourceFacetSelection = [];
  sourceList = [];

  //User selected filters;
  selectedPubFilterValues:CheckBoxItem[] = [];
  selectedDocFilterValues:CheckBoxItem[] = [];
  selectedsourcTypeFilterValues:CheckBoxItem[] = [];

  dateFilterSubscription: Subscription;
  fullTextLimiterSubscription: Subscription;
  

  constructor(private pubservice: PublicationService) { }


  ngOnInit() {
    //populate docFacetCheckboxModel...
    this.pubFacetSelection = this.pubFacets ? this.pubFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count)) : [];
    this.pubFilterSelection = this.pubFacetSelection;
    this.docFacetSelection = this.docFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count));
    this.docList = this.docFacetSelection.length < 12 ? this.docFacetSelection : this.docFacetSelection.slice(0,12);
    this.docCount = this.docFacetSelection.length - this.docList.length;
    this.sourceFacetSelection = this.sourceFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count));
    this.sourceList = this.sourceFacetSelection.length < 12 ? this.sourceFacetSelection : this.sourceFacetSelection.slice(0,12);
    this.sourceCount = this.sourceFacetSelection.length - this.sourceList.length;
    this.initialLoad = true;
    this.populatedSelectedFilterValues();
    this.showFilterButton();
    this.dateFilterSubscription = this.pubservice.dateFilter$.subscribe(val => {
     this.showApplyFiltersForDates = this.pubservice.isDatesSelected()
    })

    this.fullTextLimiterSubscription = this.pubservice.fullTextLimitter$.subscribe(val => {
      this.showApplyFiltersForFullTextLimiter = val;
    })



    //Show Filter for FullTextLimiter
  }

  isChecked(type:string, facet):boolean{
    if(type == 'pub'){
      return this.selectedPubFilterValues.includes(facet.value);
    } else if(type == 'source'){
      return this.selectedsourcTypeFilterValues.includes(facet.value);
    } else if(type == 'doc'){
      return this.selectedDocFilterValues.includes(facet.value);
    }
    return false;
  }

  onDocFacetChange(values) {
    this.selectedDocFilterValues = values;
    this.initialLoad = false;
    this.showFilterButton();
  }

  onSourceTypeFacetChange(values) {
    this.selectedsourcTypeFilterValues = values;
    this.initialLoad = false;
    this.showFilterButton();
  }

  onAnalysisDocFacetChange(values) {
    if(this.selectedDocFilterValues.includes(values)){
      this.selectedDocFilterValues = this.selectedDocFilterValues.filter(fac => fac !== values)
    } else { this.selectedDocFilterValues.push(values) };
    this.initialLoad = false;
    this.pubservice.setFilterChecked(true);
    this.showApplyFilters = true;
  }

  onAnalysisSourceTypeFacetChange(values) {
    if(this.selectedsourcTypeFilterValues.includes(values)){
      this.selectedsourcTypeFilterValues = this.selectedsourcTypeFilterValues.filter(facet => facet !== values)
    } else { this.selectedsourcTypeFilterValues.push(values) };
    this.initialLoad = false;
    this.pubservice.setFilterChecked(true);
    this.showApplyFilters = true;
  }

  onAnalysisPublicationFacetChange(values) {
    if(this.selectedPubFilterValues.includes(values)){
      this.selectedPubFilterValues = this.selectedPubFilterValues.filter(facet => facet !== values)
    } else { this.selectedPubFilterValues.push(values) };
    this.initialLoad = false;
    this.pubservice.setFilterChecked(true);
    this.showApplyFilters = true;
  }


  private populatedSelectedFilterValues() {
    this.selectedPubFilterValues = localStorage.getItem('pubFilters') === null ? [] : JSON.parse(localStorage.getItem('pubFilters'));
    this.selectedDocFilterValues = localStorage.getItem('docFilters') === null ? [] : JSON.parse(localStorage.getItem('docFilters'));
    this.selectedsourcTypeFilterValues = localStorage.getItem('sourceFilters') === null ? [] : JSON.parse(localStorage.getItem('sourceFilters'));

  }
  private showFilterButton() {
    if( this.selectedDocFilterValues.length > 0 || 
        this.selectedsourcTypeFilterValues.length > 0 ||
        this.selectedPubFilterValues.length > 0 ) {
       this.pubservice.setFilterChecked(true);
       this.showApplyFilters = true;
    } else {
        this.pubservice.setFilterChecked(false);
        this.showApplyFilters = false;
    }

  }

  applyFilters(){
    localStorage.setItem('sourceFilters', JSON.stringify(this.selectedsourcTypeFilterValues));
    localStorage.setItem('docFilters', JSON.stringify(this.selectedDocFilterValues));
    localStorage.setItem('pubFilters', JSON.stringify(this.selectedPubFilterValues));
    if(localStorage.getItem('tDate')){
      localStorage.setItem('toDate', localStorage.getItem('tDate')); 
      localStorage.removeItem('tDate');
    }
    if(localStorage.getItem('fDate')){
      localStorage.setItem('fromDate', localStorage.getItem('fDate'));
      localStorage.removeItem('fDate');
    }
    this.triggerFilters();
  }

  triggerFilters() {
    this.isApplyFilterTriggered.emit(true);
  }

  clearAllFilters() {
    this.pubservice.clearFilters();
    this.triggerFilters();
  }

  public displayClearFilters(): boolean | false {
    return this.pubservice.displayClearFilters();

  }

  ngOnDestroy() {
    if(this.dateFilterSubscription != null)
      this.dateFilterSubscription.unsubscribe();
  }

  applySearchFilter(filterValue: string) {
    if(filterValue === this.currentPubSearch){ return }

    this.currentPubSearch = filterValue;
    let results;

    console.log('filter Value ='+filterValue)
    let copyAllPubs:CheckBoxItem[] =  [...this.pubFacetSelection];


    const searcher = new JsSearch.Search('label');
    searcher.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    searcher.addIndex('value');
    searcher.addIndex('label');
    searcher.addDocuments(copyAllPubs);
    


    if(filterValue !=null && filterValue.length == 0) {
      this.pubFilterSelection = copyAllPubs;
      results = copyAllPubs;
    } else {
      results =  searcher.search(filterValue)
      console.log(results);
    }

     this.pubFilterSelection =  results;

  }

  clearSearchFilter(event: any) {
    let filterValue =  event;

    if(filterValue !=null && filterValue.length== 0) {
      this.applySearchFilter(filterValue);
    }
  }

  
  showMore(type:string){
    if(type === 'source'){
      this.sourceList = this.sourceFacetSelection;
    } else if (type === 'doc') {
      this.docList = this.docFacetSelection;
    }
  }



}
