import { Injectable } from '@angular/core';
import { OneTrustService } from './one-trust.service';
import { PendoService } from './pendo.service';

/**
 * Class used to load pendo and one trust scripts as string <script> tags.  Doing it this way to try and prevent any weird async issues, and to better match Clarivate's standard.
 */
@Injectable({
  providedIn: 'root'
})
export class PendoOneTrustLoadService {

  constructor(private pendoService: PendoService, private oneTrustService: OneTrustService) {
//    console.log('Pendo - One Trust Load Service - Begin loading');
    this.loadPendoScript();
    this.oneTrustService.bindWrapperToWindow();
    if(!location.origin.includes('.dev')){
      this.loadOneTrust();
    }
    console.log('Pendo - One Trust Load Service - Done loading');
  }

  /**
   * Manually load the Pendo script
   */
  private loadPendoScript() {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('class', 'optanon-category-C0003');

    script.text = `(function(apiKey) {
	  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
      })('2d46cce0-8d06-49de-4185-3ec9e5e782a8');`;

    const headerEl = document.getElementsByTagName('head')[0];
    if (headerEl) {
      headerEl.appendChild(script);
    }
  }

  /**
   * Manually load the one trust scripts
   */
  private loadOneTrust() {
    let cookieBannerId = "15495670-c116-4ad3-bb4d-1625f90d192d";
    if (window.location.host !== 'tdmstudio.proquest.com') {
      cookieBannerId += '-test';
    }
    const script1 = document.createElement('script');
    script1.setAttribute('type', 'text/javascript');
    script1.setAttribute('src', `https://cdn.cookielaw.org/consent/${cookieBannerId}/OtAutoBlock.js`);

    const script2 = document.createElement('script');
    script2.setAttribute('type', 'text/javascript');
    script2.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    script2.setAttribute('charset', 'UTF-8');
    script2.setAttribute('data-domain-script', cookieBannerId);

    const header = document.getElementsByTagName('head')[0];
    if (header) {
      header.appendChild(script1);
      header.appendChild(script2);
    }
  }

}