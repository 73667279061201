<div ngClass="filterPanel" ngClass.lt-md="filterPanel filterPanel-sm" fxLayout="column" *ngIf="sourceFacets !=null">
    <div ngClass.gt-sm="scroll">

        <!-- Applied filters -->
        <div class="facetSection"  *ngIf="appliedFilters.length > 0">
            <div class="applied-filters-header">
                <h2 ngClass="facetFiltersHeader">Applied filters</h2>
                <p class="facetClearAll" (click)="clearAllFilters()" (keyup.enter)="clearAllFilters()" tabindex="0" role="link">Clear all</p>
            </div>
            <div [ngClass]="showApplied ? 'af-expand' : 'applied-filters-display'">
                <div class="applied-filters" #aFilters>
                    <div class="filter" *ngFor="let filter of appliedFilters; index as i"
                        (click)="clearFilter(filter)" (keyup.enter)="clearFilter(filter)" 
                        tabindex="0" role="button">
                        <p class="filter-label" attr.aria-label="remove filter: {{filter.value}}">{{filter.value}}</p><i class="bi bi-x"></i>
                    </div>
                </div>
            </div>
            <br>
            <div class="facetViewAll" tabindex="0" *ngIf="isExpandable" (click)="showApplied = !showApplied" (keyup.enter)="showApplied = !showApplied">{{showApplied ? 'View Less' : 'View All'}}</div>
        </div>

        <tdms-date-facet></tdms-date-facet>

        <!--Publication facet-->
        <div class="facetSection" >
            <div fxLayout="row" fxLayoutAlign="start center">
                <h2 ngClass="facetHeader" ngClass.md="facetHeader-md">Publication title</h2>
            </div>    
            <!-- <div class="facetContent" fxLayout="column" *ngIf="!isPubFacetLoading; else pubfacetLoading">
                <tdms-wb-checkbox-group 
                    [options]=pubFacetSelection class="facetLineItem"
                    [selectedValues]="selectedPubFilterValues"
                    (toggle)="onPublicationFacetChange($event)"></tdms-wb-checkbox-group>
            </div>
            <ng-template #pubfacetLoading><mat-spinner [diameter]="15" [strokeWidth]="2"></mat-spinner></ng-template> -->
            <div class="facetViewAll" tabindex="0" (click)="onViewPubs('pub')" (keyup.enter)="onViewPubs('pub')">View All</div>
        </div>
        <!-- <div class="bottom">&nbsp;</div> -->
        
        
        <!--Database type facet-->
        <div class="facetSection" *ngIf="docFacets !=null ">
            <h2 ngClass="facetHeader" ngClass.md="facetHeader-md">Databases</h2>
            <div class="facetViewAll" tabindex="0" (click)="onViewPubs('database')" (keyup.enter)="onViewPubs('database')">View All</div>
        </div>
        

        <!--sourceType facet-->
        <div class="facetSection">
            <div fxLayout="row" fxLayoutAlign="start center">
                <h2 ngClass="facetHeader" ngClass.md="facetHeader-md">Source Type</h2>
            </div>    
            <div class="facetContent" fxLayout="column">
                <tdms-wb-checkbox-group 
                    [options]=sourceFacetSelection class="facetLineItem"
                    [selectedValues]="selectedSourceTypeFilterValues"
                    (toggle)="onSourceTypeFacetChange($event)"></tdms-wb-checkbox-group>
            </div>
        </div>
        <!-- <div class="bottom">&nbsp;</div> -->

        <!--Document type facet-->
        <div class="facetSection" *ngIf="docFacets !=null ">
            <h2 ngClass="facetHeader" ngClass.md="facetHeader-md">Document Type</h2>
            <div class="facetContent">
                <tdms-wb-checkbox-group 
                    [options]="docFacetSelection"
                    [selectedValues]="selectedDocFilterValues"
                    (toggle)="onDocFacetChange($event)"></tdms-wb-checkbox-group>
            </div>
        </div>


        <div class="bottom">&nbsp;</div>
    </div>

    
    <div class="apply-wrapper"
        fxLayout="row" 
        fxLayoutAlign="start center">
        <div class="applyButton"><button ngClass="apply-button" ngClass.lt-lg="apply-button" (click)='applyFilters()'>Apply</button></div>
        <div class="cancelWB" (click)="clearAllFilters()" (keyup.enter)="clearAllFilters()" tabindex="0">Clear all</div>
    </div>
</div>