import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RouterStateSnapshot } from '@angular/router';
import {Location} from '@angular/common';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'tdms-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./_app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

    @Input() display: boolean = true

    currentYear:number = new Date().getFullYear()
    contactUsUrl  = 'https://support.proquest.com/submitcase';
    termsAndConditionsUrl  = 'https://about.proquest.com/go/supplementalterms-tdmstudio';
    accessibilityUrl = 'https://support.proquest.com/articledetail?id=kA13r000000FyLNCA0';
   // currentRoute: string;
    
    constructor(private route: ActivatedRoute, private router: Router, private location: Location) {}

    ngOnInit() {
       this.display = this.location.path().indexOf('analysis/viz') === -1? true : false;

       this.router.events.pipe(
           filter((event: RouterEvent) => event instanceof NavigationEnd)
       ).subscribe( res => {
            console.log('currentRoute =>', res.url);
            this.display = res.url.indexOf('analysis/viz') === -1? true : false;
            const mainContent = document.getElementById('main-container');
            if (mainContent) {mainContent.focus();}
       })


    }


}
