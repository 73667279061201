<div class="EA_header">
    <div class="close_div">
        <p class="close" (click)="dialogRef.close()">n</p>
    </div>
    <h1 class="NA_title">We are sorry…</h1>
</div>

<mat-dialog-content >
    <div class="EA_content">
        <p class="EA_text">You do not have access to TDM Studio. Please reach out to your university librarian to request access.</p>
        <img class="EA_image" src='../../../assets/images/flamenco-no-comments.png'>
    </div>
</mat-dialog-content>
<div class="EA_border"></div>
<mat-dialog-actions>
    <p class="NA_cancel_link" (click)="dialogRef.close()">Cancel and try a different email</p>
</mat-dialog-actions>