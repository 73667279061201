import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StudioUserSignupService {
    constructor(http) {
        this.http = http;
        this.apiPath = '/api/us';
        this.userManagerApiPath = '/api/um';
    }
    getSignupUserInfo() {
        return this.http.get(this.apiPath + '/user');
    }
    getAccountAccessInfo(accountId) {
        return this.http.get(this.apiPath + '/accounts/' + accountId + '/access');
    }
    getUserAccounts(email) {
        const apiUrl = this.apiPath + '/accounts?email=' + encodeURIComponent(email);
        return this.http.get(apiUrl);
    }
    getAccount(acctId) {
        const apiUrl = this.apiPath + '/accounts/' + acctId + '/access';
        return this.http.get(apiUrl);
    }
    getUserStatus(email) {
        const apiUrl = this.apiPath + '/user/' + encodeURIComponent(email) + '/status';
        return this.http.get(apiUrl);
    }
    getStudioUserStatus(email) {
        const apiUrl = this.userManagerApiPath + '/user/' + encodeURIComponent(email) + '/status';
        return this.http.get(apiUrl);
    }
    createAccount(userData, acctInfo) {
        var userEmail = userData.email;
        var user = {
            email: userEmail.toLowerCase(),
            emailVerified: false,
            password: userData.password,
            accountId: acctInfo.accountId
        };
        const apiUrl = this.apiPath + '/user';
        return this.http.post(apiUrl, user);
    }
    verifyEmail(email, verificationCode) {
        const apiUrl = this.apiPath + '/user/' + encodeURIComponent(email) + '/verify?code=' + verificationCode;
        return this.http.get(apiUrl);
    }
    resendVerificationEmail(email) {
        const apiUrl = this.apiPath + '/user/' + encodeURIComponent(email) + '/resendverification';
        return this.http.put(apiUrl, null);
    }
}
StudioUserSignupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudioUserSignupService_Factory() { return new StudioUserSignupService(i0.ɵɵinject(i1.HttpClient)); }, token: StudioUserSignupService, providedIn: "root" });
