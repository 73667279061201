import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { MethodsAccessCheckResponse, User } from 'src/app/workbenchDashboard/workbench.model';
import { WorkbenchService } from 'src/app/workbenchDashboard/workbench.service';
import { AnalysisUser } from '../models/account.model';

@Component({
  selector: 'tdms-analysis-dashboard',
  templateUrl: './analysis-dashboard.component.html',
  styleUrls: ['./analysis-dashboard.component.scss']
})
export class AnalysisDashboardComponent implements OnInit {

  isSampleProject:boolean = false;
  isHover:boolean = false;

  isLoading = false;
  errMsg: any;
  user: User;

  constructor(private router: Router, private authService: AuthService, private workbenchService: WorkbenchService) {}

  ngOnInit() {
    this.user = this.authService.getCurrentlyLoggedInUser();
    this.verifyLoggedUserAndCreateUser();
    
  }

  /**
   * This method is used to verify currently logged in user has methods user established or not. 
   * If not it will create methods user before giving dashboard content to the user. 
   * 
   */
  verifyLoggedUserAndCreateUser() {

    
    if(this.user!==null &&
        this.user.hasMethodsAccessResp && 
        this.user.hasMethodsAccessResp.hasMethodsAccess) {

      this.isLoading = true;

      //Created methods user...
      this.workbenchService.createMethodsUser(true)
                           .subscribe( (resp: MethodsAccessCheckResponse) => {
                              console.log('Analysis user is created from Welcome page...');
                              let userObj =  {...this.user};
                              userObj.hasMethodsAccessResp =  resp;
                              localStorage.setItem('user', JSON.stringify(userObj));
                              this.isLoading = false;
                              this.router.navigate(['/analysis/dashboard']);
                           },
                           (err) => {
                              if(err.status === 409 || err.status === 503 || err.status === 429 ) {
                                console.error('Bad Request: User already exists');
                                this.errMsg = err;
                              }
                              this.isLoading = false;
                           }, 
                           () => {
                              console.log('Analysis User creation complete..');
                              this.isLoading = false;
                           })
      }
                          


  }



  search() {
    this.router.navigate(['analysis/search']);
  }

  navagateToGeoViz() {
    this.router.navigate(['analysis/viz/geoanalysis'])
  }

  toggleProjects(toggle:boolean){
    this.isSampleProject = toggle;
    localStorage.setItem("isSampleProject",this.isSampleProject.toString());
  }

}
