import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tdms-analysis-find-content',
  templateUrl: './analysis-find-content.component.html',
  styleUrls: ['./analysis-find-content.component.scss']
})
export class AnalysisFindContentComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  submitSearch(searchTerm: string) {
    localStorage.setItem('searchTerm', searchTerm);
    this.clearStorage();
    this.router.navigate(['analysis/refinecontent']);
  }

  private clearStorage(){
	    var clearKeys:string[] = [ "vDocCount", "vsReq", "vPubList","vPubFilters","pubFacets","fromDate","toDate","docFilters","fullTextLimiter","sourceFilters"];
	    clearKeys.forEach(key => {
	      localStorage.removeItem(key);
	    })
	  }
}
