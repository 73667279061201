<div class="ps-close" >
    <i  #modal_first class="bi bi-x"
        tabindex="0" aria-label="close modal"
        (click)="dialogRef.close()" (keydown.enter)="dialogRef.close()" (keydown.tab)="loopTabindex('firstTabIndex',$event)">
    </i>
</div>
<div class="ps-container">    
    <div class="ps-header">
        <h2 class="ps-title" tabindex="0">{{getModalTitle()}}</h2>
        <div class="ps-search-wrapper">
            <i class="bi bi-search" (click)="onSearch(modal_search_box.value)"></i>
            <input type="text" class="ps_search"
                #modal_search_box
                type="text" 
                class="ps-search" 
                role="searchbox"
                attr.aria-label="{{searchPlaceholder}}"
                placeholder="{{searchPlaceholder}}"
                (keyup.enter)="onSearch(modal_search_box.value)"
                (keyup)="onLiveSearch(modal_search_box.value)"
                [(ngModel)] = "text" >
            <i *ngIf="text != ''" class="bi bi-x ps-close-search" 
                tabindex="0" aria-label="clear text"
                (click)="onSearch(''); text=''" (keydown.enter)="onSearch('')">
            </i>
        </div>
    </div>

    <div class="applied-filters-display">
        <div class="applied-filters" #aFilters>
            <div class="filter" *ngFor="let filter of selectedItems; index as i"
                (click)="clearFilter(filter)" (keyup.enter)="clearFilter(filter)" 
                tabindex="0" role="button">
                <p class="filter-label" attr.aria-label="remove filter: {{this.data.filterType == 'pub' ? filter.title : filter.name}}">{{this.data.filterType == 'pub' ? filter.title : filter.name}}</p><i class="bi bi-x"></i>
            </div>
        </div>
    </div>
    
    <div *ngIf="data.filterType == 'pub'" class="ps-table-header">
        <div class="ps-header-cell ps-header-name" > Publication title 
            <!-- (click)="isAsc = !isAsc; sortPub();" (keydown.enter)="isAsc = !isAsc; sortPub();"> Publication title  -->
            <!-- <div [ngClass]="isAsc ? 'bi bi-triangle-fill' : 'ps-carot-down bi bi-triangle-fill'" ></div> -->
        </div>
        <div class="ps-header-cell ps-date">Date <i class="bi bi-question-circle" tabindex="0"><span class="tooltiptext-date">These are the years of coverage for each publication. This can be useful for distinguishing between different versions of similar publications.</span></i></div>
        <div class="ps-header-cell ps-id">ID <i class="bi bi-question-circle" tabindex="0"><span class="tooltiptext">The Publication ID is a unique number to identify the publication. This can be valuable when working with multiple publications which have the same name for example different versions of The New York Times.</span></i></div>
        <div class="ps-header-cell ps-source">Source Type</div>
        <div class="ps-header-cell ps-subject">Subject</div>
        <div class="ps-header-cell ps-fulltext">Full text</div>
    </div>

    <div *ngIf="data.filterType == 'database'" class="ps-table-header">
        <div class="ps-header-cell ps-description" > Database 
             <!-- (click)="isAsc = !isAsc; sortDb();" (keydown.enter)="isAsc = !isAsc; sortDb();"> Database  -->
            <!-- <div [ngClass]="isAsc ? 'bi bi-triangle-fill' : 'ps-carot-down bi bi-triangle-fill'" ></div> -->
        </div>
        <div class="ps-header-cell ps-description">Description</div>
        <div class="ps-header-cell ps-fulltext">Full text</div>
    </div>



    <div class="ps-table-container" *ngIf="data.filterType == 'pub'">
        <div  class="center" *ngIf = 'isLoading'>
            <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
        </div>
        <div class="ps-table" *ngIf = '!isLoading'>
            <!-- <p class="table-label">All</p> -->
            <div    *ngFor="let pub of tableList; let i = index;"
                    [ngClass]=" i % 2 === 0 ? 'ps-row ps-grey' : 'ps-row'" 
                    tabindex="0"
                    (click)="togglePubSelection(pub)"
                    (keyup.enter)="togglePubSelection(pub)"
                    role="checkbox"
                    attr.aria-checked="{{isChecked(pub)}}">
                <div [ngClass]="isChecked(pub) ? 'ps-checkbox ps-check' : 'ps-checkbox'" role="checkbox"></div>
                <div class="ps-cell ps-name">
                    <a (click)="goToPubPage($event, pub.id)" (keydown.enter)="goToPubPage($event, pub.id)" target="_blank" rel="noopener" tabindex="0">
                      {{getTitle(pub.field)}}
                      <span class="ps-screen-reader-only">(opens in a new tab)</span>
                    </a>
                </div>
                <div class="ps-cell ps-date">{{getDate(pub.field)}}</div>
                <div class="ps-cell ps-id">{{pub.id}}</div>
                <div class="ps-cell ps-source">{{getSourceType(pub.field)}}</div>
                <div class="ps-cell ps-subject">{{getSubject(pub.field)}}</div>
                <div class="ps-cell ps-fullText">{{getFullText(pub.field)}}</div>
            </div>
        </div>
    </div>

    <div class="ps-table-container" *ngIf="data.filterType == 'database'" >
        <div  class="center" *ngIf = 'isLoading'>
            <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
        </div>
        <div class="ps-table" *ngIf = '!isLoading'>
            <!-- <p class="table-label">All</p> -->
            <div    *ngFor="let db of tableList; let i = index;"
                    [ngClass]=" i % 2 === 0 ? 'ps-row ps-grey' : 'ps-row'"
                    tabindex="0"
                    role="checkbox"
                    attr.aria-checked="{{isChecked(db)}}"
                    attr.aria-checked="{{isChecked(db)}}"
                    (click)="toggleDbSelection(db)"
                    (keyup.enter)="toggleDbSelection(db)"
                    (keyup.space)="toggleDbSelection(db)">
                <div [ngClass]="isChecked(db) ? 'ps-checkbox ps-check' : 'ps-checkbox'" role="checkbox"></div>
                <div class="ps-cell ps-database">
                    <a (click)="goToDbPage($event, db.moniker)" (keydown.enter)="goToDbPage($event, db.moniker)" target="_blank" rel="noopener" tabindex="0">
                        {{getDbTitle(db)}}
                        <span class="ps-screen-reader-only">(opens in a new tab)</span>
                    </a>
                </div>
                <div class="ps-cell ps-description" [matTooltip]="db.description" matTooltipClass="tooltip">{{getDbDescription(db)}}</div>
                <div class="ps-cell ps-fullText">{{getDbFullText(db)}}</div>
            </div>
        </div>
    </div>

    <div class="ps-footer" fxLayout="row" fxLayoutAlign="start center">
        <div class="ps-button" (click)="onDone()" (keydown.enter)="onDone()" tabindex="0" role="button">Done</div>
        <p class="ps-cancel" (click)="clearSelection()" (keydown.enter)="clearSelection()" tabindex="0" role="link" #modal_last (keydown.tab)="loopTabindex('lastTabIndex',$event)">Clear</p>
    </div>
</div>