import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, forkJoin, of, throwError } from "rxjs";
import {
  WorkbenchResponse,
  UserInfo,
  WbStatus,
  WbAction,
  WbUserTypes,
  WbUserType,
} from "src/app/workbenchDashboard/workbench.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, delay, map, tap } from "rxjs/operators";
import { MetaDataExportLimit } from "src/app/_shared/metadata-export.model";
import {
  MetadataFileLocationInfo,
  WosFileLocationInfo,
} from "src/app/shared/models/dataset.model";

@Injectable({ providedIn: "root" })
export class WorkbenchDataService {
  private workbenchIdSource = new BehaviorSubject("default workbenchId");
  private workbenchSelectionSource = new BehaviorSubject("default workbenchId");
  private workbenchId: string;
  currentWorkbenchId$ = this.workbenchIdSource.asObservable();
  currentlySelectedWorkbench = this.workbenchSelectionSource.asObservable();
  apiRootForWb = "/api/wb";
  apiRootForUm = "/api/um";

  collaboratorTestData: {
    wbId: string;
    hasWos: boolean;
    isCollaborator: boolean;
  }[] = [
    {
      wbId: "devlt10",
      hasWos: true,
      isCollaborator: true,
    },
    {
      wbId: "devlt7",
      hasWos: false,
      isCollaborator: true,
    },
    {
      wbId: "devlt15",
      hasWos: true,
      isCollaborator: false,
    },
    {
      wbId: "devlt1",
      hasWos: true,
      isCollaborator: true,
    },
  ];

  constructor(private http: HttpClient) {}

  newWorkbenchId(newWorkbenchId: string) {
    this.workbenchId = newWorkbenchId;
    this.workbenchSelectionSource.next(newWorkbenchId);
    localStorage.setItem("workbench", newWorkbenchId);
  }

  changeWorkbenchId() {
    this.workbenchIdSource.next(this.workbenchId);
  }

  getWorkbenches(): Observable<WorkbenchResponse> {
    console.log("getWorkbenches ....>");
    const apiURL = "api/wbm/workbench";
    console.log("apiUrL =", apiURL);
    return this.http.get<WorkbenchResponse>(apiURL);
  }

  getWorkbenchById(wbId: string): Observable<any> {
    const apiURL = "api/wbm/workbench/" + wbId;
    return this.http.get<WorkbenchResponse>(apiURL);
  }

  getNoteBookName(wbId: string): Observable<string> {
    const apiURL = "api/maintain/wbm/workbench/" + wbId;
    return this.http.get(apiURL).pipe(
      map((resp: any) => {
        return resp.workbench.notebookName;
      })
    );
  }

  getWbInfo(wbId: string): Observable<any> {
    const apiURL = "api/maintain/wbm/workbench/" + wbId;
    return this.http.get(apiURL).pipe(
      tap((resp: any) => {
        console.log("wbInfo response =====> ", resp);
      })
    );
  }

  getAdminWorkbenches(
    count: number,
    offset: number
  ): Observable<WorkbenchResponse> {
    let params = new HttpParams();
    params = params.append("count", count.toString());
    params = params.append("offset", offset.toString());

    const apiURL = "api/wbm/admin/workbench";
    return this.http.get<WorkbenchResponse>(apiURL, { params: params });
  }

  getAdminWorkbenchUsers(wbId: string): Observable<any> {
    const apiURL = "api/wbm/admin/users?workbenchId=" + wbId;
    return this.http.get<WorkbenchResponse>(apiURL);
  }

  createUser(user: UserInfo): Observable<any> {
    const apiUrl = this.apiRootForUm + "/admin/user";
    return this.http.post(apiUrl, user);
  }

  addUserToWorkbench(userId: string, wbId: string): Observable<any> {
    const apiUrl = "api/wbm/admin/workbench/" + wbId + "/user/" + userId;
    return this.http.post(apiUrl, null);
  }

  deleteUserFromWorkbench(userId: string, wbId: string): Observable<any> {
    const apiUrl = "api/wbm/admin/workbench/" + wbId + "/user/" + userId;
    return this.http.delete(apiUrl);
  }

  getUserInfo(): Observable<UserInfo> {
    console.log("getUserInfo ....>");
    const apiURL = "/api/um/user";
    console.log("getUserInfo apiUrl ==>", apiURL);
    return this.http.get<UserInfo>(apiURL);
  }

  getWorkbenchLink(workbenchId: string): Observable<WorkbenchResponse> {
    console.log("getWorkbencheLink ....>");
    const apiURL = "api/wbm/workbench/" + workbenchId + "/accesslink/";
    console.log("apiUrL =", apiURL);

    return this.http.get<WorkbenchResponse>(apiURL, {
      responseType: "text" as "json",
    });
  }

  getWorkbenchStatusById(workbenchId: string): Observable<WbStatus> {
    const apiURL = "api/wbm/workbench/" + workbenchId + "/status";
    return this.http.get<WbStatus>(apiURL);
  }

  getWbMaintenanceStatusById(workbenchId: string): Observable<any> {
    const apiURL = "/api/maintain/wbm/workbench/" + workbenchId;
    return this.http.get<any>(apiURL);
  }

  updateWbStatusById(
    workbenchId: string,
    wbAction: WbAction
  ): Observable<WbStatus> {
    const apiURL = "api/wbm/workbench/" + workbenchId + "/status";
    return this.http.post<WbStatus>(apiURL, wbAction);
  }

  updateWbMaintanceToggle(workbenchId: string, payLoad: any): Observable<any> {
    const apiURL = "api/maintain/wbm/workbench/" + workbenchId + "/setMaintain";
    return this.http.put(apiURL, payLoad);
  }

  getDataset(datasetId: string) {
    const apiUrl = "api/cm/corpus/" + datasetId;
    return this.http.get(apiUrl);
  }

  getDatasetList(): Observable<any> {
    const apiUrl = "/api/cm/corpus?workbenchId=" + this.workbenchId;
    return this.http.get(apiUrl);
  }

  deleteDataset(datasetId: string): Observable<any> {
    const apiUrl = "/api/cm/corpus/" + datasetId;
    return this.http.delete(apiUrl, { responseType: "text" });
  }

  getGisStatus(wbId: string) {
    const apiUrl = "/api/wbm/workbench/" + wbId + "/subscription/gis";
    return this.http.get(apiUrl);
  }

  getWosSubscriptionStatus(wbId: string) {
    const apiUrl = "/api/wbm/workbench/" + wbId + "/subscription/wos";
    return this.http.get(apiUrl);
  }

  hasAccessToWos(wbId: string): Observable<boolean> {
    const subscriptionAPiUrl =
      "/api/wbm/workbench/" + wbId + "/subscription/wos";
    const wbUserTypeApiUrl = "/api/wbm/workbench/" + wbId + "/user/type";

    const wosSubscriptionInfo$ = this.http.get<any>(subscriptionAPiUrl);
    const wbUserTypes$ = this.http.get<any>(wbUserTypeApiUrl);
    return forkJoin([wosSubscriptionInfo$, wbUserTypes$]).pipe(
      map(([wosSubcriptionRes, userTypesRes]) => {
        console.log(
          " => wosSubcriptionRes =",
          JSON.stringify(wosSubcriptionRes)
        );
        console.log(" => userTypesRes =", JSON.stringify(userTypesRes));

        let hasWos = wosSubcriptionRes.hasWos;
        let collaboratorItem = userTypesRes.userTypes.find((item) => {
          if (item.type === "collaborator") return item;
        });

        if (hasWos && collaboratorItem.status === "false") return true;

        return false;
        //return this.simulateCollaboratorWithMockData(wbId); //For Testing only...
      })
    );
  }

  private simulateCollaboratorWithMockData(wbId: string): boolean {
    console.log(
      "simulating collaborator with test data ==> ",
      JSON.stringify(this.collaboratorTestData)
    );

    let { hasWos, isCollaborator } = this.collaboratorTestData.find(
      (wbInfo) => wbInfo.wbId === wbId
    );

    if (!hasWos || (hasWos && isCollaborator)) return false;

    return true;
  }

  getMetadataExportLimitTest(
    wbId: String,
    trialOrSub?: string
  ): Observable<MetaDataExportLimit> {
    const apiURL = "/api/cm/metadataexport/" + wbId + "/exportLimit";
    return this.http.get<MetaDataExportLimit>(apiURL).pipe(
      catchError((error) => {
        console.error("Ann error occurred:", error);
        if (trialOrSub === "SubReached") {
          return this.getSubLimitReached();
        } else if (trialOrSub === "TrialReached") {
          return this.getTrialLimitReached();
        } else if (trialOrSub === "TrialRemaining") {
          return this.getTrialLimit();
        } else {
          return this.getSubLimit();
        }
      })
    );
  }

  getMetadataExportLimit(
    wbId: String,
    trialOrSub?: string
  ): Observable<MetaDataExportLimit> {
    const apiURL = "/api/cm/metadataexport/" + wbId + "/exportLimit";
    return this.http.get<MetaDataExportLimit>(apiURL).pipe(
      catchError((error) => {
        console.error("Ann error occurred:", error);
        const errorObj = {
          status: error.status,
          statusText: error.statusText,
          message: "An error occurred while getting export limit...",
        };
        return throwError(errorObj);
      })
    );
  }

  private getSubLimit(): Observable<MetaDataExportLimit> {
    return of({
      subscriptionType: "CUSTOMER",
      metadataRowsRemaining: 2000000,
      metadataRowsWeeklyLimit: 5000000,
    });
  }

  private getSubLimitReached(): Observable<MetaDataExportLimit> {
    return of({
      subscriptionType: "CUSTOMER",
      metadataRowsRemaining: 0,
      metadataRowsWeeklyLimit: 5000000,
    });
  }

  private getTrialLimit(): Observable<MetaDataExportLimit> {
    return of({
      subscriptionType: "TRIAL",
      metadataRowsRemaining: 231,
      metadataRowsWeeklyLimit: 10000,
    });
  }

  private getTrialLimitReached(): Observable<MetaDataExportLimit> {
    return of({
      subscriptionType: "TRIAL",
      metadataRowsRemaining: 0,
      metadataRowsWeeklyLimit: 10000,
    });
  }

  public getExportMetadataLink(
    corpusid: string,
    exportType: string
  ): Observable<MetadataFileLocationInfo> {
    const apiUrl =
      "/api/cm/corpus/" + corpusid + "/docmetadata/" + exportType + ".csv";
    return this.http.get<MetadataFileLocationInfo>(apiUrl);
  }

  public getExportWOSLink(corpusid: string): Observable<WosFileLocationInfo> {
    const apiUrl = "/api/cm/corpus/wos/" + corpusid + "/export";
    return this.http.get<WosFileLocationInfo>(apiUrl);
  }

  /**
   * This method helps to identify the userType (collaborator or not)
   * @param wbId
   * @returns WbUserType[]
   */
  public getWbUserTypes(wbId: string): Observable<WbUserType[]> {
    const apiUrl = "/api/wbm/workbench/" + wbId + "/user/type";
    return this.http.get<WbUserType[]>(apiUrl);
  }
}
