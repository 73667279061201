import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/workbench-dashboard/workbench-data.service";
import * as i2 from "@angular/router";
export class WosGuard {
    constructor(wbmService, router) {
        this.wbmService = wbmService;
        this.router = router;
        this.activeWorkBenchId$ = this.wbmService.currentWorkbenchId$;
        this.wosAccessCheck$ = this.activeWorkBenchId$.pipe(switchMap(wbId => {
            let workbenchId = wbId;
            console.log('1. wosGuard [workbenchId]=', workbenchId);
            if (wbId === 'default workbenchId')
                workbenchId = localStorage.getItem('workbench');
            console.log('2. wosGuard [workbenchId]=', workbenchId);
            return this.wbmService.hasAccessToWos(workbenchId);
        }));
    }
    canActivate(next, state) {
        return this.wosAccessCheck$.pipe(tap(canAccess => {
            console.log('canAccess from wosGuard => ', canAccess);
            if (canAccess)
                return true;
            this.router.navigateByUrl("unauthorized");
        }));
    }
}
WosGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WosGuard_Factory() { return new WosGuard(i0.ɵɵinject(i1.WorkbenchDataService), i0.ɵɵinject(i2.Router)); }, token: WosGuard, providedIn: "root" });
