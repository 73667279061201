<div class="limit-modal">
  <div class="limit-header">
    <h3 class="limit-title">Too Many Documents</h3>
    <i class="bi bi-x" tabindex="0" role="button" aria-label="close" (click)="dialogRef.close()" (keydown.enter)="dialogRef.close()" #modal_x (keydown.tab)="loopTabindex('firstTabIndex',$event)"></i>
  </div>
  <div class="limit-body">
    <span tabindex="0">Refine your results below <b>{{data.docCount | number}}</b> documents to proceed.</span>
    <br>
    <span tabindex="0">Consider breaking dataset by date ranges or publication titles.  You can combine multiple smaller datasets if you need more than <b>{{ data.docCount | number }}</b> documents.</span>
  </div>
  <div class="limit-footer">
    <button class="limit-button" (click)="dialogRef.close()" (keydown.enter)="dialogRef.close()" #modal_button (keydown.tab)="loopTabindex('lastTabIndex',$event)">OK</button>
  </div>
</div>