<div class='container'>

    <div *ngIf="isLoading" class="spinner" fxLayoutAlign="center">
        <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>

    <div *ngIf="!isLoading" class='info_container'>
        <h1 class='title'>You did not verify your email address.</h1>
        <p class='info'>Click below to send a new verification to your university/institution email address</p>
        <a class='link' href="javascript:void(0);" (click)="resendEmail()">Send verification email</a>
    </div>

</div>
