<div class="header">
    <div class="close_div">
        <p class="close" (click)="dialogRef.close()">n</p>
    </div>
    <h1 class="title">TDM Studio Visualizations Documentation</h1>
</div>

<div class="tab_bar" fxLayout="row" fxLayoutAlign="space-around center">
    <ng-template ngFor let-docTab [ngForOf]="docTabs">
        <p [ngClass]="selected===docTab ? 'current tab' : 'tab'" (click)="selected=docTab">{{ docTab }}</p>
    </ng-template>
</div>

<mat-dialog-content >
    <div class="content_conatiner">
        <tdms-viz-dashboard-doc-tab *ngIf="selected==='Visualization Dashboard'"></tdms-viz-dashboard-doc-tab>
        <tdms-create-project-doc-tab *ngIf="selected==='Create a Project'"></tdms-create-project-doc-tab>
        <tdms-geo-analysis-doc-tab *ngIf="selected==='Geographic Analysis'"></tdms-geo-analysis-doc-tab>
        <tdms-topic-modeling-doc-tab *ngIf="selected==='Topic Modeling'"></tdms-topic-modeling-doc-tab>
        <tdms-sa-doc-tab *ngIf="selected==='Sentiment Analysis'"></tdms-sa-doc-tab>
    </div>
</mat-dialog-content>


<mat-dialog-actions>
    <div class="button" (click)="dialogRef.close()">Close</div>
</mat-dialog-actions>
