/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./welcome.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../workbenchDashboard/workbench.service";
import * as i10 from "../auth/auth.service";
import * as i11 from "@angular/router";
var styles_WelcomeComponent = [i0.styles];
var RenderType_WelcomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomeComponent, data: {} });
export { RenderType_WelcomeComponent as RenderType_WelcomeComponent };
function View_WelcomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "spinner-container mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = 20; var currVal_4 = 3; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_WelcomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errMsg.error; _ck(_v, 1, 0, currVal_0); }); }
function View_WelcomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "card-header-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "card-header__image"], ["role", "none"], ["src", "../../../assets/images/Flexible_new.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["aria-label", "Workbench access request form"], ["class", "card-header-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToWorkbenchDashboardCheck() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "card-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Workbench Dashboard"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["role", "none"], ["src", "../../../assets/images/right-arrow.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "ul", [["class", "card-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WHAT TO EXPECT"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "li", [["class", "card-info__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Requires basic coding in Python or R and text analysis"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "li", [["class", "card-info__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Provides options to select content and create datasets"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "li", [["class", "card-info__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Offers Jupyter Notebook coding platform"])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["alt", "Sample code for text analysis"], ["class", "card-content__image"], ["src", "../../../assets/images/jupyter.svg"], ["title", "Sample code for text analysis"]], null, null, null, null, null))], null, null); }
function View_WelcomeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "card-header-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "card-header__image"], ["role", "none"], ["src", "../../../assets/images/Viz-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "card-header-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToAnalysisDashboardCheck() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "card-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Visualization Dashboard"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["role", "none"], ["src", "../../../assets/images/right-arrow.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "ul", [["class", "card-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WHAT TO EXPECT"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "li", [["class", "card-info__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Requires no coding experience"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "li", [["class", "card-info__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Provides the ability to select content specific to your project"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "li", [["class", "card-info__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Offers pre-configured data visualization to perform analysis"])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["alt", "Sample geographic analysis; a map with clusters"], ["class", "card-content__image"], ["src", "../../../assets/images/viz-chart.svg"], ["title", "Sample geographic analysis; a map with clusters"]], null, null, null, null, null))], null, null); }
function View_WelcomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeComponent_4)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayDashboardCard(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayVizCard(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_WelcomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome to TDM Studio"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeComponent_3)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.errMsg; _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.isLoading; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_WelcomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-welcome", [], null, null, null, View_WelcomeComponent_0, RenderType_WelcomeComponent)), i1.ɵdid(1, 245760, null, 0, i7.WelcomeComponent, [i8.Title, i9.WorkbenchService, i10.AuthService, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WelcomeComponentNgFactory = i1.ɵccf("tdms-welcome", i7.WelcomeComponent, View_WelcomeComponent_Host_0, {}, {}, []);
export { WelcomeComponentNgFactory as WelcomeComponentNgFactory };
