/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unautorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./unautorized.component";
import * as i3 from "../analysis/analysis.service";
var styles_UnautorizedComponent = [i0.styles];
var RenderType_UnautorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnautorizedComponent, data: {} });
export { RenderType_UnautorizedComponent as RenderType_UnautorizedComponent };
export function View_UnautorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container"], ["fxlayout", "row"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "items-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "cloud-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "An illustration of a cloud with lightning and rain"], ["src", "../../assets/images/cloud_lightning_rain.svg"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "info-msg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "info-msg-1"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, something went wrong."])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "info-msg-2"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please log in again. If the issues persists, contact "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["aria-label", "Email us at tdmstudio@clarivate.com"], ["class", "email"], ["href", "mailto: tdmstudio@clarivate.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["tdmstudio@clarivate.com"]))], null, null); }
export function View_UnautorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-unautorized", [], null, null, null, View_UnautorizedComponent_0, RenderType_UnautorizedComponent)), i1.ɵdid(1, 114688, null, 0, i2.UnautorizedComponent, [i3.AnalysisService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnautorizedComponentNgFactory = i1.ɵccf("tdms-unautorized", i2.UnautorizedComponent, View_UnautorizedComponent_Host_0, {}, {}, []);
export { UnautorizedComponentNgFactory as UnautorizedComponentNgFactory };
