<Dataset-Header stage='refine'></Dataset-Header>
<div class="page">

  <!--start of header wrapper-->
  <div class="header-wrapper">
    <div class="search_header">
      <div class="search_bar">
        <div class="search_div">
            <input #searchBar type="text" 
                              ngClass="search" ngClass.md="search search-md" ngClass.lt-md="search search-ltMd"
                              placeholder="Enter search here…" 
                              [formControl]="searchField"
                              (keyup.enter)="search(searchField.value)"/>
            <span *ngIf="searchBar.value !== null && searchBar.value.length !== 0" class ='clear' (click)="search(''); searchBar.value = ''">n</span>                  
          </div>
          <div ngClass="search_button" ngClass.md="search_button-md" ngClass.lt-md="search_button-ltMd" fxLayoutAlign="center" (click)="search(searchField.value)" >;</div>
      </div>
      <div>
        <span ngClass="searchTips" ngClass.md="searchTips searchTips-md" ngClass.lt-md="searchTips searchTips-ltMd" 
              (click)='openSearchTipsPage()' (keyup.enter)='openSearchTipsPage()'
              tabindex="0" aria-label="Search Tips: this link will open in a new tab">Search Tips</span>
      </div>
    </div>
  </div>  
  <!--end of search_wrapper-->


  <div *ngIf="dateRange$ | async as obsRes">
    <div *ngIf="obsRes[0].type !== 'start' && !isDocs(obsRes[0].searchResp?.docsFound)" class="alert alert-danger">
      <p class="warning_icon">w</p>
      Your search found 0 results. Please modify your search query, date ranges, or search filters, and try again.
    </div>

    <!--results found...-->
    <div  class="docResults">

      <div *ngIf="obsRes[0].type === 'finish' && obsRes[1].type === 'finish' && isShowFilters" 
            ngClass="filter-overlay" 
            fxLayout="column" 
            fxLayoutAlign="start start" 
            fxShow.gt-sm="false" #filterOverlay>
    
        <div class="filter-header" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="close" (keydown.enter)="onToggleShowFilters()" (click)="onToggleShowFilters()" tabindex="0">n</span>
        </div>
        
    
        <div ngClass="filters" ngClass.lt-md="filters-ltMd" fxFlex *ngIf="obsRes[0].type === 'finish' && obsRes[1].type === 'finish'" >
          <tdms-applied-filters *ngIf="isAppliedFilters()" (isApplyFilterTriggered)='applyFilters(searchField.value)' ></tdms-applied-filters>
          <tdms-docfacet [sourceFacets]=sourceFacets(obsRes[0].searchResp?.facets)
                         [docFacets]=docFacets(obsRes[0].searchResp?.facets)
                         [isAnalysis]=false
                         [stickyHeight] = "getFooterHeight(obsRes[0].searchResp?.docsFound)"
                         (isApplyFilterTriggered)='applyFilters(searchField.value)'></tdms-docfacet>
    
        </div>
      </div>
    
      <tdms-workbench-pubfacet *ngIf="isPublication && obsRes[0].type === 'finish'" [facets]=pubFacets(obsRes[0].searchResp?.facets) [isPublication]=isPublication ></tdms-workbench-pubfacet>
      <tdms-workbench-pubfacet *ngIf="!isPublication && obsRes[0].type === 'finish'" [facets]=dbSelection [isPublication]=isPublication></tdms-workbench-pubfacet>
      
      <div ngClass="container" ngClass.md="container-md" fxShow.lt-md="false"
                            fxLayout="row"
                            fxLayoutGap="15px"
                            fxLayoutAlign="start top"
                            fxLayout.xm="column">
    
          <div class="filters" fxFlex="25" fxLayout="column" *ngIf="obsRes[0].type === 'finish' && obsRes[1].type === 'finish'">
            <tdms-applied-filters *ngIf="isAppliedFilters()" (isApplyFilterTriggered)='applyFilters(searchField.value)' ></tdms-applied-filters>
            <tdms-docfacet [sourceFacets]=sourceFacets(obsRes[0].searchResp?.facets)
                           [docFacets]=docFacets(obsRes[0].searchResp?.facets)
                           [isAnalysis]=false
                           [stickyHeight] = "getFooterHeight(obsRes[0].searchResp?.docsFound)"
                           (isApplyFilterTriggered)='applyFilters(searchField.value)'></tdms-docfacet>
    
          </div>
          <div ngClass="results" ngClass.md="results-md" fxFlex="75" *ngIf="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' ">
              <tdms-publication-results [results]= obsRes[0].searchResp?.hits 
                                        [docCount] = obsRes[0].searchResp?.docsFound
                                        [resultsAsc]= obsRes[1].searchResp?.hits >
              </tdms-publication-results> 
          </div>
          <div class="center" *ngIf="obsRes[0].type === 'start' || obsRes[1].type === 'start'" >
            <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
          </div>
      </div>
    
      <div ngClass="results-ltMd" fxShow.gt-sm="false">
        <ng-template [ngIf]="obsRes[0].type === 'finish'  && obsRes[1].type === 'finish' ">
          <tdms-publication-results [results]= obsRes[0].searchResp?.hits 
                                    [docCount] = obsRes[0].searchResp?.docsFound
                                    [resultsAsc]= obsRes[1].searchResp?.hits>
          </tdms-publication-results>
        </ng-template>  
    
        <div class="center">
          <ng-template [ngIf]="obsRes[0].type === 'start' || obsRes[1].type === 'start'" ><mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner></ng-template>
        </div>
    
        <div *ngIf="obsRes[0].type === 'finish' && !isShowFilters" ngClass="filter-tab" (keydown.enter)="onToggleShowFilters()" (click)="onToggleShowFilters()" tabindex="0" [style.bottom]="getFooterHeight(obsRes[0].searchResp?.docsFound) + 'px'"> Filter your results</div>
      </div>
    
      
      <tdms-dataset-selection-footer 
        *ngIf="obsRes[0].type === 'finish' &&  obsRes[1].type === 'finish' "
        [noOfItemsSelected]=obsRes[0].searchResp?.docsFound
        selectionLabel= "Document"
        select= ""
        btnLabel="Next: Review Dataset"
        isRefineContent="true"
        nextStep="CreatePubdataset">
      </tdms-dataset-selection-footer>
    </div>  
  </div>
  
</div>

<!-- <div *ngIf="dateRange$  | async as obsRes" class="docResults"> -->



