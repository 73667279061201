/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-facet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./year-card.component";
var styles_YearCardComponent = [i0.styles];
var RenderType_YearCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YearCardComponent, data: {} });
export { RenderType_YearCardComponent as RenderType_YearCardComponent };
function View_YearCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Year"]))], null, null); }
function View_YearCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Years"]))], null, null); }
export function View_YearCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "year-card"], ["tabindex", "0"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YearCardComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YearCardComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.yearLabel === 1); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.yearLabel !== 1); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelected; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.yearLabel; _ck(_v, 1, 0, currVal_1); }); }
export function View_YearCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-year-card", [], null, null, null, View_YearCardComponent_0, RenderType_YearCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.YearCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YearCardComponentNgFactory = i1.ɵccf("tdms-year-card", i3.YearCardComponent, View_YearCardComponent_Host_0, { yearLabel: "yearLabel", isSelected: "isSelected" }, { selectionChanged: "selectionChanged" }, []);
export { YearCardComponentNgFactory as YearCardComponentNgFactory };
