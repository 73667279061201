import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { CheckBoxItem } from './checkboxItem';

@Component({
  selector: 'tdms-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit, OnChanges {

  @Input() options = Array<CheckBoxItem>();
  @Input() selectedValues: string[] = [];
  @Input() isCheckBoxVisible: boolean = false;
  @Output() toggle = new EventEmitter<string[]>();

  show = false;
  showMore = false;
  all = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    if(this.options.length >7) this.showMore = true;
    if(this.selectedValues.length >0) this.isCheckBoxVisible = true;
  }

  onToggle() {
    const checkedOptions = this.options.filter(x => x.checked);
    this.selectedValues = checkedOptions.map(x => x.value);
    if(checkedOptions.length >0) {
     this.isCheckBoxVisible = true;
    } else {
      this.isCheckBoxVisible = false;
    }
    this.toggle.emit(checkedOptions.map(x => x.value));

    
  }

  changeItem(item:CheckBoxItem){
    this.options.find(x => x == item).checked = !item.checked;
    this.onToggle();
  }

  ngOnChanges() {
    this.selectedValues.forEach(value => {
      const element = this.options.find( x => x.value === value);
      if(element) {
        element.checked = true;
      }
    });
  }

  showAll() {
    this.all = true;
    this.showMore = false;
  }

  handleMouseOver(checkBoxEle: HTMLElement) {
    //console.log(checkBoxEle);
    if(!this.isCheckBoxVisible) checkBoxEle.setAttribute("style", "visibility: visible");

  }

  handleMouseLeave(checkBoxEle: HTMLElement) {
    //console.log(checkBoxEle);
    if(!this.isCheckBoxVisible) checkBoxEle.setAttribute("style", "visibility: hidden");
  }



}
