<div class="container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="12px">
    <div fxLayout="row" fxLayoutAlign="space-between start" >
        <h3 class="header">Applied Filters</h3>
        <p class="clear-all" tabindex="0" (click)="clearAllFilters()" (keyup.enter)="clearAllFilters()">Clear All</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="filter" *ngFor="let filter of selectedPubFilterValues" 
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" 
             tabindex="0" attr.aria-label="Remove applied source type filter, {{filter}}" (keyup.enter)="removeSourceTypeFilter(filter)">
            <p class="label">{{filter}} </p>
            <p class="clear" (click)="removePubTypeFilter(filter)">l</p>
        </div>
        <div class="filter" *ngFor="let filter of selectedSourceTypeFilterValues" 
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" 
             tabindex="0" attr.aria-label="Remove applied source type filter, {{filter}}" (keyup.enter)="removeSourceTypeFilter(filter)">
            <p class="label">{{filter}} </p>
            <p class="clear" (click)="removeSourceTypeFilter(filter)">l</p>
        </div>
        <div class="filter" *ngFor="let filter of selectedDocFilterValues"
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
             tabindex="0" attr.aria-label="Remove applied document type filter, {{filter}}" (keyup.enter)="removeDocTypeFilter(filter)">
            <p class="label">{{filter}} </p>
            <p class="clear" (click)="removeDocTypeFilter(filter)">l</p>
        </div>
        <div class="filter" *ngIf="isDateFilter"
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
             tabindex="0" attr.aria-label="Remove applied date filter, {{getDateFilter()}}" (keyup.enter)="removeDateFilter()">
            <p class="label">{{getDateFilter()}}</p>
            <p class="clear" (click)="removeDateFilter()">l</p>
        </div>
    </div>
</div>