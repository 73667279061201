import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Renderer2 } from '@angular/core';
import { CheckBoxItem } from './checkboxItem';

@Component({
  selector: 'tdms-wb-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit, OnChanges {

  @Input() options = Array<CheckBoxItem>();
  @Input() selectedValues: string[] = [];
  @Output() toggle = new EventEmitter<string[]>();

  show = false;
  showMore = false;
  all = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    if(this.options.length >7) this.showMore = true;
  }

  onToggle() {
    const checkedOptions = this.options.filter(x => x.checked);
    this.selectedValues = checkedOptions.map(x => x.value);
    this.toggle.emit(checkedOptions.map(x => x.value));
  }

  changeItem(item:CheckBoxItem){
    this.options.find(x => x == item).checked = !item.checked;
    this.onToggle();
  }

  ngOnChanges() {
    this.selectedValues.forEach(value => {
      const element = this.options.find( x => x.value == value);
      if(element) {
        element.checked = true;
      }
    });
  }

  showAll() {
    this.all = true;
    this.showMore = false;
  }

}
