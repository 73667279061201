import { MatButtonModule, MatTableModule, MatIconModule, MatTooltipModule, MatDialogModule, MatCardModule, MatInputModule, MatFormFieldModule, MatPaginatorModule, MatCheckboxModule, MatProgressSpinnerModule, MatDividerModule, MatDatepickerModule, MatNativeDateModule, MatListModule, MatSelectModule, MatExpansionModule, } from "@angular/material";
const MaterialComponents = [
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatSelectModule,
    MatExpansionModule,
];
const ɵ0 = {}, ɵ1 = [];
export class MaterialModule {
}
export { ɵ0, ɵ1 };
