import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { WorkbenchResponse, UserInfo, WorkbenchAccessCheckResponse, MethodsAccessCheckResponse } from './workbench.model';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';

import { delay, map } from 'rxjs/operators';
import { StudioUserSignupService } from '../auth/studio-user-signup.service';

@Injectable({
  providedIn: 'root'
})
export class WorkbenchService {

  apiRootForWb = '/api/wb';
  apiRootForUm = '/api/um';
  errMsg: '';

  triggerCreateWorkBenchEvent$ = new BehaviorSubject<boolean>(false);
  wbCreationInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private userSignupService: StudioUserSignupService) { }

  getWorkbenches(): Observable<WorkbenchResponse> {
    const apiURL = `{this.apiRootForWb}/workbench`;
    return this.http.get<WorkbenchResponse>(apiURL);
  }

  getUserInfo(email: string): Observable<UserInfo> {
    const apiURL = `{this.apiRootForUm}/user/` + email;
    return this.http.get<UserInfo>(apiURL);
  }

  getWorkbenchAcessCheck(isCreate:boolean, isRead:boolean) {
    const apiURL = 'api/wbm/access?create=' + isCreate + '&read=' + isRead;
    return this.http.get<WorkbenchAccessCheckResponse>(apiURL);
  }

  createWorkBench() {
    const apiURL =  'api/wbm/workbench';
    
    return this.http.post(apiURL, null);
  }

  createWorkBenchMock() {
    const apiURL =  'api/wbm/workbench';

    let createWorkBenchResponse$ =  of(['NewWorkbench is creating'])
                                  .pipe(
                                      delay(3000),
                                      map(res => ({"workbenchId":"a27"}))
                                  )
    
    return createWorkBenchResponse$
  }

  //TODO BA: Move this metho to relavent service or rename this service. 
  createMethodsUser(createIfMissing: boolean) {
    const apiURL = '/api/mum/user/access?createIfMissing='+createIfMissing;
    return this.http.get<MethodsAccessCheckResponse>(apiURL);
  }

  createFakeMethodUser() {
    const body = { message: 'user created successfully'}
    //return of(new HttpResponse({ status: 200, body}));
    const obs$ =  Observable.create((observer) => {
      observer.next(body);
    })

    return obs$;
  }

  triggerCreateWbEvent(create: boolean) {
    this.triggerCreateWorkBenchEvent$.next(create);
  }

  setCreationWbProgress(isProgressing: boolean) {
    this.wbCreationInProgress$.next(isProgressing);
  }

}

interface WorkbenchDashboardAccessRights {
  create: boolean;
  read: boolean;
  displayRequestAccessForm?: boolean;
}

