/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./_no-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-content.component";
import * as i3 from "../services/seo/seo.service";
var styles_NoContentComponent = [i0.styles];
var RenderType_NoContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoContentComponent, data: {} });
export { RenderType_NoContentComponent as RenderType_NoContentComponent };
export function View_NoContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "container-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" page not found\n"]))], null, null); }
export function View_NoContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-nocontent", [], null, null, null, View_NoContentComponent_0, RenderType_NoContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.NoContentComponent, [i3.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoContentComponentNgFactory = i1.ɵccf("tdms-nocontent", i2.NoContentComponent, View_NoContentComponent_Host_0, {}, {}, []);
export { NoContentComponentNgFactory as NoContentComponentNgFactory };
