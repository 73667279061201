import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tdms-subway-map',
  templateUrl: './subway-map.component.html',
  styleUrls: ['./subway-map.component.scss']
})
export class SubwayMapComponent implements OnInit {

  @Input() step:string

  constructor() { }

  ngOnInit() {
  }

}
