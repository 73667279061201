/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../doc-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./viz-dashboard-doc-tab.component";
var styles_VizDashboardDocTabComponent = [i0.styles];
var RenderType_VizDashboardDocTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VizDashboardDocTabComponent, data: {} });
export { RenderType_VizDashboardDocTabComponent as RenderType_VizDashboardDocTabComponent };
function View_VizDashboardDocTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "tab_content column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "tab_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Once you have created projects, they will appear on the Visualization Dashboard where you can have up to five projects at a time. Newly created projects are grayed out until they are ready for exploration. You can use your browser\u2019s refresh button to check on the status of new projects. Each project listing provides a summary of the search, the number of documents, and the newspaper titles being analyzed."])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Viz Page Screenshot"], ["class", "img_center img_size"], ["src", "../../../../../assets/images/Documentation/dashboard-main.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "p", [["class", "tab_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To view or delete a project, select the project listing you are interested in exploring and then select "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show actions"])), (_l()(), i1.ɵted(-1, null, [". You can open the visualization for a specific project by selecting the appropriate visualization button (e.g. \u201COpen Geographic Visualization\u201D). "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["alt", "Viz Project Screenshot"], ["class", "img_center img_size"], ["src", "../../../../../assets/images/Documentation/dashboard-project.png"]], null, null, null, null, null))], null, null); }
export function View_VizDashboardDocTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome to TDM Studio Visualizations"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "sub_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TDM Studio Visualizations allows discoveries using text and data mining without coding. It is designed for users of all levels to quickly spot trends and generate insights using newspaper articles available on the ProQuest Platform."])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "info_tab"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "tab_bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "tab_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Using the Dashboard"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(11, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VizDashboardDocTabComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isOpen(1) ? "up_arrow" : "down_arrow"); _ck(_v, 10, 0, currVal_0); var currVal_1 = (_co.isOpen(1) ? "up_arrow" : "down_arrow"); _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.isOpen(1); _ck(_v, 13, 0, currVal_2); }, null); }
export function View_VizDashboardDocTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-viz-dashboard-doc-tab", [], null, null, null, View_VizDashboardDocTabComponent_0, RenderType_VizDashboardDocTabComponent)), i1.ɵdid(1, 114688, null, 0, i5.VizDashboardDocTabComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VizDashboardDocTabComponentNgFactory = i1.ɵccf("tdms-viz-dashboard-doc-tab", i5.VizDashboardDocTabComponent, View_VizDashboardDocTabComponent_Host_0, {}, {}, []);
export { VizDashboardDocTabComponentNgFactory as VizDashboardDocTabComponentNgFactory };
