import { combineLatest, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
export class AuthtokenService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.user$ = this.authService.studioUser$.pipe(catchError((err) => {
            return of(null);
        }));
    }
    openDocview(docId) {
        let host = window.location.hostname;
        let baseUrl = 'https://www.proquest.com/docview/';
        const key = 'tokenObj';
        let lapseTime = 23 * 60 * 60 * 1000; //23 hrs lapse time
        let testingLapseTime = 5 * 1000; // 5 seconds;
        if (host.indexOf('.pre') > 0 ||
            host.indexOf('.dev') > 0 ||
            host.indexOf('localhost') === 0 ||
            host.indexOf('127.0.0.1') === 0) {
            baseUrl = 'https://search.pre.proquest.com/docview/';
        }
        let pqToken = this.getWithExpiry(key);
        const token$ = this.getPQToken();
        if (pqToken === null) {
            combineLatest([token$, this.user$]).subscribe(([tokenRes, userRes]) => {
                pqToken = tokenRes.pqtoken;
                localStorage.setItem('acctId', userRes.accountId);
                this.setWithExpiry('tokenObj', pqToken, lapseTime);
                let docUrl = baseUrl + docId + '/embedded/' + pqToken + this.getLinkOutSuffix();
                window.open(docUrl, '_blank');
            });
        }
        else {
            let docUrl = baseUrl + docId + '/embedded/' + pqToken + this.getLinkOutSuffix();
            window.open(docUrl, '_blank');
        }
    }
    openPubPage(pubId) {
        let host = window.location.hostname;
        let baseUrl = 'https://www.proquest.com/publication/';
        const key = 'tokenObj';
        let lapseTime = 23 * 60 * 60 * 1000; //23 hrs lapse time
        let testingLapseTime = 5 * 1000; // 5 seconds;
        if (host.indexOf('.pre') > 0 ||
            host.indexOf('.dev') > 0 ||
            host.indexOf('localhost') === 0 ||
            host.indexOf('127.0.0.1') === 0) {
            baseUrl = 'https://search.pre.proquest.com/publication/';
        }
        let pqToken = this.getWithExpiry(key);
        const token$ = this.getPQToken();
        if (pqToken === null) {
            combineLatest([token$, this.user$]).subscribe(([tokenRes, userRes]) => {
                pqToken = tokenRes.pqtoken;
                localStorage.setItem('acctId', userRes.accountId);
                this.setWithExpiry('tokenObj', pqToken, lapseTime);
                let docUrl = baseUrl + pubId + '/embedded/' + pqToken;
                window.open(docUrl, '_blank');
            });
        }
        else {
            let docUrl = baseUrl + pubId + '/embedded/' + pqToken;
            window.open(docUrl, '_blank');
        }
    }
    openDatabasePage(moniker) {
        let host = window.location.hostname;
        let baseUrl = 'https://www.proquest.com/';
        const key = 'tokenObj';
        let lapseTime = 23 * 60 * 60 * 1000; //23 hrs lapse time
        let testingLapseTime = 5 * 1000; // 5 seconds;
        if (host.indexOf('.pre') > 0 ||
            host.indexOf('.dev') > 0 ||
            host.indexOf('localhost') === 0 ||
            host.indexOf('127.0.0.1') === 0) {
            baseUrl = 'https://search.pre.proquest.com/';
        }
        let pqToken = this.getWithExpiry(key);
        const token$ = this.getPQToken();
        if (pqToken === null) {
            combineLatest([token$, this.user$]).subscribe(([tokenRes, userRes]) => {
                pqToken = tokenRes.pqtoken;
                localStorage.setItem('acctId', userRes.accountId);
                this.setWithExpiry('tokenObj', pqToken, lapseTime);
                let databaseUrl = baseUrl + moniker + '/embedded/' + pqToken;
                window.open(databaseUrl, '_blank');
            });
        }
        else {
            let databaseUrl = baseUrl + moniker + '/embedded/' + pqToken;
            window.open(databaseUrl, '_blank');
        }
    }
    getLinkOutSuffix() {
        let acctId = localStorage.getItem('acctId');
        let suffix = '?accountid=';
        if (acctId) {
            return suffix + acctId;
        }
        return '';
    }
    setWithExpiry(key, value, ttl) {
        const now = new Date();
        const tokenObj = {
            value: value,
            expiry: now.getTime() + ttl
        };
        localStorage.setItem(key, JSON.stringify(tokenObj));
    }
    getWithExpiry(key) {
        const tokenStr = localStorage.getItem(key);
        if (!tokenStr) {
            //This check to delete the old docview token from local storage... since code base in transistion from simple token storage
            //to object (token + expirtytime);
            if (localStorage.getItem('dt') != null)
                localStorage.removeItem('dt');
            return null;
        }
        const tokenObj = JSON.parse(tokenStr);
        const now = new Date();
        if (now.getTime() > tokenObj.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return tokenObj.value;
    }
    getPQToken() {
        let workbenchId = localStorage.getItem('workbench');
        const apiURL = '/api/wbm/workbench/' + workbenchId + '/pqtoken';
        if (window.location.pathname.indexOf('analysis') < 0) {
            return this.http.get(apiURL)
                .pipe(tap(val => console.log('pqtoken -->', val)));
        }
        else {
            return this.http.get('/api/mum/user/pqtoken')
                .pipe(tap(val => console.log('pqtoken -->', val)));
        }
    }
}
AuthtokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthtokenService_Factory() { return new AuthtokenService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: AuthtokenService, providedIn: "root" });
