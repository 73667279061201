import { Routes, RunGuardsAndResolvers } from '@angular/router';
import { CallbackComponent } from './auth/callback/callback.component';
import { AuthGuard } from './auth/auth.guard';
import { NoContentComponent } from './no-content';
import { WorkbenchResolver } from './workbenchDashboard/workbench.resolver';
import { AdminGuard } from './auth/admin.guard';
import { UnautorizedComponent } from './unautorized/unautorized.component';
import { VizGuard } from './auth/viz.guard';
import { WelcomeComponent } from './welcome/welcome.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { EmailExpireComponent } from './create-account/email-verification/email-expire.component';
import { EmailVerificationComponent } from './create-account/email-verification/email-verification.component';
import { WosGuard } from './auth/wos.guard';
export const alwaysRunGuard = 'always';
const ɵ0 = () => import("./wb-dataset/wb-dataset.module.ngfactory").then(m => m.WbDatasetModuleNgFactory), ɵ1 = () => import("./wb-dataset/wb-dataset.module.ngfactory").then(m => m.WbDatasetModuleNgFactory), ɵ2 = () => import("./dataset/dataset.module.ngfactory").then(m => m.DatasetModuleNgFactory), ɵ3 = () => import("./wos-dataset/wos-dataset.module.ngfactory").then(m => m.WosDatasetModuleNgFactory);
export const ROUTES = [
    { path: 'home', loadChildren: './home#HomeModule' },
    {
        path: 'workbenchdashboard',
        loadChildren: './workbenchDashboard#WorkbenchDashboardModule',
        canActivate: [AuthGuard],
        resolve: { wb: WorkbenchResolver },
        runGuardsAndResolvers: alwaysRunGuard
    },
    { path: 'callback', component: CallbackComponent },
    {
        path: 'dataset',
        loadChildren: ɵ0
    },
    {
        path: 'dataset/refinecontent',
        loadChildren: ɵ1
    },
    {
        path: 'datasetv1',
        loadChildren: ɵ2
    },
    {
        path: 'wosdataset',
        loadChildren: ɵ3,
        canActivate: [AuthGuard, WosGuard]
    },
    { path: 'admindashboard', redirectTo: 'admin/dashboard', pathMatch: 'full' },
    { path: 'admin', loadChildren: './adminDashboard#AdminDashboardModule', canActivate: [AdminGuard] },
    { path: 'analysis', loadChildren: './analysis#AnalysisModule', canActivate: [VizGuard] },
    { path: 'unauthorized', component: UnautorizedComponent },
    { path: 'welcome', component: WelcomeComponent },
    { path: 'createaccount', component: CreateAccountComponent },
    { path: 'verify/:email/:vCode', component: EmailVerificationComponent },
    { path: 'unverified/:email', component: EmailExpireComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', component: NoContentComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
