import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tdms-year-card',
  template: `
    <div class="year-card" [class.selected]="isSelected" (click)="toggleSelection()" tabindex="0">
      {{ yearLabel }}
      <span *ngIf="yearLabel === 1">Year</span>
      <span *ngIf="yearLabel !== 1">Years</span>
    </div>
  `,
  styleUrls: ['./date-facet.component.scss']
})
export class YearCardComponent implements OnInit {

  @Input() yearLabel: number = 0;
  @Input() isSelected: boolean = false;
  @Output() selectionChanged =  new EventEmitter<boolean>();

  ngOnInit() {
    this.isSelected = localStorage.getItem("yearCardSel") && Number.parseInt(localStorage.getItem("yearCardSel")) === this.yearLabel;
  }

  toggleSelection() {
    this.isSelected = !this.isSelected;
    this.selectionChanged.emit(this.isSelected);
  }



}