<div *ngIf="isLoading" class="center">
  <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
</div>
<div *ngIf="!isLoading" class="create_container">
  <div class="create_header">
    <div class="summary">Summary</div>
    <div class="details-docCount">
      <div class="bold summary_label">Document Count:</div>
      <div class="summary_label">{{ docCount | number }}</div>
      <div></div>
    </div>

    <div class="details" *ngIf="isDbFiltersExists()">
      <div class="bold summary_label">Databases</div>
      <div class="summary_label">{{ getContentList("db") }}</div>
      <div></div>
    </div>

    <div class="details" *ngIf="isPubFiltersExists()">
      <div class="bold summary_label">Publications Titles</div>
      <div class="summary_label">{{ getContentList("pub") }}</div>
      <div></div>
    </div>

    <div class="details">
      <div class="bold summary_label">Visualization</div>
      <div class="summary_label">{{ selectedVizText }}</div>
      <div></div>
    </div>

    <div tabindex="0" 
         (click)='toggleQuery()' 
         (keyup.enter)='toggleQuery()' 
         *ngIf="isQuery" 
         class="query-label" role="link">

        <p class="show_query">{{isShowQuery ? 'Hide Query' : 'Show Query'}}</p>
        <i [ngClass]="isShowQuery ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
    </div>
    <div *ngIf='isShowQuery' tabindex="0" class="query">{{query}}</div>


  </div>

  <div class="create_body">
    <div class="dataset_details">Dataset Details</div>

    <form  [formGroup]="createProject"
           (ngSubmit)="onCreate(createProject.value)" >
      <div>
        <p class="name label">Name</p>
        <p class="required label">*</p>
      </div>
      <textarea #datasetNameInput
        [ngClass]="isError ? 'error textbox' : 'textbox'"
        form="createProject"
        formControlName="name"
        placeholder="Enter Descriptive Dataset Name"
        maxlength="60">
      </textarea>
      <p *ngIf="isError" class="error_message">{{ errorMessage }}</p>
      <div *ngIf="!isCreating"
        (click)="onCreate(createProject.value)"
        class="create">
        Create Dataset
      </div>
      <div *ngIf="isCreating" class="create disabled">
        <div class="spinner">
          <mat-spinner [diameter]="30" [strokeWidth]="3"></mat-spinner>
        </div>
      </div>
    </form>
  </div>
</div>
