<div class="main-container">
  <div class="page-title">
      <h1 tabindex="0">Welcome to TDM Studio</h1>
      <mat-spinner [diameter]="20" [strokeWidth]="3" class="spinner-container" *ngIf="isLoading"></mat-spinner>
  </div>
  <div class="error-info" *ngIf="errMsg"> {{ errMsg.error }}</div>


  <div class="card-wrapper" *ngIf="!isLoading">
    <div class="card" *ngIf="displayDashboardCard()">
        <div class="card-header">
            <div class="card-header-left">
                <img src="../../../assets/images/Flexible_new.svg" class="card-header__image" role="none"/>
            </div>

            <a (click)="navigateToWorkbenchDashboardCheck()"
                class="card-header-right" aria-label="Workbench access request form">
                <h2 class="card-header__title">Workbench Dashboard</h2>
                <img src="../../../assets/images/right-arrow.svg" role="none" />
            </a>

        </div>   
        <div class="card-content">
            <ul class="card-info">
                <li><h3>WHAT TO EXPECT</h3></li>
                <li class="card-info__instruction">Requires basic coding in Python or R and text analysis</li>
                <li class="card-info__instruction">Provides options to select content and create datasets</li>
                <li class="card-info__instruction">Offers Jupyter Notebook coding platform</li>
            </ul>
            <img src="../../../assets/images/jupyter.svg" class="card-content__image" 
                 alt="Sample code for text analysis" 
                 title="Sample code for text analysis" />

        </div>
    </div>

    <!-- card2-->
   <div class="card" *ngIf="displayVizCard()">
        <div class="card-header">
            <div class="card-header-left">
                <img src="../../../assets/images/Viz-icon.svg"  class="card-header__image" role="none"/>
            </div>
            <a (click)="navigateToAnalysisDashboardCheck()" class="card-header-right">
              <h2 class="card-header__title">Visualization Dashboard</h2>
              <img src="../../../assets/images/right-arrow.svg" role="none"/>
            </a>
        </div>   
        <div class="card-content">
            <ul class="card-info">
                <li><h3>WHAT TO EXPECT</h3></li>
                <li class="card-info__instruction">Requires no coding experience</li>
                <li class="card-info__instruction">Provides the ability to select content specific to your project</li>
                <li class="card-info__instruction">Offers pre-configured data visualization to perform analysis</li>
            </ul>
            <img src="../../../assets/images/viz-chart.svg" class="card-content__image"
                 alt="Sample geographic analysis; a map with clusters"
                 title="Sample geographic analysis; a map with clusters"/>
        </div>
    </div>
    
  </div>

</div>

