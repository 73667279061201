<div ngClass="filterPanel" ngClass.lt-md="filterPanel filterPanel-sm" fxLayout="column" *ngIf="sourceFacets !=null">
    <div ngClass.gt-sm="scroll">
        
        <tdms-datefacet></tdms-datefacet>
        
        <!--Publication/Database facet-->
        <div class="facetSection" *ngIf="isAnalysis">
            <h3 ngClass="facetHeader" ngClass.md="facetHeader-md">Publication titles</h3>
            <div class="search_wrapper" >
                <i class="bi-search search-icon" (click)="applySearchFilter(filterText.value)"></i>
                <input  class="search" 
                        placeholder="Publication title" 
                        type="text" 
                        id="search" 
                        aria-label="Publication title " 
                        ariaContols="pubCountInfo"
                        #filterText 
                        (search)="applySearchFilter(filterText.value)"
                        (keyup)="applySearchFilter(filterText.value)"
                        (ngModelChange)="clearSearchFilter($event)"
                        [(ngModel)]="filterValue"/>
                <div class="search-clear"><i *ngIf="filterValue" class="bi-x clear-icon" (click)="filterValue = ''; applySearchFilter('')"  (keydown.enter)="filterValue = ''; applySearchFilter('')" tabindex="0" aria-label="clear publication title"></i></div>
            </div>

            <div role="region" id="pubCountInfo" aria-live="polite"  >
                <p style="visibility: hidden;">{{pubFilterSelection.length == 1 ? "1 publication found" : pubFilterSelection.length + " publications found"}}</p>
            </div>

            <div class="facetContent pub-scroll">
                <div class="publist">
                    <div [ngClass]="isOdd ? 'facetRow fill' : 'facetRow'" *ngFor="let facet of pubFilterSelection; let isOdd = odd;" fxLayout="row"
                            role="checkbox"
                            (click)="onAnalysisPublicationFacetChange(facet.value)"
                            (keydown.space)="onAnalysisPublicationFacetChange(facet.value)"
                            (keydown.enter)="onAnalysisPublicationFacetChange(facet.value)"
                            attr.aria-checked="{{facet.checked || isChecked('pub', facet)}}"
                            attr.aria-label="{{facet.label}}"
                            tabindex="0">
                        <div class="checkbox"><i *ngIf="facet.checked || isChecked('pub', facet)" class="check bi-check-lg"></i></div>
                        <span class="facetLabel" fxFlex>{{facet.label}}</span>
                        <span [ngClass]="isOdd ? 'count fill' : 'count fill-white'">{{facet.noOfItems}}</span>
                    </div>
                    <div  *ngIf="pubFilterSelection.length > 12" class="end-pub-list">
                        
                    </div>
                </div>
            </div>
        </div>


        <!--sourceType facet-->
        <div class="facetSection">
            <div fxLayout="row" fxLayoutAlign="start center">
                <h3 ngClass="facetHeader" ngClass.md="facetHeader-md">Source Type</h3>
            </div>    
            <div class="facetContent" fxLayout="column">
                <div *ngIf="isAnalysis">
                    <div [ngClass]="isOdd ? 'facetRow fill' : 'facetRow'" *ngFor="let facet of sourceList; let isOdd = odd;" fxLayout="row"
                        role="checkbox"
                        tabindex="0"
                        attr.aria-checked="{{facet.checked || isChecked('source', facet)}}"
                        attr.aria-label="{{facet.label}}"
                        (click)="onAnalysisSourceTypeFacetChange(facet.value)"
                        (keydown.space)="onAnalysisSourceTypeFacetChange(facet.value)"
                        (keydown.enter)="onAnalysisSourceTypeFacetChange(facet.value)">
                        <div class="checkbox"><i *ngIf="facet.checked || isChecked('source', facet)" class="check bi-check-lg"></i></div>
                        <span class="facetLabel" fxFlex>{{facet.label}}</span>
                        <span class="count">{{facet.noOfItems}}</span>
                    </div>
                    <p *ngIf="sourceList < sourceFacetSelection" class="expand" (click)="showMore('source')">Show more ({{sourceCount}}) ></p>
                </div>
                


                <tdms-checkbox-group *ngIf="!isAnalysis" [options]=sourceFacetSelection class="facetLineItem"
                    [selectedValues]="selectedsourcTypeFilterValues"
                    (toggle)="onSourceTypeFacetChange($event)"></tdms-checkbox-group>
            </div>
        </div>
        <!-- <div class="bottom">&nbsp;</div> -->

        <!--Document type facet-->
        <div class="facetSection" *ngIf="docFacets !=null ">
            <h3 ngClass="facetHeader" ngClass.md="facetHeader-md">Document Type</h3>
            <div class="facetContent">
                <div *ngIf="isAnalysis">
                    <div [ngClass]="isOdd ? 'facetRow fill' : 'facetRow'" *ngFor="let facet of docList; let isOdd = odd;" fxLayout="row" 
                        role="checkbox"
                        tabindex="0"
                        attr.aria-checked="{{facet.checked || isChecked('doc', facet)}}"
                        attr.aria-label="{{facet.label}}"
                        (click)="onAnalysisDocFacetChange(facet.value)"
                        (keydown.space)="onAnalysisDocFacetChange(facet.value)"
                        (keydown.enter)="onAnalysisDocFacetChange(facet.value)">
                        <div class="checkbox"><i *ngIf="facet.checked || isChecked('doc', facet)" class="check bi-check-lg"></i></div>
                        <span class="facetLabel" fxFlex>{{facet.label}}</span>
                        <span class="count">{{facet.noOfItems}}</span>
                    </div>
                    <p *ngIf="docList < docFacetSelection" class="expand" (click)="showMore('doc')">Show more ({{docCount}}) ></p>
                </div>
                

                <tdms-checkbox-group *ngIf="!isAnalysis" [options]="docFacetSelection"
                    [selectedValues]="selectedDocFilterValues"
                    (toggle)="onDocFacetChange($event)"></tdms-checkbox-group>
            </div>
        </div>
        <div class="bottom">&nbsp;</div>
    </div>
    <div class="applyFilters" 
        fxLayout="row" 
        fxLayoutAlign="space-around center" 
        *ngIf="(showApplyFilters || showApplyFiltersForDates || showApplyFiltersForFullTextLimiter) && isAnalysis"
        [style.bottom] = "stickyHeight + 'px'">
        <div class="cancel" (click)="clearAllFilters()" (keyup.enter)="clearAllFilters()" tabindex="0">Cancel</div>
        <div><button (click)='applyFilters()'>Apply Filters</button></div>
        
    </div>
    
    <div ngClass="applyFiltersWB" ngClass.md="applyFiltersWB-md" ngClass.lt-md="applyFiltersWB-ltMd"
        fxLayout="row" 
        fxLayoutAlign="start center" 
        *ngIf="(showApplyFilters || showApplyFiltersForDates || showApplyFiltersForFullTextLimiter) && !isAnalysis"
        [style.bottom] = "stickyHeight + 'px'">
        <div class="applyButton"><button ngClass="buttonWB" ngClass.lt-lg="buttonWB-ltLg" (click)='applyFilters()'>Apply Filters</button></div>
        <div class="cancelWB" (click)="clearAllFilters()" (keyup.enter)="clearAllFilters()" tabindex="0">Cancel</div>
        
    </div>
</div>