import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _m from 'moment';
import { Subscription } from 'rxjs';
import { Database, Entry } from 'src/app/_shared/models/publication-search.model';
import { PublicationService } from 'src/app/dataset/selectContent/publication-refinecontent/publication-results/publication-refinement.service';
import { CheckBoxItem } from '../checkbox-group/checkboxItem';
import * as JsSearch from 'js-search';
import { PubSelectModalComponent } from './pubselectmodal.component';
import { MatDialog } from '@angular/material';
import { Facet, PubFacetResponseType } from 'src/app/_shared/models/publication-results.model';



@Component({
  selector: 'tdms-filterpanel',
  templateUrl: './filterpanel.component.html',
  styleUrls: ['./filterpanel.component.scss']
})
export class FilterpanelComponent implements OnInit {

  pubFacets: Entry[];
  @Input() sourceFacets: Entry[];
  @Input() docFacets: Entry[];
  @Input() dbFacets: Entry[];
  @Input() isWb: boolean;

  @Output() componentRendered: EventEmitter<void> = new EventEmitter<void>();

  displayFilters$ = this.pubservice.displayFilter$;
  lazyPubFacetSubject$ = this.pubservice.lazyPubFacetSubjectV1$;




  

  showApplyFilters: boolean = false;
  showApplyFiltersForDates: boolean = false;
  showApplyFiltersForFullTextLimiter: boolean = false;

  @ViewChild('aFilters',{static: false}) aFiltersEl: ElementRef;
  @Output() isApplyFilterTriggered: EventEmitter<boolean> = new EventEmitter();
  @HostListener('window:keydown.space', ['$event'])
    spaceEvent(event: any) {
        if(event.target.className == "checkbox"){
          event.preventDefault();
        }
    }
  
  
  initialLoad: boolean = false;
  docCount:number = 0;
  sourceCount:number = 0;
  currentPubSearch:string = "";
  filterValue: string;
  showApplied:boolean = false;
  isExpandable:boolean = false;
  
  pubFacetSelection = [];
  pubFilterSelection = [];

  docFacetSelection = [];
  docList = [];

  sourceFacetSelection = [];
  sourceList = [];

  dbFacetSelection = [];
  dbList = [];



  //User selected filters;
  selectedPubFilterValues: {
    id: string;
    title: string;
  } []  = [];
  selectedDocFilterValues: string[] = [];
  selectedSourceTypeFilterValues: string[] = [];
  selectedModalDbFilterValues: Database[] = [];
  appliedFilters = [];
  selectedDbDisplayValues = [];

  dateFilterSubscription: Subscription;
  fullTextLimiterSubscription: Subscription;

  isPubFacetLoading =  true;
  isdbFacetLoading = true;

  
  
  

  constructor(private pubservice: PublicationService, private dialog: MatDialog, private cdr:ChangeDetectorRef) {}


  ngOnInit() {
    console.log('FilterPanel component ---- ngOnInit method...');
    //populate docFacetCheckboxModel...
    //this.pubFacetSelection = this.pubFacets ? this.pubFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count)) : [];
    //this.pubFilterSelection = this.pubFacetSelection;

    this.docFacetSelection = this.docFacets ? this.docFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count))  : [];
    this.docList = this.docFacetSelection.length < 12 ? this.docFacetSelection : this.docFacetSelection.slice(0,12);
    this.docCount = this.docFacetSelection.length - this.docList.length;

    this.sourceFacetSelection = this.sourceFacets ? this.sourceFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count))  : [];
    this.sourceList = this.sourceFacetSelection.length < 12 ? this.sourceFacetSelection : this.sourceFacetSelection.slice(0,12);
    this.sourceCount = this.sourceFacetSelection.length - this.sourceList.length;

    //this.dbFacetSelection = this.dbFacets ? this.dbFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count)) : [];

    this.initialLoad = true;
    this.populatedSelectedFilterValues();
    this.dateFilterSubscription = this.pubservice.dateFilter$.subscribe(val => {
      this.showApplyFiltersForDates = this.pubservice.isDatesSelected();
    })
    this.fullTextLimiterSubscription = this.pubservice.fullTextLimitter$.subscribe(val => {
      this.showApplyFiltersForFullTextLimiter = val;
    })
    

    //Load pubFaceet & DBFacet section lazily....
    this.lazyPubFacetSubject$.subscribe( (pubFacetResp: PubFacetResponseType) => {

      if(pubFacetResp.type === 'start') {
        this.isPubFacetLoading = true;
      }

      if(pubFacetResp.type === 'finish') {
        this.isPubFacetLoading = false;
        if(pubFacetResp.searchResp) {
          this.pubFacets = this.pubservice.getPubFacets(pubFacetResp.searchResp.facets);
          localStorage.setItem("pubFacets", JSON.stringify(this.pubFacets));
          this.pubFacetSelection = this.pubFacets ? this.pubFacets.map( entry => new CheckBoxItem(entry.searchValue, entry.displayValue, entry.count)) : [];
          console.log('filterPanel init method...', this.pubFacets.length, ' ', this.isPubFacetLoading);
        }
        
      }
      
    })


  }


  ngAfterViewInit() {
    if(this.aFiltersEl){this.isExpandable = this.aFiltersEl.nativeElement.offsetHeight > 340;}
    this.cdr.detectChanges();

    this.componentRendered.emit();
  }




  onDocFacetChange(values) {
    this.selectedDocFilterValues = values;
    this.initialLoad = false;
    this.showApplyFilters = true;

  }

  onSourceTypeFacetChange(values) {
    this.selectedSourceTypeFilterValues = values;
    this.initialLoad = false;
    this.showApplyFilters = true;

  }


  // onDbFacetChange(values) {
  //   this.selectedDbDisplayValues = [];
  //   for(let val of values){
  //     this.selectedDbDisplayValues.push({displayValue:this.dbFacetSelection.find(x => x.value == val).label, value: val})
  //   }
  //   this.selectedDbFilterValues = values;
  //   this.initialLoad = false;
  //   this.showFilterButton();
  //   this.showApplyFilters = true;

  // }

  onPublicationFacetChange(values) {
    this.selectedPubFilterValues = values;
    this.initialLoad = false;
    this.pubservice.setFilterChecked(true);
    this.showApplyFilters = true;
  }


  private populatedSelectedFilterValues() {
    this.selectedPubFilterValues = localStorage.getItem('pubFilters') === null ? [] : JSON.parse(localStorage.getItem('pubFilters'));
    this.selectedDocFilterValues = localStorage.getItem('docFilters') === null ? [] : JSON.parse(localStorage.getItem('docFilters'));
    this.selectedSourceTypeFilterValues = localStorage.getItem('sourceFilters') === null ? [] : JSON.parse(localStorage.getItem('sourceFilters'));
    this.selectedModalDbFilterValues = localStorage.getItem('dbFilters') === null ? [] : JSON.parse(localStorage.getItem('dbFilters'));
    this.popluateAppliedFilter();
  }


  popluateAppliedFilter(){
    for(var pub of this.selectedPubFilterValues){
      this.appliedFilters.push({type: 'pub', value: pub.title})
    }
    for(var st of this.selectedSourceTypeFilterValues){
      this.appliedFilters.push({type: 'st', value: st})
    }
    for(var doc of this.selectedDocFilterValues){
      this.appliedFilters.push({type: 'doc', value: doc})
    }
    for(var db of this.selectedModalDbFilterValues){
      this.appliedFilters.push({type: 'db', value: db.name})
    }
    //Date filter
    let dateCard = localStorage.getItem('yearCardSel');
    if(dateCard){
      this.appliedFilters.push({type: 'date', value: dateCard == '1' ? dateCard + " Year" : dateCard + " Years"});
    } else {
      let fDate = JSON.parse(localStorage.getItem('fromDate'));
      let tDate = JSON.parse(localStorage.getItem('toDate'));
      let date = '';

      if(fDate != null && fDate.length>0 ) {
        let tmpFdate = fDate.substring(0,10).split('-');
        tmpFdate = tmpFdate[1] + '/' + tmpFdate[2] + '/' + tmpFdate[0];
        date = tDate ? tmpFdate + ' - ' : 'after ' + tmpFdate;
      }

      if(tDate !=null && tDate.length>0) {
        let tmpTdate = tDate.substring(0,10).split('-');
        tmpTdate = tmpTdate[1] + '/' + tmpTdate[2] + '/' + tmpTdate[0];
        date = fDate ? date + tmpTdate : 'before ' + tmpTdate;
      }
      if(date){this.appliedFilters.push({type: 'date', value: date});}
    }
  }

  clearFilter(filter){
    switch(filter.type) {
      case 'pub': {
        this.selectedPubFilterValues = this.selectedPubFilterValues.filter(pub => pub.title !== filter.value);
        break;
      }
      case 'date': {
        localStorage.removeItem('toDate');
        localStorage.removeItem('fromDate');
        localStorage.removeItem('yearCardSel');
        break;
      }
      case 'st': {
        this.selectedSourceTypeFilterValues = this.selectedSourceTypeFilterValues.filter(st => st !== filter.value);
        break;
      }
      case 'doc': {
        this.selectedDocFilterValues = this.selectedDocFilterValues.filter(doc => doc !== filter.value);
        break;
      }
      case 'db': {
        this.selectedModalDbFilterValues = this.selectedModalDbFilterValues.filter(db => db.name !== filter.value);
        break;
      }

    }
    this.applyFilters();
  }

  applyFilters(){
    
    localStorage.setItem('sourceFilters', JSON.stringify(this.selectedSourceTypeFilterValues));
    localStorage.setItem('docFilters', JSON.stringify(this.selectedDocFilterValues));
    localStorage.setItem('pubFilters', JSON.stringify(this.selectedPubFilterValues));
    localStorage.setItem('dbFilters', JSON.stringify(this.selectedModalDbFilterValues));
    localStorage.setItem('dbDisplayFilters', JSON.stringify(this.selectedDbDisplayValues));

    if(localStorage.getItem('tDate')){
      localStorage.setItem('toDate', localStorage.getItem('tDate')); 
      localStorage.removeItem('tDate');
    }
    if(localStorage.getItem('fDate')){
      localStorage.setItem('fromDate', localStorage.getItem('fDate'));
      localStorage.removeItem('fDate');
    }
    this.triggerFilters();
  }

  triggerFilters() {
    this.isApplyFilterTriggered.emit(true);
  }

  clearAllFilters() {
    this.pubservice.clearFilters();
    this.triggerFilters();
  }

  public displayClearFilters(): boolean | false {
    return this.pubservice.displayClearFilters();

  }

  ngOnDestroy() {
    console.log('FilterPanel component destroyed ---- ngONeDestory method...');
    if(this.dateFilterSubscription != null)
      this.dateFilterSubscription.unsubscribe();
  }

  applySearchFilter(filterValue: string) {
    if(filterValue === this.currentPubSearch){ return }

    this.currentPubSearch = filterValue;

    let copyAllPubs:CheckBoxItem[] =  [...this.pubFacetSelection];


    const searcher = new JsSearch.Search('label');
    searcher.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    searcher.addIndex('value');
    searcher.addIndex('label');
    searcher.addDocuments(copyAllPubs);
    


    /***
     * TODO: What is this doing.????
     * temporarly commenting it out.
     */
    // if(filterValue !=null && filterValue.length == 0) {
    //   this.pubFilterSelection = copyAllPubs;
    //   results = copyAllPubs;
    // } else {
    //   results =  searcher.search(filterValue);
    // }

    //  this.pubFilterSelection =  results;

  }

  clearSearchFilter(event: any) {
    let filterValue =  event;

    if(filterValue !=null && filterValue.length== 0) {
      this.applySearchFilter(filterValue);
    }
  }

  
  showMore(type:string){
    if(type === 'source'){
      this.sourceList = this.sourceFacetSelection;
    } else if (type === 'doc') {
      this.docList = this.docFacetSelection;
    }
  }

  onViewPubs(filterType:string){
    let selectedDbMonikerValues = [];
    let dialogRef = this.dialog.open(PubSelectModalComponent, {
                                       disableClose: false, 
                                       width: '1175px', 
                                       maxWidth:'98vw',  
                                       maxHeight: '90vh', 
                                       data: {
                                        isWb: this.isWb,
                                        filterType: filterType, 
                                        filters: filterType == "pub" ? Object.assign([],this.selectedPubFilterValues) : Object.assign([],this.selectedModalDbFilterValues)
                                      }});
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'pubSelect'){

        this.selectedPubFilterValues = result.data;
        this.applyFilters();
      } else if (result && result.event == 'dbSelect'){
        this.selectedModalDbFilterValues = result.data;
        this.applyFilters();
      }
    })
  }
}

