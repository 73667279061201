import createAuth0Client from '@auth0/auth0-spa-js';
import { from, of, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "./studio-user-signup.service";
import * as i4 from "../workbenchDashboard/workbench.service";
export class AuthService {
    constructor(router, http, studioUserSignupService, workbenchService) {
        this.router = router;
        this.http = http;
        this.studioUserSignupService = studioUserSignupService;
        this.workbenchService = workbenchService;
        this.auth0Client$ = this.loadConfigAndCreateAuthInstance();
        this.tdmUser = {};
        this.loginActivitySubject = new BehaviorSubject(false);
        this.loginActivity$ = this.loginActivitySubject.asObservable();
        this.isAuthenticated$ = this.auth0Client$.pipe(concatMap((client) => from(client.isAuthenticated())), tap((res) => this.loggedIn = res));
        this.handleRedirectCallback$ = this.auth0Client$.pipe(concatMap((client) => from(client.handleRedirectCallback())));
        this.userProfileSubject$ = new BehaviorSubject(null);
        this.userProfile$ = this.userProfileSubject$.asObservable();
        this.loggedIn = null;
        this.studioUser$ = this.http.get("/api/um/user");
        this.analysisUser$ = this.http.get('/api/mum/user');
        this.wbAccessCheck$ = this.workbenchService.getWorkbenchAcessCheck(true, true);
        this.methodsAccessCheck$ = this.workbenchService.createMethodsUser(false);
        this.emailVerified$ = this.getUser$().pipe(switchMap((res) => this.studioUserSignupService.getUserStatus(res.name)));
        this.auth0Client$ = this.loadConfigAndCreateAuthInstance();
    }
    getUser$(options) {
        return this.auth0Client$.pipe(concatMap((client) => from(client.getUser(options))), tap(user => {
            this.userProfileSubject$.next(user);
            console.log('user from Auth0 =', user);
            this.tdmUserData = user; // Setting up user data in userData var
            this.tdmUser = Object.assign({}, user);
            //localStorage.setItem('lastUserEmail', this.tdmUser.email);
        }));
    }
    getTokenSilently$(options) {
        return this.auth0Client$.pipe(concatMap((client) => from(client.getTokenSilently(options))));
    }
    localAuthSetup() {
        const checkAuth$ = this.isAuthenticated$.pipe(concatMap((loggedIn) => {
            if (loggedIn) {
                return this.getUser$();
            }
            return of(loggedIn);
        }));
        checkAuth$.subscribe((response) => {
            console.log('checkAuth =>', response);
            this.loggedIn = !!response;
        });
    }
    login(redirectPath = "/analysis/dashboard") {
        redirectPath = localStorage.getItem("lastDashboard") ? localStorage.getItem("lastDashboard") : redirectPath;
        this.auth0Client$.subscribe((client) => {
            // Call method to log in
            client.loginWithRedirect({
                redirect_uri: `${window.location.origin}/callback`,
                appState: { target: redirectPath }
            });
        });
    }
    handleAuthCallback() {
        let targetRoute; // Path to redirect  after login processsed
        const authComplete$ = this.handleRedirectCallback$.pipe(tap(cbRes => {
            targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/home';
        }), concatMap(() => {
            // Redirect callback complete; get user and login status
            return combineLatest([
                this.emailVerified$,
                this.isAuthenticated$,
                this.wbAccessCheck$.pipe(catchError((err) => {
                    console.log('Error ocured: while wb access check...');
                    return of(null);
                })),
                this.methodsAccessCheck$.pipe(catchError((err) => {
                    console.log('Error occured: while methods access check...');
                    return of(null);
                }))
            ]);
        }));
        /**
         * singupAndAccessInfo$ observable returns objet contains accountAccess information & singupUserInfomation
         *    example: { signupUserInfo, acountAcessInfo }
         *
         */
        authComplete$.subscribe(([emailVerifiedResp, loggedIn, wbAccessCheckResp, methodsAccessCheckResp]) => {
            if (wbAccessCheckResp !== null) {
                this.tdmUser.hasWorkbenchAccessResp = wbAccessCheckResp;
            }
            if (methodsAccessCheckResp !== null)
                this.tdmUser.hasMethodsAccessResp = methodsAccessCheckResp;
            if (emailVerifiedResp !== null)
                this.tdmUser.verifyEmailResp = emailVerifiedResp;
            this.loggedIn = loggedIn;
            localStorage.setItem('user', JSON.stringify(this.tdmUser));
            //this.newTargetRoute = this.navigateToDashboardBasedOnUserType(wbAccessCheckResp,methodsAccessCheckResp,  emailVerifiedResp);
            this.newTargetRoute = this.getNewRouteAfterAuthentication(wbAccessCheckResp, methodsAccessCheckResp, emailVerifiedResp);
            //getNewRouteAfterAuthentication
            this.router.navigate([this.newTargetRoute.routePath], { queryParams: { 'r': this.newTargetRoute.params.value } });
        }, error => {
            console.error("error occured while handling callback", error);
        }, () => {
            // this.router.navigate([this.newTargetRoute]);
        });
    }
    getNewRouteAfterAuthentication(wbAccessCheckResp, methodsAccessCheckResp, emailVerifiedResp) {
        let newRoute = '/home';
        let displayWelcomePage = localStorage.getItem("displayWelcomePage");
        let lastDashboard = localStorage.getItem('lastDashboard');
        let lastUserEmail = localStorage.getItem('lastUserEmail');
        let queryParams = {};
        //                             
        if (this.hasAccessToApp()) {
            //Display welcome page for the first time. 
            if ((!displayWelcomePage || displayWelcomePage === 'true') ||
                (lastUserEmail !== this.tdmUser.email && displayWelcomePage === 'false')) {
                newRoute = '/welcome';
            }
            else if (lastDashboard) {
                if (lastDashboard === '/analysis/dashboard' && !this.hasAccessToVisualization() && this.hasAccessToWorkBench()) {
                    newRoute = '/workbenchdashboard';
                }
                else if (lastDashboard === '/workbenchdashboard' && !this.hasAccessToWorkBench && this.hasAccessToVisualization()) {
                    newRoute = '/analysis/dashboard';
                }
                else {
                    newRoute = lastDashboard;
                }
                //newRoute = (lastDashboard !== 'null' ||  lastDashboard !== 'null') ? localStorage.getItem("lastDashboard") :'/analysis/dashboard';
                this.tdmUser.dashboardPath = newRoute;
                localStorage.setItem("lastDashboard", newRoute);
            }
        }
        else if (emailVerifiedResp != null && emailVerifiedResp.signup_status === 'unverified') {
            newRoute = '/unverified/' + this.tdmUser.email;
        }
        else if (emailVerifiedResp && emailVerifiedResp.auth0_status && emailVerifiedResp.auth0_status === 'exists') {
            newRoute = '/unauthorized';
            queryParams = { 'paramName': 'r',
                'value': 'Your-Account-Is-Not-Signed-Up' };
        }
        localStorage.setItem('user', JSON.stringify(this.tdmUser));
        this.loginActivitySubject.next(this.loggedIn);
        return { routePath: newRoute, params: queryParams };
    }
    logout() {
        this.auth0Client$.subscribe((client) => {
            let user = this.getCurrentlyLoggedInUser();
            let lastDashboard = localStorage.getItem("lastDashboard");
            let displayWelcomePage = localStorage.getItem("displayWelcomePage");
            let lastUserEmail = (user !== null) ? user.email : null;
            localStorage.clear();
            if (lastDashboard)
                localStorage.setItem("lastDashboard", lastDashboard);
            if (displayWelcomePage)
                localStorage.setItem("displayWelcomePage", displayWelcomePage);
            if (lastUserEmail)
                localStorage.setItem('lastUserEmail', lastUserEmail);
            client.logout({
                client_id: this.tdmStudioConfig.clientID,
                returnTo: `${window.location.origin}`
            });
        });
    }
    clearAppSession() {
        let user = this.getCurrentlyLoggedInUser();
        let lastDashboard = localStorage.getItem("lastDashboard");
        let displayWelcomePage = localStorage.getItem("displayWelcomePage");
        let lastUserEmail = (user !== null) ? user.email : null;
        localStorage.clear();
        if (lastDashboard)
            localStorage.setItem("lastDashboard", lastDashboard);
        if (displayWelcomePage)
            localStorage.setItem("displayWelcomePage", displayWelcomePage);
        if (lastUserEmail)
            localStorage.setItem('lastUserEmail', lastUserEmail);
        this.loginActivitySubject.next(false);
        this.router.navigate(['/home']);
    }
    loadConfigAndCreateAuthInstance() {
        //Cache it once if configs value is false. 
        if (!this.config$) {
            this.config$ = this.http.get('/api/ssoidentity/config/TDMStudio')
                .pipe(shareReplay(1));
        }
        return this.config$
            .pipe(tap(() => console.log('config request executed!')), switchMap((config) => {
            this.tdmStudioConfig = config;
            return createAuth0Client({
                domain: config.domainInfo.proquestLogin,
                client_id: config.clientID,
                audience: config.api_audience,
                leeway: 300,
                redirect_uri: `${window.location.origin}/callback`,
                contextHostURL: `${window.location.origin}/`
            });
        }), shareReplay(1));
    }
    getCurrentlyLoggedInUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user;
    }
    hasAccessToWorkBench() {
        let user = this.getCurrentlyLoggedInUser();
        return user &&
            user.hasWorkbenchAccessResp &&
            (user.hasWorkbenchAccessResp.create ||
                user.hasWorkbenchAccessResp.read ||
                user.hasWorkbenchAccessResp.accountType === 'limited');
    }
    preLoadWorkbenchs() {
        let user = this.getCurrentlyLoggedInUser();
        return user &&
            user.hasWorkbenchAccessResp &&
            user.hasWorkbenchAccessResp.read;
    }
    isLimitedSubscription() {
        let user = this.getCurrentlyLoggedInUser();
        return user &&
            user.hasWorkbenchAccessResp &&
            !user.hasWorkbenchAccessResp.create &&
            !user.hasWorkbenchAccessResp.read &&
            (user.hasWorkbenchAccessResp.accountType === 'limited' ||
                user.hasWorkbenchAccessResp.accountType === 'unknown');
    }
    hasAccessToVisualization() {
        let user = this.getCurrentlyLoggedInUser();
        return user &&
            user.hasMethodsAccessResp &&
            user.hasMethodsAccessResp.hasMethodsAccess;
    }
    hasAccessToApp() {
        return this.hasAccessToWorkBench() || this.hasAccessToVisualization();
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.StudioUserSignupService), i0.ɵɵinject(i4.WorkbenchService)); }, token: AuthService, providedIn: "root" });
export class TdmUser {
    constructor(email, firstname, lastname) {
        this.email = email;
        this.firstname = firstname;
        this.lastname = lastname;
    }
}
