import { Component, ComponentFactoryResolver, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Location } from '@angular/common';
import { filter, last, tap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';

import { Router, RouterEvent, NavigationEnd } from '@angular/router';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { IdleTimeOutModalComponent } from './idle-time-out-modal.component';
import { PendoOneTrustLoadService } from './Pendo/pendo-one-trust-load.service';

/**
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'tdms-app',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    isAdmin:boolean = false;
    adminSuscription:Subscription;

    //Fields to store out state so we can display it in the UI
    idleState = "NOT_STARTED";
    countdown?: number = undefined;
    lastPing?: Date = undefined;

    private dialogRef: MatDialogRef<IdleTimeOutModalComponent>

    counter = 0;
    idleTime = 60 * 60 * 5;
    idleTimeOut = 60;

    userAuthenticated$ = combineLatest([this.auth.isAuthenticated$, this.auth.loginActivity$])
                          .pipe(
                            tap(([authenticated, loginActivity]) => {
                              console.log(`isUserAuthenticated value[${authenticated}] loginActivity value [${loginActivity}]`);
                            })
                          );

    constructor(private auth: AuthService, 
                private location:Location, 
                 private router: Router,
                 private idle: Idle,
                 private idleDialog: MatDialog,
                 private pendoOneTrust: PendoOneTrustLoadService) {
        
              this.trackUserIdleState();
      
    }

    public ngOnInit() {

      this.userAuthenticated$.subscribe( (authRes) => {
        if(authRes[0] || authRes[1]) {
          console.log(`User is authenticated= ${authRes}, so starting the IdleWatch...`);
          if(this.auth.getCurrentlyLoggedInUser() !==null)
            this.reset();

        } else {
          console.log(`User is not authenticated= ${authRes}, so not watching IdleWatch...`);
        }
        
      })
      this.auth.localAuthSetup();
      this.getHeader(this.location.path());
    }

    getHeader(url:string){
      if (url.includes('/admin')) {
        this.isAdmin = true;
      } else if(url.includes('/callback')){
        this.router.events.pipe(
          filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe( res => {
            this.getHeader(res.url);
        })
      }
      else {
        this.isAdmin = false;
      }
    }

    public handleContentLink(e: Event) {
        e.preventDefault();
        document.getElementById('content').focus();
    }

    private trackUserIdleState() {
              //set idle parameters
              this.idle.setIdle(this.idleTime); //how long can they be inactive before considered idle, in seconds
              this.idle.setTimeout(this.idleTimeOut); //how long can they be idle before considered timed out, in seconds
              this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); //provde sources that will "interrupt" aka provide events indicating the user is active. 
      
              //do something when the user becomes this.idle.
             this.idle.onIdleStart.subscribe(() => {
              this.idleState = "IDLE";
                console.log('idle time started...')
                if(this.dialogRef) this.dialogRef.close();
                 this.dialogRef = this.idleDialog.open(IdleTimeOutModalComponent,  {
                  width: '550px',
                  data: {idleState: this.idleState, countdown: this.countdown}
                });


                const logoutSub =  this.dialogRef.componentInstance.onLogout.subscribe(() => {
                    this.dialogRef.close();
                    //this.auth.logout();
                    this.idle.stop();
                    this.auth.clearAppSession();
                })

                const extendSub = this.dialogRef.componentInstance.onExtendSession.subscribe(() => {
                  console.log('extending the session ===>');
                  this.dialogRef.close();
                  this.reset();
                });

                this.dialogRef.afterClosed().subscribe(result => {
                  this.counter++;
                  console.log('No.Of Modal windows closed ===', this.counter);
                  console.log('The idledialog was closed....');
                  console.log('Unsubscribing...');
                  logoutSub.unsubscribe();
                  extendSub.unsubscribe();
                });    

              });


      
              //do something when user is no longer this.idle. 
              this.idle.onIdleEnd.subscribe(() => {
                this.idleState = "NOT_IDLE";
                console.log(`${this.idleState} ${new Date()}`)
                //this.dialogRef.close();
                this.countdown = 0;
              });
      
              //do something when the user has timed out
              this.idle.onTimeout.subscribe(() => {
                this.idleState = "TIMED_OUT";
                this.dialogRef.componentInstance.data = {idleState: this.idleState, countdown: this.countdown};
                this.dialogRef.close();
                this.auth.clearAppSession();
                this.idle.stop();
              });
      
              //do something as the timeout countdown does its thing
              this.idle.onTimeoutWarning.subscribe( countdownSeconds => {
                this.idleState = 'You will time out in ' + countdownSeconds + ' seconds!'
                this.dialogRef.componentInstance.data = {idleState: this.idleState, countdown: this.countdown};
      
                this.countdown = countdownSeconds;
              })

    }

    reset() {
      // we'll call this method when we want to start/reset the idle process
      // reset any component state and be sure to call idle.watch()
      this.idle.watch();
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      this.lastPing = null;
    }
    
    public skipNav() {
      document.getElementById('app-content').focus();
    }

    public resetNav(){
      document.getElementById('main-container').focus();
      this.getHeader(this.location.path());
    }

    ngOnDestroy(){
      if(this.adminSuscription){this.adminSuscription.unsubscribe();}
      console.log('TAB IS CLOSED...SETTING TIMESTAMP.')
      localStorage.setItem('tabClosedTime', localStorage.getItem('ng2Idle.main.expiry'));
      
    }

    @HostListener('window:beforeunload')
    setIdleTimeExpiry() {
      localStorage.setItem('expiryTime', localStorage.getItem('ng2Idle.main.expiry'));
    }

    @HostListener('window:load')
    checkSessionExpiry() {
      console.log('App is loading....');
      let expiryTime =  +localStorage.getItem('expiryTime');
      let now =  new Date().getTime();
      if(expiryTime && expiryTime < now ) {
        this.auth.clearAppSession();

      }

    }
}
