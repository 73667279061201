<div class="footer" fxLayout="row" fxLayoutAlign="start center"  fxLayout.xs="column">
    <div class="div-right" fxFlex  fxLayoutAlign="end center" fxLayoutGap="15px" fxLayout.xs="column">
      <div *ngIf="(selectVizCard$ | async) === false">
            <mat-icon color="warn" class="warnIcon">
                warning
            </mat-icon><span class="refine_msg">You will need to select at least one visualization to proceed</span>
      </div>
      <div><button [ngClass]="{ 'button_disabled': (selectVizCard$ | async) === false, 
                                'button': !((selectVizCard$ | async) === false)}" 
                   (click)='navigateToNextStep()' 
                   [disabled]='(selectVizCard$ | async) === false' >Next: Search Content 
            </button>
      </div>
    </div>
</div>
