import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { delay, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/studio-user-signup.service";
export class WorkbenchService {
    constructor(http, userSignupService) {
        this.http = http;
        this.userSignupService = userSignupService;
        this.apiRootForWb = '/api/wb';
        this.apiRootForUm = '/api/um';
        this.triggerCreateWorkBenchEvent$ = new BehaviorSubject(false);
        this.wbCreationInProgress$ = new BehaviorSubject(false);
    }
    getWorkbenches() {
        const apiURL = `{this.apiRootForWb}/workbench`;
        return this.http.get(apiURL);
    }
    getUserInfo(email) {
        const apiURL = `{this.apiRootForUm}/user/` + email;
        return this.http.get(apiURL);
    }
    getWorkbenchAcessCheck(isCreate, isRead) {
        const apiURL = 'api/wbm/access?create=' + isCreate + '&read=' + isRead;
        return this.http.get(apiURL);
    }
    createWorkBench() {
        const apiURL = 'api/wbm/workbench';
        return this.http.post(apiURL, null);
    }
    createWorkBenchMock() {
        const apiURL = 'api/wbm/workbench';
        let createWorkBenchResponse$ = of(['NewWorkbench is creating'])
            .pipe(delay(3000), map(res => ({ "workbenchId": "a27" })));
        return createWorkBenchResponse$;
    }
    //TODO BA: Move this metho to relavent service or rename this service. 
    createMethodsUser(createIfMissing) {
        const apiURL = '/api/mum/user/access?createIfMissing=' + createIfMissing;
        return this.http.get(apiURL);
    }
    createFakeMethodUser() {
        const body = { message: 'user created successfully' };
        //return of(new HttpResponse({ status: 200, body}));
        const obs$ = Observable.create((observer) => {
            observer.next(body);
        });
        return obs$;
    }
    triggerCreateWbEvent(create) {
        this.triggerCreateWorkBenchEvent$.next(create);
    }
    setCreationWbProgress(isProgressing) {
        this.wbCreationInProgress$.next(isProgressing);
    }
}
WorkbenchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkbenchService_Factory() { return new WorkbenchService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StudioUserSignupService)); }, token: WorkbenchService, providedIn: "root" });
