import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountComponent, ExistingAccountModal, NoAccessModal, SubmissionModal } from './create-account.component';
import { EmailExpireComponent } from './email-verification/email-expire.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { SubwayMapComponent } from './subway-map/subway-map.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    ExistingAccountModal,
    NoAccessModal,
    SubmissionModal,
    EmailExpireComponent,
    EmailVerificationComponent,
    SubwayMapComponent,
    CreateAccountComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  entryComponents: [
    ExistingAccountModal,
    NoAccessModal,
    SubmissionModal
  ]
})
export class CreateAccountModule { }
