import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services';

@Component({
  selector: 'tdms-nocontent',
  templateUrl: './no-content.component.html',
  styleUrls: ['./_no-content.component.scss']
})
export class NoContentComponent implements OnInit {

  constructor (
      private _seoSvc: SeoService
  ) {
    this._seoSvc.setTitle('Page Not Found');
  }

  public ngOnInit () {
  }
}
