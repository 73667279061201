import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocLimitModalComponent } from './doc-limit-modal/doc-limit-modal.component';



@NgModule({
  declarations: [DocLimitModalComponent],
  imports: [
    CommonModule
  ],
  entryComponents: [
    DocLimitModalComponent
  ]
})
export class ModalModule { }
