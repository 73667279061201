<div class="pc-container" fxLayout="column">
    <div class="pc-title-bar" fxLayout="row" fxLayoutAlign="space-between stretch">
        <span class="pc-title">Modify Your Selected Publications ({{editedPubList.length}})</span>
        <p class="pc-close" (click)="dialogRef.close({applyChanges:false, selectionList:selected})">n</p>


    </div>

    <div class="pc-content" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
        <span class="pc-deselect" (click)="editedPubList = []">Deselect all</span>
        <div class="pc-table"  fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
            <div class="pc-table-header" fxLayout="row" fxLayoutAlign="space-between1 left1" fxLayoutGap="5px">
                <div class="pc-header-item select-item">Select</div>
                <div class="pc-header-item pub-title">Publication Title</div>
                <div class="pc-header-item date-range">Date Range</div>
                 <div class="pc-header-item publication-id">
                    <div fxLayout="row" fxLayoutAlignment="start space-between" class="info-bubble">
                        <div>Publication ID</div> 
                        <span class="help-cart" (mouseover)="isPubIdTipCart = true" (mouseleave)="isPubIdTipCart = false" >V</span>
                        <div class="tool-tip id-tip-cart" [ngStyle]="(isPubIdTipCart) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                            <p class="tool-label">Publication ID</p>
                            <span class="tool-text">The Publication ID is a unique number to identify the publication. 
                                This can be valuable when working with multiple publications which have the same name 
                                for example different versions of The New York Times.
                            </span>
                        </div>
                    </div>
                </div> 
                <div class="pc-header-item source-type1">Source Type</div>
                <div class="pc-header-item subject1">Subject</div>
            </div>
            <div class="pc-table-scroll">
                <div class="pc-table__content-row" 
                            *ngFor="let pub of selected" 
                            fxLayout="row" 
                            fxLayoutAlign="left center" 
                            fxLayoutGap="5px">
                            
                    <div class="pc-column-content select-item" >
                        <div [ngClass]="editedPubList.includes(pub) ? 'pc-selected' : 'pc-checkbox'" (click)="toggleSelected(pub)">A</div>
                    </div>
                    <div class="pc-column-content pc-pub-title  pub-title"><p class='link-out' (click)='goToPubPage($event, pub.id)'>{{getPublicationTitle(pub)}}</p></div>
                    <div class="pc-column-content date-range" >{{getDateRange(pub)}}</div>
                    <div class="pc-column-content publication-id" >{{pub.id}}</div>
                    <div class="pc-column-content source-type1" >{{getSourceType(pub)}}</div>
                    <div class="pc-column-content subject1" >{{getSubject(pub)}}</div>
                </div>
            </div>
            

        </div>
    </div>

    <div class="pc-button-bar" fxLayout="column" fxLayoutAlign="center center">
        <div class="pc-button" (click)="applyChanges()">Apply Changes</div>
    </div>

</div>
