import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules, ActivatedRouteSnapshot, Routes, RunGuardsAndResolvers } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorService } from './auth/interceptor.service';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { CallbackComponent } from './auth/callback/callback.component';
import { AuthGuard } from './auth/auth.guard';
import { NoContentComponent } from './no-content';
import { WorkbenchResolver } from './workbenchDashboard/workbench.resolver';
import { AdminGuard } from './auth/admin.guard';
import { UnautorizedComponent } from './unautorized/unautorized.component';
import { VizGuard } from './auth/viz.guard';
import { WelcomeComponent } from './welcome/welcome.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { EmailExpireComponent } from './create-account/email-verification/email-expire.component';
import { EmailVerificationComponent } from './create-account/email-verification/email-verification.component';
import { WosGuard } from './auth/wos.guard';


export const alwaysRunGuard:RunGuardsAndResolvers =  'always'

export const ROUTES: Routes = [

  { path: 'home', loadChildren: './home#HomeModule' },
  {
    path: 'workbenchdashboard',
    loadChildren: './workbenchDashboard#WorkbenchDashboardModule',
    canActivate: [AuthGuard],
    resolve: { wb: WorkbenchResolver},
    runGuardsAndResolvers:  alwaysRunGuard     
  },
  
  { path: 'callback', component: CallbackComponent},
  { 
    path: 'dataset',
    loadChildren: () => import('./wb-dataset/wb-dataset.module').then(m => m.WbDatasetModule)
  },
  { 
    path: 'dataset/refinecontent',
    loadChildren: () => import('./wb-dataset/wb-dataset.module').then(m => m.WbDatasetModule)
  },
  {
    path: 'datasetv1',
    loadChildren: () => import('./dataset/dataset.module').then(m => m.DatasetModule)
  },
  {
    path: 'wosdataset',
    loadChildren: () => import('./wos-dataset/wos-dataset.module').then(m => m.WosDatasetModule),
    canActivate: [AuthGuard, WosGuard]
  },
  { path: 'admindashboard', redirectTo: 'admin/dashboard', pathMatch: 'full'},
  { path: 'admin', loadChildren: './adminDashboard#AdminDashboardModule', canActivate: [AdminGuard]},
  { path: 'analysis', loadChildren: './analysis#AnalysisModule', canActivate: [VizGuard]},
  { path: 'unauthorized', component: UnautorizedComponent},
  {path: 'welcome', component: WelcomeComponent},
  { path: 'createaccount', component: CreateAccountComponent },
  { path: 'verify/:email/:vCode', component: EmailVerificationComponent },
  { path: 'unverified/:email', component: EmailExpireComponent },

  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', component: NoContentComponent }
];


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules
    }),
    SharedModule.forRoot(),

  ],
  exports: [RouterModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
  ]
})
export class AppRoutingModule {}
