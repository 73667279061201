<div class="idle">

    <h1 class="idle-header">Session Timeout</h1>
    <mat-divider></mat-divider>

    <mat-dialog-content >
        <!-- <div>Your will be logged out in {{ timer$ | async }} seconds due to inactivity.</div> -->
        <div>Your will be logged out in {{ timer }} seconds due to inactivity.</div>

        <div class="dialog-content">
        </div>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions aria-live="">
        <button mat-raised-button  class="mat-extend" cdkFocusInitial (click)="extendSession()" tabIndex="0" aria-label="Session expired, keep working">Keep Working</button>
        <a href="#"  class="mat-logout"  (click)="logout()" tabIndex="0" aria-label="Log Out">Log Out</a>        
    </mat-dialog-actions>
</div>