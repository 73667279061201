export class CheckBoxItem {
    value: string;
    label?: string;
    noOfItems?: number
    checked: boolean;

    constructor(value: string, label?: string, noOfItems?: number,  checked?: boolean) {
        this.value = value;
        this.label = label;
        this.noOfItems = noOfItems
        this.checked = checked ? checked : false;
    }
}