import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { map } from 'leaflet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tdms-dataset-selection-footer',
  templateUrl: './dataset-footer.component.html',
  styleUrls: ['./dataset-footer.component.scss']
})
export class DatasetFooterComponent implements OnInit, OnChanges, OnDestroy {

  @Input() noOfItemsSelected: number;
  @Input() selectionLabel?: string;
  @Input() select?: string;
  @Input() btnLabel: string;
  @Input() isRefineContent: boolean;
  @Input() isDatabaseContent: boolean;
  @Input() nextStep: string;
  @Input() docLimit: number = 2000000;
  @Output() pubCartEvent = new EventEmitter<boolean>();

  displayDocMsg = false;
  displayWarn = false;

  displayLabel: string;
  SELECTED_LABEL = ' ';
  isVisualization:boolean = false;


  stateObj: PubCartState;

  constructor(private datasetService: DatasetService, private router: Router, private location:Location) { }

  ngOnInit() {
    this.isVisualization = this.location.path().includes('/analysis');
    if (this.isVisualization){this.docLimit = 10000}

    this.stateObj = <PubCartState> JSON.parse(localStorage.getItem('pubCartState'));


  }

  ngAfterViewInit() {

    if(this.stateObj!==null && this.stateObj.showCart) {
      this.showSelected();
      localStorage.removeItem('pubCartState');
    }
  }

  ngOnChanges() {
    this.displayDocMsg =  this.isRefineContent && (this.noOfItemsSelected > this.docLimit || this.noOfItemsSelected <= 0);
    this.displayWarn = this.isRefineContent && this.noOfItemsSelected > this.docLimit;

  }

  getLabel() {
    return this.getSelectionLabelText();
  }

  getFirstThreePubNames() {
    let pubList =  JSON.parse(localStorage.getItem('pubList'));
    let rtnPubNames: string[] = []

    rtnPubNames = pubList.map( pubItem => this.getPublicationTitle(pubItem));

    if(rtnPubNames.length>3){
      return rtnPubNames.slice(0, 3).join(', ').substring(0,130) + '...'
    }

    return rtnPubNames.join(', ').length > 130 ? rtnPubNames.join(', ').substring(0,130) + '...' : rtnPubNames.join(', ');
      
 }

 //<span class="show-selected">Show Selected</span>

  getPublicationTitle(publication:any){
    return publication.field.find(element => element.name === 'title') ? publication.field.find(element => element.name === 'title').value.toString() : '';
  }

  private getSelectionLabelText() {

    if (this.noOfItemsSelected > 1) {
      return this.selectionLabel + 's' + this.select + this.SELECTED_LABEL;
    }
    return this.selectionLabel + this.select + this.SELECTED_LABEL;

  }

  navigateToNextStep(nextStep: string ) {
    //this.datasetService.selectDatasetType(true);

    if ( nextStep === 'RefineContent') {
      this.datasetService.selectNextPage();
      this.router.navigate(['datasetv1/refinecontent_']);
    } else if (nextStep === 'CreatePubdataset') {
      this.datasetService.selectNextPage();
      this.router.navigate(['dataset/createdataset']);
    } else if (nextStep === 'VizCreateDataset') {
      localStorage.setItem("vDocCount", this.noOfItemsSelected.toString());
      this.datasetService.selectNextPage();
      this.router.navigate(['analysis/createdataset']);
    }
  }

  dispalyDocRefineMsg() {
    return this.isRefineContent && this.noOfItemsSelected > this.docLimit;
  }


  search() {
    const randomNum = Math.floor(Math.random() * this.noOfItemsSelected + 1);
    this.noOfItemsSelected = (this.noOfItemsSelected - randomNum) > 0 ? (this.noOfItemsSelected - randomNum) : 0;
    this.ngOnChanges();
  }

  showSelected(){
    this.pubCartEvent.emit(true);
  }

  ngOnDestroy(): void {}

}


