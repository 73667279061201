<div class="wrapper">
    <div *ngFor="let item of options; let ind=index;" class="facetLineItem">        
        <div fxLayout="row" 
            role="checkbox"
            attr.aria-checked="{{item.checked}}"
            attr.aria-label="{{item.label}}"
            (click)="changeItem(item)"
            (keydown.enter)="changeItem(item)"
            tabindex="0">
            <div  [ngClass]="item.checked ? 'checkbox check' : 'checkbox'"></div>  
            <div fxLayout="row" class="facet-label" fxLayoutAlign="space-between end" >
                <div class="chkLabel">{{ item.label }}</div> 
                <div><span class="nOfFacetItems">{{ item.noOfItems }}</span></div>
            </div>
        </div>
        
    </div>
    <div  *ngIf="options.length > 5" class="end-pub-list"></div>
    
</div>