import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, of, from, combineLatest, concat } from 'rxjs';
import { map, toArray, tap, switchMap, catchError, shareReplay } from 'rxjs/operators';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { Facet } from 'src/app/_shared/models/publication-results.model';
import { VizPublicationService } from './analysis-refinement.service';

@Component({
  selector: 'tdms-analysis-refine-content',
  templateUrl: './analysis-refine-content.component.html',
  styleUrls: ['./analysis-refine-content.component.scss']
})
export class AnalysisRefineContentComponent implements OnInit {

  searchField: FormControl;
  error: string ='';
  clearFilter: boolean = false;
  isPublication = false;


  //searchTermSubject and respective action stream...
  private searchTermSubject = new BehaviorSubject<string>("");
  searchTermAction$ =  this.searchTermSubject.asObservable();

  // observable for sourceType and docType filters..
  sTypeFilters$ = this.pubservice.sourceTypeFilter$

  docFilters$ = this.pubservice.docTypeFilter$

  pubFilters$ = this.pubservice.pubFilter$

  displayFilters$ = this.pubservice.displayFilter$;


  //combine all the observables before search request.
  searchRequest$ = combineLatest(this.searchTermAction$, this.sTypeFilters$, this.docFilters$, this.pubFilters$)
                    .pipe(
                      //tap(val => console.log('searchRequest start =>', val)),
                      map(([searchTerm, sTypeFilters, docFilters, pubFilters]) => {
                        return this.pubservice.generateSearchRequest(searchTerm, sTypeFilters, docFilters, pubFilters)
                      }),
                      tap( val => console.log( 'SQ =>', val))
                    );

  //Build resultsSteam based on searchRequest steam.
  docresults$ = this.searchRequest$.pipe(
    tap(searchReq =>  console.log('searchReq =>', JSON.stringify(searchReq))),
    switchMap(searchReq =>
      concat(
        of( { type: 'start'}),
        this.pubservice.search(searchReq)
                        .pipe(
                            map(searchResp => ({ type: 'finish', searchResp})),
                            catchError(err => of( { type: 'finish', err}))
                        )
      )), shareReplay(1)
  );

  constructor(private pubservice: VizPublicationService, private dataSetService: DatasetService) { }


  ngOnInit() {
      this.searchField = new FormControl(localStorage.getItem("searchTerm"));
      this.applyFilters();
      this.isPublication =  true;

  }

  public search(searchValue: string) {
    localStorage.setItem("searchTerm",searchValue);
    this.searchTermSubject.next(searchValue);
  }

  isDocs(docsFound:number):boolean{
    return docsFound > 0;
  }

  pubFacets(facets: Facet[]) {
    const pubfacets = this.pubservice.getPubFacets(facets);
    localStorage.setItem("pubFacets", JSON.stringify(pubfacets));
    return pubfacets;
  }

  sourceFacets(facets: Facet[]) {
    return this.pubservice.getSourceFacets(facets);
  }

  docFacets(facets: Facet[]) {
    return this.pubservice.getDocTypeFacets(facets);
  }

  applyFilters(searchValue?: string) {
    if(searchValue != null) {
      this.searchTermSubject.next(searchValue);
    } else {
      this.searchTermSubject.next(localStorage.getItem("searchTerm") ? localStorage.getItem("searchTerm"): "");
    }
    from(this.docFilterValues())
    .pipe(
      map(val => ({"searchValue": val})),
      tap(val => console.log(val)),
      toArray(),
      map(val => JSON.stringify(val)),
      tap( val => console.log(val))
    ).subscribe(val =>  {
        console.log("docFilter >>>>> ", val)
        this.pubservice.setDocTypeFilterSubject(val)
    })

    from(this.sourceFilterValues())
    .pipe(
        map(val => ({"searchValue": val})),
        tap(val => console.log(val)),
        toArray(),
        map(val => JSON.stringify(val)),
        tap( val => console.log(val))
      ).subscribe(val => {
        console.log("sourceFilter >>>>> ", val)
        this.pubservice.setSourceFilterSubject(val)
      })

      this.applyPubFilter();
      window.scroll(0,0);

  }

  applyPubFilter() {
    from(this.pubFilterValues())
    .pipe(
        map(val => ({"searchValue": val})),
        tap(val => console.log(val)),
        toArray(),
        map(val => JSON.stringify(val)),
        tap( val => console.log(val))
      ).subscribe(val => {
        console.log("pubFilter >>>>> ", val)
        this.pubservice.setPubFilterSubject(val)
      })

  }

  private sourceFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('sourceFilters'));
    if(val !=null && val.length >0)
      return val;
    else
       return [];

  }

  private docFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('docFilters'));
    if(val !=null && val.length >0) {
      return val;
    } else {
       return [];
    }

  }

  private pubFilterValues = () => {
    let val = JSON.parse(localStorage.getItem('pubFilters'));
    if(val !=null && val.length >0) {
      return val;
    } else {
       return [];
    }

  }

  public displayClearFilters(): boolean | false {
    return this.pubservice.displayClearFilters();

  }

  getFooterHeight(numOfDocs:number):number{
    
    if(numOfDocs < 100000){
      return 92;
    }

    const extraDigits = numOfDocs.toString().length - 5;
    const commas = Math.ceil(numOfDocs.toString().length / 3) - 1;

    return 71 + (extraDigits*9) + (commas*5);
  }

}
