import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnalysisAccountInfo, AnalysisAccountResponse, AnalysisUser } from '../analysis/models/account.model';
import { UserStatus } from '../analysis/models/user.model';
import { AccountAccessInfo, SignupUserInfo } from '../workbenchDashboard/workbench.model';

@Injectable({
  providedIn: 'root'
})
export class StudioUserSignupService {

  apiPath = '/api/us';
  userManagerApiPath = '/api/um';

  constructor(private http: HttpClient) { }

  getSignupUserInfo(): Observable<SignupUserInfo> {

    return this.http.get<SignupUserInfo>(this.apiPath + '/user');
                   
  }

  getAccountAccessInfo(accountId: string): Observable<AccountAccessInfo> {
    return this.http.get<AccountAccessInfo>(this.apiPath + '/accounts/'+accountId+'/access');
  }

  getUserAccounts(email:string): Observable<AnalysisAccountResponse> {

    const apiUrl = this.apiPath + '/accounts?email='+encodeURIComponent(email);
    return this.http.get(apiUrl);
  }

  getAccount(acctId:string): Observable<any> {
    const apiUrl = this.apiPath + '/accounts/' + acctId + '/access';
    return this.http.get(apiUrl);
  }

  getUserStatus(email:string): Observable<UserStatus> {
    const apiUrl = this.apiPath + '/user/' + encodeURIComponent(email) + '/status'
    return this.http.get<UserStatus>(apiUrl);
  }

  getStudioUserStatus(email:string): Observable<UserStatus> {
    const apiUrl = this.userManagerApiPath + '/user/' + encodeURIComponent(email) + '/status'
    return this.http.get<UserStatus>(apiUrl);
  }

  createAccount(userData, acctInfo:AnalysisAccountInfo): Observable<any>{
    var userEmail: string = userData.email;
    var user:AnalysisUser = {
      email: userEmail.toLowerCase(),
      emailVerified: false,
      password: userData.password,
      accountId: acctInfo.accountId
    }

    const apiUrl = this.apiPath + '/user';
    return this.http.post(apiUrl, user);
  }

  verifyEmail(email: string, verificationCode: string): Observable<any> {
    const apiUrl = this.apiPath + '/user/' + encodeURIComponent(email) + '/verify?code=' + verificationCode;
    return this.http.get(apiUrl);
  }

  resendVerificationEmail(email: string): Observable<any>{
    const apiUrl = this.apiPath + '/user/' + encodeURIComponent(email) + '/resendverification';
    return this.http.put(apiUrl, null);
  }

}
