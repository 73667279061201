<div ngClass="pubFacetBar" ngClass.md="pubFacetBar-md" ngClass.lt-md="pubFacetBar-ltMd" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="space-evenly start" >
    <h2 ngClass="pubCount" ngClass.md="pubCount-md" ngClass.lt-md="pubCount-ltMd"><span>Selected {{isPublication ? 'Publications' : 'Databases'}} ({{facets.length}}):</span></h2>
        <div class="pubSelected" *ngIf="facets.length <= 3" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
            <div ngClass="pubBubble" ngClass.md="pubBubble-md" ngClass.lt-md="pubBubble-ltMd" *ngFor="let facet of facets">{{getFacet(facet)}}</div>
        </div>
        <div class="pubSelected" *ngIf="facets.length > 3" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
            <div ngClass="pubBubble" ngClass.md="pubBubble-md" ngClass.lt-md="pubBubble-ltMd" >{{getFacet(facets[0])}}</div>
            <div ngClass="pubBubble" ngClass.md="pubBubble-md" ngClass.lt-md="pubBubble-ltMd" >{{getFacet(facets[1])}}</div>
            <div ngClass="pubBubble" ngClass.md="pubBubble-md" ngClass.lt-md="pubBubble-ltMd" >{{getFacet(facets[2])}}</div>
            <div ngClass="pubBubble" ngClass.md="pubBubble-md" ngClass.lt-md="pubBubble-ltMd" >{{facets.length - 3}} More</div>
        </div>
    <div><a  ngClass="pubChange" ngClass.lt-md="pubChange-ltMd" 
             (click)="navigateToPublicationPage()" 
             (keyup.enter)="navigateToPublicationPage()"  tabindex="0">Add/change {{isPublication ? 'publications' : 'databases'}}</a></div>
</div>