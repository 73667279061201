<div class="close-icon">
    <img class="icon" 
    src="../assets/images/close.svg" 
    alt="close icon" 
    role="button"
    aria-label="Close help and learn section" tabindex="0" (keyup.enter)="close()"   (click)="close()">
</div>

<div class="onboard-container">
    <img class="banner-content-img" src="../assets/images/person.png"  role="img" aria-hidden="true" alt="">
    <div class="banner-content-wrapper">
        <div class="banner-content-info-0">
            <div  class="product-questions">Guides</div>
            <a href="https://about.proquest.com/globalassets/proquest/files/toolkits/tdm-quick-guide-2022-nov.pdf" target="_blank" aria-label="Quick Start Guide">Quick Start Guide </a>
            <a href="https://proquest.libguides.com/tdmstudio#s-lg-box-25541893" target="_blank" aria-label="Videos">Videos</a>
            <a href="https://proquest.libguides.com/tdmstudio" target="_blank" aria-label="Documentation (LibGuide)">Documentation (LibGuide)</a>
        </div>                
        <div class="banner-content-info-1">
            <div class="banner-title-1-row">
                 <i class="bi bi-calendar-check"></i>
                 <a class="banner-title-1" href="https://outlook.office365.com/owa/calendar/TDMStudioOnboarding@clarivate.com/bookings/" target="_blank" aria-label="Schedule session booking for TDM Studio Onboarding">Schedule a personalized onboarding session</a>
                 <a href="https://outlook.office365.com/owa/calendar/TDMStudioOnboarding@clarivate.com/bookings/" target="_blank" aria-label="Schedule session booking for TDM Studio Onboarding"><i class="bi bi-box-arrow-up-right"></i></a>
            </div>     
            <div class="banner-text">1. Choose a time to meet with one of our experts.</div>
            <div class="banner-text">2. Join the online session tailored to your project.</div>
            <div class="banner-title-1-row">
                <i class="bi bi-play-btn"></i>
                <a class="banner-title-1" href="https://share.vidyard.com/watch/GdyeQtdPF4uZMtmyVvJtoc?" target="_blank" aria-label="watch the recorded onboarding session">Recorded onboarding session</a>
                <a href="https://share.vidyard.com/watch/GdyeQtdPF4uZMtmyVvJtoc?" target="_blank" aria-label="watch the recorded onboarding session"><i class="bi bi-box-arrow-up-right"></i></a>
           </div>  
        </div>
        <div class="banner-content-info-2">
            <div  class="product-questions">Product Questions</div>
            <a href="https://proquest.libguides.com/ld.php?content_id=74086531" class="email" target="_blank" aria-label="TDM Studio FAQ">TDM Studio FAQ</a>
            <div  class="banner-text padding">Email us at <a href = "mailto: tdmstudio@clarivate.com" class="email" aria-label="Email us at tdmstudio@clarivate.com">tdmstudio@clarivate.com</a></div>
        </div>
    </div>
</div>