import { NgModule } from "@angular/core";
import {
  MatButtonModule,
  MatTableModule,
  MatSortModule,
  MatIconModule,
  MatTooltipModule,
  MatDialogModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatSelectModule,
  MatExpansionModule,
} from "@angular/material";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

const MaterialComponents = [
  MatButtonModule,
  MatTableModule,
  MatIconModule,
  MatTooltipModule,
  MatDialogModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatSelectModule,
  MatExpansionModule,
];

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
})
export class MaterialModule {}
