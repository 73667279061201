import { tap } from 'rxjs/operators';
import { User } from '../workbenchDashboard/workbench.model';
import * as i0 from "@angular/core";
import * as i1 from "../analysis/analysis.service";
import * as i2 from "./auth.service";
import * as i3 from "@angular/router";
export class VizGuard {
    constructor(analysisService, auth, router, route) {
        this.analysisService = analysisService;
        this.auth = auth;
        this.router = router;
        this.route = route;
    }
    canActivate(next, state) {
        if (state.url.indexOf('createaccount') != -1 || state.url.indexOf('verif') != -1) {
            return true;
        }
        return this.auth.isAuthenticated$.pipe(tap(loggedIn => {
            if (!loggedIn) {
                this.auth.login(state.url);
                return false;
            }
            let tdmUser = JSON.parse(localStorage.getItem('user'));
            if ((tdmUser !== null && tdmUser.hasMethodsAccessResp.hasMethodsAccess)) {
                return true;
            }
            else {
                this.router.navigate(['unauthorized'], { relativeTo: this.route, skipLocationChange: true });
                // return false;
            }
        }));
        return true;
    }
}
VizGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VizGuard_Factory() { return new VizGuard(i0.ɵɵinject(i1.AnalysisService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i3.ActivatedRoute)); }, token: VizGuard, providedIn: "root" });
