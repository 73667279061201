<div class="main-container" id="main-container" tabindex="-1">
  <div class="skip-nav-bar">
    <div class="skip-nav" (click)="skipNav()" (keydown.enter)="skipNav()" tabindex="0" role="link" aria-label="Skip navigation">Skip navigation</div>
  </div>
  
  <tdms-admin-header *ngIf="isAdmin" ></tdms-admin-header>
  <tdms-header *ngIf="!isAdmin"></tdms-header>
  <main id="content">
    <router-outlet id="app-content" (activate)="resetNav()" tabindex="-1"></router-outlet>
  </main>
  <tdms-footer tabindex="2"></tdms-footer>
</div>