import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalysisFindContentComponent } from './analysis-dataset/analysis-find-content/analysis-find-content.component';
import { AnalysisRefineContentComponent } from './analysis-dataset/analysis-refine-content/analysis-refine-content.component';
import { AnalysisCreateContentComponent } from './analysis-dataset/analysis-create-content/analysis-create-content.component';
import { AnalysisDashboardComponent } from './analysis-dashboard/analysis-dashboard.component';
import { SelectVizComponent } from './analysis-dataset/select-viz/select-viz.component';
import { AnalysisSearchComponent } from './analysis-dataset/analysis-search/analysis-search.component';



const CREATE_ACCOUNT_ROUTES: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'select', component: SelectVizComponent },
  { path: 'search', component: AnalysisSearchComponent },
  { path: 'search0', component: AnalysisFindContentComponent },
  { path: 'refinecontent', component: AnalysisRefineContentComponent },
  { path: 'createdataset', component: AnalysisCreateContentComponent },
  { path: 'dashboard', component: AnalysisDashboardComponent },
  { path: 'viz', loadChildren: () => import('./viz/viz.module').then(m => m.VizModule) },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(CREATE_ACCOUNT_ROUTES)],
  exports: [RouterModule]
})
export class AnalysisRoutingModule { }
