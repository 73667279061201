import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalysisService } from '../analysis/analysis.service';
import { AccountAccessInfo, User } from '../workbenchDashboard/workbench.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class VizGuard implements CanActivate {

  constructor(private analysisService: AnalysisService, 
              private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute) { }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if(state.url.indexOf('createaccount') != -1 || state.url.indexOf('verif') != -1) { return true;}

    return this.auth.isAuthenticated$.pipe(
      tap(loggedIn => {
        if(!loggedIn) {
          this.auth.login(state.url);
          return false;
        }

        let tdmUser: User = <User>JSON.parse(localStorage.getItem('user'));

        if((tdmUser !== null && tdmUser.hasMethodsAccessResp.hasMethodsAccess)) {
          return true;
        } else {
          this.router.navigate(['unauthorized'], {relativeTo: this.route, skipLocationChange: true })
          // return false;
        }

      }))
    return true;
  }
  
}
