<footer *ngIf="display">
    <div class="bottom_div" fxLayout="row" fxLayoutAlign="space-between">
        <img class="logo_span"  src=" ../../assets/images/pq_clarivate_logo.svg" title="ProQuest logo">
        <div class="link_div" fxLayout="column">
            <span class="link_span">
                <a href={{contactUsUrl}} class="links" title="Contact Us" target="_blank">Contact Us</a>
                <a href={{termsAndConditionsUrl}} class="links" title="Terms and Conditions" target="_blank">Terms and Conditions</a>
                <a href={{accessibilityUrl}} class="links" title="Terms and Conditions" target="_blank">Accessibility</a>
                <!-- OneTrust Cookies Settings button start -->
                <button class="ot-sdk-show-settings btn btn-link btn-action">Cookie Settings</button>
                <!-- OneTrust Cookies Settings button end -->
            </span>
            <span class="link_span">
                <p class="copyright" title="Copyright ©2019 ProQuest LLC.">Copyright ©{{currentYear}} ProQuest LLC.</p>
            </span>
        </div>
       
    </div>
</footer>
