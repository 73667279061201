<div class="facetSection">
    <div fxLayout="column" fxLayoutAlign="start left">
        <h3 ngClass="facetHeader" ngClass.md="facetHeader-md">Date Published</h3>
    </div>    

    <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
            <!-- {{ fromDateC.value  }} -->

            <div>
                <mat-form-field class="dateInput">
                        <mat-label>From date</mat-label>
                        <input matInput [matDatepicker]="fromDatePicker" [formControl]="fromDateC" [max]='maxDate'
                            (input)="inputedFromDate($event.target.value)" 
                            (dateChange) = 'saveDate("fromDate", $event);' >
                        <mat-datepicker-toggle [for]="fromDatePicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePicker [disabled]='false'>  </mat-datepicker>
                        <mat-error *ngIf="fromDateC.invalid">Please enter valid date (mm/dd/yyyy)</mat-error> 
                        <mat-hint></mat-hint>
                </mat-form-field>

            </div>
            <div>to</div>
            <div>   
                <mat-form-field  class="dateInput">
                        <mat-label>To date</mat-label>
                        <input matInput [matDatepicker]="toDatePicker" [formControl]="toDateC"  [min]='toDateMin' [max]='maxDate'
                            (input)="inputedToDate($event.target.value)" 
                            (dateChange) = 'saveDate("toDate", $event)' >
                        <mat-datepicker-toggle [for]="toDatePicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #toDatePicker [disabled]='false'></mat-datepicker>
                        <mat-error *ngIf="toDateC.invalid && toDateC.hasError('matDatepickerParse')">Please enter valid date (mm/dd/yyyy)</mat-error> 
                        <mat-error *ngIf="toDateC.invalid && toDateC.hasError('matDatepickerMin')">End date cannot be earlier than start date</mat-error>
                </mat-form-field>

            </div>

    </div>



</div>
