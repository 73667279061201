<div class="footer" fxLayout="row" fxLayoutAlign="space-between stretch">
    <div class="div-left"  fxLayout="row" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="circle"><div class="circle__value">{{noOfItemsSelected | number }}</div></div>
        <div fxLayout="column" fxLayoutAlign="center" >
          <h3 class="selected" *ngIf="!isRefineContent && !isDatabaseContent">{{getLabel()}}</h3>
          <h3 class="selectedDB" *ngIf="isDatabaseContent || isRefineContent">{{getLabel()}}</h3>
          <span class="selectedPubs" *ngIf="!isRefineContent && !isDatabaseContent">{{getFirstThreePubNames()}}<span class="show-selected" (click)="showSelected()"> Show Selected</span></span>
        </div>
      </div>
    </div>
    <div ngClass="div-right"  fxLayoutAlign="end center" fxLayoutGap="15px" fxLayout.lt-lg="column"  fxLayoutAlign.lt-lg="center end" ngClass.lt-md="div-right-ltMd">
      <div *ngIf="displayWarn" ngClass="warn" ngClass.lt-md="warn-ltMd">
            <mat-icon color="warn" class="warnIcon" ngClass.lt-md="warnIcon-ltMd">
                warning
            </mat-icon><span ngClass="refine_msg" ngClass.lt-md="refine_msg-ltMd" *ngIf="!isVisualization">Please refine your results below 2,000,000 documents to proceed.</span> <span class="refine_msg" *ngIf="isVisualization">Please refine your results below 10,000 documents to proceed.</span>
      </div>
      <div><button [ngClass]="{ 'button_disabled': displayDocMsg, 'button': !displayDocMsg}"  
        (click)='navigateToNextStep(nextStep)' (keyup.enter)='navigateToNextStep(nextStep)' 
        tabindex="0" [attr.aria-label]="btnLabel" [disabled]='displayDocMsg' >{{ btnLabel }}</button></div>
    </div>
</div>
