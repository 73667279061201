<div fxlayout="row" class="container" tabindex="0">
    <div class="items-wrapper">
        <div class="cloud-icon">
            <img src="../../assets/images/cloud_lightning_rain.svg" tabindex="0" alt="An illustration of a cloud with lightning and rain">
        </div>
        <div class="info-msg">
            <div class="info-msg-1" tabindex="0">Sorry, something went wrong.</div>
            <div class="info-msg-2" tabindex="0">Please log in again. If the issues persists, contact <a href = "mailto: tdmstudio@clarivate.com" class="email" aria-label="Email us at tdmstudio@clarivate.com">tdmstudio@clarivate.com</a></div>
        </div>
</div>
