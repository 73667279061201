import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { User } from '../workbenchDashboard/workbench.model';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    canActivate(next, state) {
        return this.auth.isAuthenticated$.pipe(tap(loggedIn => {
            if (!loggedIn) {
                this.auth.login(state.url);
                return false;
            }
            let tdmUser = this.auth.getCurrentlyLoggedInUser();
            if (this.auth.hasAccessToWorkBench()) {
                console.log("user is studio/analysis...User", state.url);
                return true;
            }
            else {
                console.log("User is not authorized to given URL --> ", state.url);
                this.router.navigateByUrl("unauthorized");
                return false;
            }
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
