import { Component, isDevMode, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StudioUserSignupService } from 'src/app/auth/studio-user-signup.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'tdms-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  email: string;
  verificationCode: string;

  isLinkExpired = false;
  isLoading = true;
  analysisSubscription:Subscription;

  constructor(private createAccountService: StudioUserSignupService,
              private route:ActivatedRoute,
              public auth: AuthService) { }

  ngOnInit() {
    this.email = this.route.snapshot.paramMap.get('email');
    this.verificationCode = this.route.snapshot.paramMap.get('vCode');
    
    this.verify();
  }

  verify(){
    if(this.email === "PQ-TestCA4822" && this.verificationCode === "PQ-TestCA4822" ){
      localStorage.setItem("FirstLogin", "FirstLogin");
      this.isLinkExpired = false;
      this.isLoading = false;
      return;
    }

    this.analysisSubscription = this.createAccountService.verifyEmail(this.email, this.verificationCode).subscribe(response => {
      console.log("Response: ", response);
      if(response.resultMessage == 'verified_successfully' || response.resultMessage == 'already_verified'){
        localStorage.setItem("FirstLogin", "FirstLogin");
        this.isLinkExpired = false;
        this.isLoading = false;
      } else {
        this.isLinkExpired = true;
        this.isLoading = false;
      }
    }, (catchError) => {
      console.error(catchError);
      this.isLinkExpired = true;
      this.isLoading = false;
    });

  }

  getTitle(): string {
    return this.isLinkExpired ? "Your account verification email has expired." : "Your email address has been verified.";
  }

  getInfo(): string {
    return this.isLinkExpired ? "Please use the link below to send another verification email to your institutional/university email address." : "Thank you for letting us know that our email reached you successfully.";
  }

  getLinkText(): string {
    return this.isLinkExpired ? "Send verification email" : "Log in to TDM Studio";
  }

  getLink(): string {
    return this.isLinkExpired ? "resendEmail()" : "/analysis/dashboard";
  }

  resendEmail() {
    this.analysisSubscription = this.createAccountService.resendVerificationEmail(this.email).subscribe(response => {
      console.log("success");
    }, (catchError) => {
      console.error("failed to resend email verification link");
    });
  }

}
