<div>
    <mat-spinner [diameter]="20" [strokeWidth]="3" class="spinner-container" *ngIf="isLoading"></mat-spinner>
    <div class="error-info" *ngIf="errMsg"> {{ errMsg.error }}</div>
</div>

<div class="dashboard-wrapper" *ngIf="!isLoading && errMsg == null">
    <div class="container">
        <div class="tour_bar">
            <div class="title">Get Started with TDM Studio</div>
            <div class="text">Use interactive data visualizations to uncover patterns and connections in content from
                newspapers, dissertations, and theses.</div>
        </div>
    </div>

    
    <tdms-analysis-projects></tdms-analysis-projects>
</div>