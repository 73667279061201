import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'tdms-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  docTabs:string[] = ['Visualization Dashboard', 'Create a Project', 'Geographic Analysis', 'Topic Modeling', 'Sentiment Analysis'];
  selected:string = this.docTabs[0];

  constructor(public dialogRef: MatDialogRef<DocumentationComponent>, @Inject(MAT_DIALOG_DATA) public data: {tab: string} ) { }

  ngOnInit() {
    if(this.data.tab){this.selected = this.data.tab}
  }

}
