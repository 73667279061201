<div class="wrapper">
    <div *ngFor="let item of options | slice:0:7; let ind=index;" class="facetLineItem">        
        <div fxLayout="row" 
            role="checkbox"
            attr.aria-label="{{item.label}}"
            attr.aria-checked="{{item.checked}}"
            (mouseover)="handleMouseOver(c)" 
            (mouseleave)="handleMouseLeave(c)" 
            (focus)="handleMouseOver(c)" 
            (blur)="handleMouseLeave(c)" 
            (keydown.enter)="changeItem(item)"
            tabindex="0">
            <div [style.visibility]="isCheckBoxVisible ? 'visible' : 'hidden'" class="chk" #c>
                <mat-checkbox color="primary" tabindex="-1"
                    [(ngModel)]="item.checked"
                    (change)="onToggle()" >
                </mat-checkbox>
            </div>
            <div fxLayout="row" 
            (click)="changeItem(item)">
                <div class="chkLabel">{{ item.label }} <span class="nOfFacetItems">({{ item.noOfItems }})</span></div>
            </div>
        </div>

    </div>
    <div *ngIf="showMore" role="link" (click)="showAll()" (keydown.enter)="showAll()" class="showMore" tabindex="0" attr.aria-label="Show {{(options.length - 7)}} more options)">Show More ({{(options.length - 7)}}) ></div>
    <div>
        <ng-template [ngIf]='all'>
            <div *ngFor="let item of options | slice:7: options.length; let ind1=index;" class="facetLineItem">
                <div fxLayout="row"
                    role="checkbox"
                    attr.aria-label="{{item.label}}"
                    attr.aria-checked="{{item.checked}}"
                    (mouseover)="handleMouseOver(c1)" 
                    (mouseleave)="handleMouseLeave(c1)" 
                    (focus)="handleMouseOver(c1)" 
                    (blur)="handleMouseLeave(c1)" 
                    
                    (keydown.enter)="changeItem(item)"
                    tabindex="0">
                    <div [style.visibility]="isCheckBoxVisible ? 'visible' : 'hidden'" class="chk" #c1>
                        <mat-checkbox color="primary" 
                            [(ngModel)]="item.checked"
                            (change)="onToggle()">
                        </mat-checkbox>
                    </div>
                    <div fxLayout="row" (click)="changeItem(item)">
                        <div class="chkLabel">{{ item.label }} <span class="nOfFacetItems">({{ item.noOfItems }})</span></div>
                    </div>
        
                </div>            
            </div>
        </ng-template>    
    </div>
    
</div>