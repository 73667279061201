import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalysisService } from 'src/app/analysis/analysis.service';

@Component({
  selector: 'tdms-select-viz-footer',
  templateUrl: './select-viz-footer.component.html',
  styleUrls: ['./select-viz-footer.component.scss']
})
export class SelectVizFooterComponent implements OnInit {

  selectVizCard$ = this.analysisService.selectViz$;

  constructor(private router: Router,
              private analysisService: AnalysisService) { }


  ngOnInit() {
  }

 
  navigateToNextStep() {
    this.router.navigate(['analysis/search']);
  }

}