import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';

import { type } from 'os';
import { nextTick } from 'process';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, range, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, shareReplay, tap, toArray } from 'rxjs/operators';
import { setFlagsFromString } from 'v8';
import { CorpusDetails } from '../models/viz.models';
import { Feature, GeoJsonResponse, GeoTimeLineWrapper } from './viz.interfaces';
import * as geojson from 'geojson';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeovizService {

  geoJsonData; //geojson.GeoJsonObject
  geoJsonLayer;
  markers;
  mapElement = null;
  markerClusterGroup:L.MarkerClusterGroup;
  showList;
  popup: L.Popup;
  private timeLineValue: [number, number];
  aggregateListSubject = new BehaviorSubject([]);



  selectedClusterSubject=  new BehaviorSubject([]);
  displayDrawerSubject =  new BehaviorSubject(false);
  displayDropdownSubject =  new BehaviorSubject(false);
  
  errorMsg: string;

  isLoadingMapDataSubject = new BehaviorSubject<boolean>(false);
  isLoadingGISCorpusSubject = new BehaviorSubject<boolean>(false);



  constructor(private http: HttpClient, private authService: AuthService) { 
     window['showList'] = () => {
       this.aggregateListSubject.next(this.showList);
     }

  }

  public setShowList(list) {
    this.showList = list;
    if(list !=null && list.length >0)
      this.displayDrawerSubject.next(true)

    this.selectedClusterSubject.next(this.showList);
  }

  public restShowList() {
    this.showList = [];
    this.selectedClusterSubject.next(this.showList);
    this.displayDrawerSubject.next(false)
  }

  public openDropDown(){
    this.displayDropdownSubject.next(true);
  }
  
  public closeDropDown(){
    this.displayDropdownSubject.next(false);
  }

  public getGeoCorpusInfo(corpusId: string): Observable<any> {
    return  this.http.post<CorpusDetails>('/api/gm/corpus/'+corpusId,null)
  }

  public getTopicCorpusInfo(corpusId: string): Observable<any> {
    return  this.http.post<CorpusDetails>('/api/tm/corpus/'+corpusId,null)
  }

  public getSentimentCorpusInfo(corpusId: string): Observable<any> {
    return  this.http.post<CorpusDetails>('/api/sa/corpus/'+corpusId,null)
  }

  public getGeoDataByCorpusId(corpusId: string): Observable<any> {

   return  this.http.get<CorpusDetails>('/api/gm/corpus/'+corpusId)
      .pipe(
        concatMap(corpus => {
          console.log( 'actuall CT ['+ corpus.fileCount+']');
          let fileNum = corpus.fileCount;
          console.log( 'rendered CT ['+ fileNum+']');

          let observables1 = [];
          for(let i=0; i<fileNum; i++) {
              observables1.push(this.http.get<GeoJsonResponse>('/api/gm/corpus/'+corpusId +'/file/'+i))
          }
          if(corpus.status !== 'completed') {
            return throwError(new Error('Project is not ready to visualize. Please contact your system administrator.'))
          } else {
            return forkJoin(observables1)
                    .pipe(
                      //tap(results => console.log('before reduce ...> '+results.length + ...results[0])),
                    // map(results => results.reduce( (all, itm) => all.concat(itm.data), [])),
                      tap(data => console.log('value ....'+data))
                    )
          }        

        }), shareReplay(1)
      )

  }

  public getTimeLineData(data: GeoJsonResponse[]): Observable<GeoTimeLineWrapper> {

    const inData: GeoTimeLineWrapper = {
       maxTime: Number.MIN_SAFE_INTEGER,
       minTime: Number.MAX_SAFE_INTEGER,
       mapData: {},
       time: []
    };

    let minTime: number = Number.MIN_SAFE_INTEGER;
    let maxTime: number = Number.MAX_SAFE_INTEGER;
    for(let i=0; i<data.length; i++) {
      data[i].features.forEach(feature => {
        if('time' in feature.properties){
          if(!(+(feature['properties']['time']) in inData['mapData'])) {          
            let time:number = +feature.properties.time;
            inData.time.push(time);
            inData['mapData'][time] = { features: [] }
          }
          const time = +(feature['properties']['time']);
          maxTime = Math.max(maxTime, time);
          minTime = Math.min(minTime, time);
          inData['mapData'][+(feature.properties.time)]['features'].push(feature);
    }});
    }
    inData['minTime'] = minTime;
    inData['maxTime'] = maxTime;

    inData.time.sort();
    return of(inData);
  }

  //New methods:
  updateTimeLine(range: [number, number]) {
    this.timeLineValue = range;
    //this.mapElement = mapElement;
    this.initializeGeoJsonLayer();
  }

  initializeGeoJsonLayer() {
    const self = this;
    if (self.geoJsonLayer) {
      self.mapElement.removeLayer(self.geoJsonLayer);
      self.mapElement.removeLayer(self.markers);
      self.geoJsonLayer = null;
      self.markers = null;
    }
    //self.geoJsonLayer = self.setUpGeoJsonLayer(self.geoJsonData);
    self.geoJsonLayer = L.geoJSON(self.geoJsonData, {

      filter: function(feature) {
          
          if(self.timeLineValue) {
            //alert(self.timeLineValue[0] + ', '+ self.timeLineValue[1]);
            return feature.properties.time >= self.timeLineValue[0] && feature.properties.time <= self.timeLineValue[1];
          } else {
            return true;
          }
      },

      pointToLayer: function(feature, latlang) {

        return L.circleMarker(latlang, {
          color: '#1D1F91',
          opacity: 0.87,
          weight: 2,
          fillColor:  '#1D1F91',
          fillOpacity: 0.87,
          radius: 6
        });
      },

      onEachFeature(feature, layer) {
        //bind click event;
        layer.on('click', function(e) {
          let f = e.target;
          let tmpArray = [];
          tmpArray.push(f);
          //tmpArray[0]= {"type": f.type, "geometry": f.geometry, "properties": f.properties};
          self.setShowList(tmpArray);
          
        })
   
       // alert(feature);
      //  self.showList.push(feature);
      //  alert(self.showList);
      //  self.selectedClusterSubject.next(self.showList);
      }

     });


    //self.markers =  L.markerClusterGroup(self.getClusterOptions());

    //Create markers clustergroup;
    self.markers = L.markerClusterGroup({
                      showCoverageOnHover: false,
                      animate: false,
                      chunkedLoading: true,
                      zoomToBoundsOnClick: false,
                      spiderfyOnMaxZoom: false,
                      removeOutsideVisibleBounds: false,
                      //chunkDelay: 500,
                      animateAddingMarkers: false,
                      chunkInterval: 7,
                      iconCreateFunction: function(cluster) {
                        let count =  cluster.getChildCount();
                        let maxCount =  count > 5000 ? 5000 : count;
                        let digits =  (count + '').length;
                        let icon: L.PointExpression;
                        return L.divIcon(
                          {
                          html: count+'',
                          className: 'cluster',
                          iconSize: new L.Point((10 + (maxCount * 0.0475)), (10 + (maxCount * 0.0475)))
                        })
                      }
                    });
    //self.markers = self.markerClusterGroup;


    self.markers.addLayer(self.geoJsonLayer);
    self.markers.on('clustermouseover1', function (a) {
      //self.showList = a.layer.getAllChildMarkers();
      self.popup = L.popup({
        maxHeight: 400,
        className: 'aggregatePopup',
        closeButton: false
      }).setLatLng(a.layer.getLatLng())
        .setContent(`<div><button class="show-list-button" onclick="window.showList()">View List</button></div>`)
        //.openOn(self.mapElement);
    });

    self.markers.on("clusterclick", function(e) {
      self.showList = e.layer.getAllChildMarkers();
      self.setShowList(e.layer.getAllChildMarkers());
    });

    self.mapElement.addLayer(self.markers);
    setTimeout(function(){ self.mapElement.invalidateSize()}, 400);

    self.mapElement.invalidateSize();

  }

  initAggregateMap(geoJsonData: GeoJsonResponse[],
    timeLineValue: [number, number],
    mapElement: any) {
    this.geoJsonData = geoJsonData;
    this.mapElement = mapElement;
    this.timeLineValue = timeLineValue;
    this.initializeGeoJsonLayer();
  }

  private setUpGeoJsonLayer(data: any): L.GeoJSON {
    const self = this;
    return L.geoJSON(data, {

      filter: function(feature) {
          
          if(self.timeLineValue) {
            //alert(self.timeLineValue[0] + ', '+ self.timeLineValue[1]);
            return feature.properties.time >= self.timeLineValue[0] && feature.properties.time <= self.timeLineValue[1];
          } else {
            return true;
          }
      },

      pointToLayer: function(feature, latlang) {

        return L.circleMarker(latlang, {
          color: '#1D1F91',
          opacity: 0.87,
          weight: 2,
          fillColor:  '#1D1F91',
          fillOpacity: 0.87,
          radius: 6
        });
      },

      onEachFeature(feature, layer) {
        alert(feature);
        self.showList.push(feature);
        alert(self.showList);
        self.selectedClusterSubject.next(self.showList);
      }
     })

     this.mapElement.invalidateSize();

  }

  private getClusterOptions(): L.MarkerClusterGroupOptions {

    const markerClusterOptions: L.MarkerClusterGroupOptions = {
      showCoverageOnHover: false,
      animate: false,
      chunkedLoading: true,
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      removeOutsideVisibleBounds: true,
      //chunkDelay: 500,
      animateAddingMarkers: false,
      chunkInterval: 7,
      iconCreateFunction: function(cluster) {

        let count =  cluster.getChildCount();
        let digits =  (count + '').length;
        let icon: L.PointExpression;
        return L.divIcon(
          {
          html: count+'',
          className: 'cluster digits-' + digits,
          //className: 'cluster',
          iconSize:  null

        })
      }

    };
    return markerClusterOptions;
  }

  getPQToken():Observable<{ pqtoken: string}> {

    return  this.http.get<{ pqtoken: string}>('/api/mum/user/pqtoken')
                     .pipe(
                       tap( val => console.log('pqtoken -->', val))
                     );

    return of();
  }

  openDocview(docId: string){
    let host = window.location.hostname;
    let baseUrl = 'https://www.proquest.com/docview/';
    const key = 'tokenObj';
    let lapseTime = 23 * 60 * 60 * 1000;   //23 hrs lapse time
    let testingLapseTime =  5 * 1000; // 5 seconds;


    if(host.indexOf('.pre') >0 || 
          host.indexOf('.dev') >0 ||
          host.indexOf('localhost') === 0 ||
          host.indexOf('127.0.0.1') === 0) {
      baseUrl = 'https://search.pre.proquest.com/docview/';
    }


    let pqToken = this.getWithExpiry(key);
    const token$ =  this.getPQToken();

    const analysisUser$ =  this.authService.analysisUser$.pipe(
                              catchError((err) => {
                                return of(null)
                              }));

    if(pqToken === null ) {

      combineLatest(token$, analysisUser$).subscribe(([tokenRes, userRes]) => {
        pqToken = tokenRes.pqtoken;
        localStorage.setItem('acctId', userRes.accountId);
        this.setWithExpiry('tokenObj', pqToken, lapseTime);
        let docUrl = baseUrl +docId+'/embedded/'+pqToken+ this.getLinkOutSuffix();
        window.open(docUrl, '_blank');
      })

    } else {
      let docUrl = baseUrl +docId+'/embedded/'+pqToken + this.getLinkOutSuffix();
      window.open(docUrl, '_blank');
    }
  }

  getLinkOutSuffix() {
    let acctId =  localStorage.getItem('acctId');
    let suffix = '?accountid=';
    if(acctId) {
      return suffix+acctId;
    } 
    return '';
  }

  setWithExpiry(key: string, value: string, ttl: number) {
    const now = new Date();
    const tokenObj =  {
      value: value,
      expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(tokenObj));
  }

  getWithExpiry(key: string) {
    const tokenStr =  localStorage.getItem(key);

    if(!tokenStr) {
      //This check to delete the old docview token from local storage... since code base in transistion from simple token storage
      //to object (token + expirtytime);
      if(localStorage.getItem('dt') !=null) localStorage.removeItem('dt');
      return null;
    }

    const tokenObj =  JSON.parse(tokenStr);
    const now = new Date();

    if(now.getTime() > tokenObj.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return tokenObj.value;
  }





}
