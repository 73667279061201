<div fxLayout="row" fxLayoutAlign="space-between center" class="dm_header">
    <h1 class="dm_title" color="primary">{{projects.length > 1 ? 'Delete Datasets?' : 'Delete Dataset?'}}</h1>
    <i class="bi bi-x" (click)="dialogRef.close()" (keydown.enter)="dialogRef.close()" tabindex="0" role="button" aria-label="close"></i>
</div>
<div class="dm_content dialog-header dialog-footer">
    <p tabindex="0">All dataset visualizations will be deleted. This may take several minutes to complete.</p>
    <br>
    <p *ngFor="let dataset of projects" tabindex="0" class="dm_datasets"><b>{{dataset.name}}</b></p>
</div>
<div class="dm_footer" fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button class='dm_delete_btn' color="primary" (click)="onDelete()" (keydown.enter)="onDelete()" tabindex="0" role="button">Delete</button>
    <p class="dm_cancel" (click)="dialogRef.close()" (keydown.enter)="dialogRef.close()" tabindex="0" role="link">Cancel</p>
</div>
