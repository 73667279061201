import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, combineLatest, forkJoin, from, of } from 'rxjs';
import { WorkbenchDataService } from '../services/workbench-dashboard/workbench-data.service';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WosGuard implements CanActivate {

  activeWorkBenchId$ = this.wbmService.currentWorkbenchId$;
  wosAccessCheck$ =  this.activeWorkBenchId$.pipe(
    switchMap( wbId =>  {
      let workbenchId = wbId;
      console.log('1. wosGuard [workbenchId]=', workbenchId);
      if(wbId === 'default workbenchId' )
        workbenchId =  localStorage.getItem('workbench');
       console.log('2. wosGuard [workbenchId]=', workbenchId);

      return this.wbmService.hasAccessToWos(workbenchId)
    })
  );


  constructor(private wbmService: WorkbenchDataService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | 
                                 Promise<boolean | UrlTree> | boolean | UrlTree {

   return this.wosAccessCheck$.pipe(
      tap( canAccess =>  {
        console.log('canAccess from wosGuard => ', canAccess);
        if(canAccess) return true;
        
        this.router.navigateByUrl("unauthorized");

      })
   )
  }
  
}
