/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./analysis-find-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "./analysis-find-content.component";
import * as i6 from "@angular/router";
var styles_AnalysisFindContentComponent = [i0.styles];
var RenderType_AnalysisFindContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnalysisFindContentComponent, data: {} });
export { RenderType_AnalysisFindContentComponent as RenderType_AnalysisFindContentComponent };
function View_AnalysisFindContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ((i1.ɵnov(_v.parent, 8).value = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["n"]))], null, null); }
export function View_AnalysisFindContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "container"], ["fxLayout", "column"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "searchLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What are you researching?"])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "filter_bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "search_div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [["filterText", 1]], null, 0, "input", [["class", "search"], ["onblur", "this.placeholder = 'Enter search here...'"], ["onfocus", "this.placeholder = ''"], ["placeholder", "Enter search here..."], ["type", "text"]], null, [[null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.enter" === en)) {
        var pd_0 = (_co.submitSearch(i1.ɵnov(_v, 8).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalysisFindContentComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "filter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitSearch(i1.ɵnov(_v, 8).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [";"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 3, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).value !== ""); _ck(_v, 10, 0, currVal_2); }, null); }
export function View_AnalysisFindContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tdms-analysis-find-content", [], null, null, null, View_AnalysisFindContentComponent_0, RenderType_AnalysisFindContentComponent)), i1.ɵdid(1, 114688, null, 0, i5.AnalysisFindContentComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnalysisFindContentComponentNgFactory = i1.ɵccf("tdms-analysis-find-content", i5.AnalysisFindContentComponent, View_AnalysisFindContentComponent_Host_0, {}, {}, []);
export { AnalysisFindContentComponentNgFactory as AnalysisFindContentComponentNgFactory };
