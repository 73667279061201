import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "../services/workbench-dashboard/workbench-data.service";
import * as i3 from "@angular/router";
export class AdminGuard {
    constructor(auth, wbService, router) {
        this.auth = auth;
        this.wbService = wbService;
        this.router = router;
    }
    canActivate(next, state) {
        return forkJoin([this.auth.isAuthenticated$, this.wbService.getUserInfo().pipe(catchError(error => of(error)))]).pipe(map(([loggedIn, UserInfo]) => {
            if (!loggedIn) {
                this.auth.login(state.url);
                return false;
            }
            else if (UserInfo.pqadmin !== "true") {
                this.router.navigateByUrl("/workbenchdashboard");
                return false;
            }
            return UserInfo.pqadmin === "true";
        }));
    }
}
AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.WorkbenchDataService), i0.ɵɵinject(i3.Router)); }, token: AdminGuard, providedIn: "root" });
