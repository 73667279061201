<div class="SA_header">
    <div class="close_div">
        <a href="/home"><p class="close">n</p></a>
    </div>
    <h1 class="SA_title">Success! You’ve Created Your Account.</h1>
</div>

<mat-dialog-content >
    <div class="EA_content">
        <p class="EA_text">You have successfully created a TDM Studio Account—A link has been sent to your institution/university email address. Please use this link to confirm your account and then you will be able to login to TDM Studio.</p>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <a href="/home"><button mat-raised-button class="SA_button" color="primary" >Ok, got it!</button></a>
</mat-dialog-actions>