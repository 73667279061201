import { Component, OnInit } from '@angular/core';
import { AnalysisService } from '../../analysis.service';

@Component({
  selector: 'tdms-select-viz',
  templateUrl: './select-viz.component.html',
  styleUrls: ['./select-viz.component.scss']
})
export class SelectVizComponent implements OnInit {

  geoCardCheck: boolean = false;
  tmCardCheck: boolean = false;
  saCardCheck: boolean = false;

  constructor(private analysisService: AnalysisService) { }

  ngOnInit() {
    this.loadCardSelection();
  }

  toggleGeoCardSelection(checked: boolean) {
    console.log('toggleGeoCardSelection ---> '+checked);
    this.geoCardCheck = checked;
    this.storeCardSelection();
    this.emmitCardSelection();
  }

  toggleTmCardSelection(checked: boolean) {
    console.log('toggleTmCardSelection ---> '+checked);
    this.tmCardCheck = checked;
    this.storeCardSelection();
    this.emmitCardSelection();
  }

  toggleSaCardSelection(checked: boolean) {
    console.log('toggleSaCardSelection ---> '+checked);
    this.saCardCheck = checked;
    this.storeCardSelection();
    this.emmitCardSelection();
  }

  emmitCardSelection() {
    this.analysisService.setVizCardSelection(this.geoCardCheck || this.tmCardCheck || this.saCardCheck);
  }

  private storeCardSelection() {
    let obj = {'geoCardSelection': this.geoCardCheck, 'tmCardSelection': this.tmCardCheck, 'saCardSelection': this.saCardCheck};
    localStorage.setItem('vizcardselection',JSON.stringify(obj));
  }

  private loadCardSelection() {
    let obj = JSON.parse(localStorage.getItem('vizcardselection'));

    this.geoCardCheck =  obj !==null ? obj.geoCardSelection : true;
    this.tmCardCheck =   obj !==null ? obj.tmCardSelection : true;
    this.saCardCheck =   obj !==null ? obj.saCardSelection : true;
  }

}
