import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/auth.service";
export class PendoService {
    constructor(http, auth) {
        this.http = http;
        this.auth = auth;
        this.currentUserData = null;
        this.oldUserData = null;
        this.currentSiteData = null;
        this.oldSiteData = null;
        this.PENDO_URL = '/api/pendo/';
        this.DEFAULT_VISITOR_ID = 'VISITOR-UNIQUE-ID';
        this.targetingCookie = true;
        this.$tdmUser = this.auth.loginActivity$;
    }
    /**
     * Initalize Pendo.  If we have an old id in the session, use that, or else init as a new user.  Update the flags too since this could be a refresh or a hard refresh from changing functional cookies.
     *
     * Clear session and flush seem to be a little heavy handed here, but it's what it took to get Pendo and one trust compliance in order.
     */
    initialize(functionalCookiesEnabled, targetingCookieValue) {
        if (functionalCookiesEnabled) {
            this.clearSession();
            this.initalizeNewUser(targetingCookieValue);
            this.flush();
            this.updateTargetingCookieValue(targetingCookieValue);
            this.updateDoNotProcess(false); // Functional cookies should only be enabled if this is called, so this should always be false
        }
    }
    /**
     * Wrapper for pendo.clearSession();
     */
    clearSession() {
        if (pendo && pendo.clearSession) {
            pendo.clearSession();
        }
    }
    /**
     * Pendo flushNow wrapper.  This should send all queued events to Pendo.
     */
    flush() {
        if (pendo && pendo.flushNow) {
            pendo.flushNow();
        }
    }
    /**
     * Another pendo wrapper.
     */
    initializePendo(visitorAndAccountData) {
        pendo.initialize(visitorAndAccountData);
    }
    /**
     * Determine if pendo is ready or not (init has happened and is setup properly)
     */
    isReady() {
        return pendo && pendo.isReady && pendo.isReady();
    }
    /**
     * Identify a visitor in pendo.
     *
     * @param visitor identifies a singular user.  Should be using account id as id.  Non-logged in users should be anonymous
     * @param account accounts are collections of visitors.  Using siteId as id to group users by site
     */
    identify(visitor, account) {
        pendo.identify({
            visitor,
            account
        });
    }
    /**
     * Update the doNotProcess flag.  This should call the service if it's changed
     */
    updateDoNotProcess(doNotProcess) {
        this.pendoDoNotProcessRequest(doNotProcess);
        if (doNotProcess) {
            pendo.stopGuides();
            pendo.stopSendingEvents();
            pendo.flushNow();
            console.log('Pendo Service - Updating do not process');
        }
        else {
            pendo.startGuides();
            pendo.startSendingEvents();
            console.log('Pendo Service - Updating to process');
        }
    }
    pendoDoNotProcessRequest(doNotProcess) {
        let url = this.PENDO_URL + "visitor/pendo/value/" + pendo.getVisitorId() + "/donotprocess";
        this.http.put(url, doNotProcess).subscribe(resp => {
            console.log(resp);
        }, error => {
            console.log(error);
        });
    }
    updateMetadataRequest(metadataValue) {
        let url = this.PENDO_URL + "visitor/custom/value/" + pendo.getVisitorId() + "/tdm_targetingcookie";
        this.http.put(url, metadataValue).subscribe(resp => {
            console.log(resp);
        }, error => {
            console.log(error);
        });
    }
    /**
     * Update the targeting cookie value.  The targeting value passed in should relate to whether the value is on or off
     */
    updateTargetingCookieValue(targetingValue) {
        console.log('Pendo Service - Updating targeting cookie value');
        const metadataValue = targetingValue ? 'YES' : 'NO';
        if (targetingValue !== this.targetingCookie) {
            this.updateMetadataRequest(metadataValue);
            setTimeout(() => location.reload(), 1000);
        }
    }
    /**
     * Initalize for a new user.  This should happen the first time the app is used by a user.
     */
    initalizeNewUser(targetingCookieValue) {
        let id = undefined;
        let email = undefined;
        let accountId = undefined;
        this.$tdmUser.subscribe((activity) => {
            this.tdmUser = this.auth.getCurrentlyLoggedInUser();
            if (this.tdmUser !== null) {
                email = this.tdmUser.email;
                id = this.tdmUser.nickname;
                accountId = this.tdmUser.sub.slice(6);
            }
            let visitorData = this.getEmptyVisitorObject(id, email, targetingCookieValue);
            let accountData = this.getAccountObject(accountId, window.location.host !== 'tdmstudio.proquest.com' ? 'dev' : 'prod');
            let cookieDomain = { cookieDomain: '.proquest.com' };
            this.targetingCookie = targetingCookieValue;
            this.initializePendo({ visitorData, accountData, cookieDomain });
            this.identify(visitorData, accountData);
        });
    }
    /**
     * Determine if site data changed.  If we have old and new site data, then compare ids.  Else, return if one of them is true.
     */
    siteDataChanged() {
        return this.oldSiteData && this.currentSiteData ?
            this.oldSiteData.siteId === this.currentSiteData.siteId :
            this.oldSiteData || this.currentSiteData;
    }
    /**
     * Passing in undefined here instead of using a blank object, since this will actually give you an anonymous user id.
     *
     * I know this says empty, but we're passing in visitorId.  The reason is that if we keep identifying as an anonymous user, pendo gives us a new id.
     */
    getEmptyVisitorObject(visitorId, visitorEmail, targetingCookieValue) {
        visitorId = visitorId || this.DEFAULT_VISITOR_ID;
        visitorEmail = visitorEmail || undefined;
        return this.getVisitorObject(visitorId, visitorEmail, targetingCookieValue);
    }
    /**
     * Get the visitor object.  A visitor represents a singular user.  Note: the comments in the method are from pendo.
     *
     * Any new fields we want to track should be added below the comments, just to make sure we separate them from the reserved pendo ones.
     */
    getVisitorObject(visitorId, visitorEmail, targetingCookieValue) {
        return {
            id: visitorId,
            email: visitorEmail,
            tdm_targetingcookie: targetingCookieValue ? "YES" : "NO"
        };
    }
    /**
     * Get the account object.  An account represents a group of users.  Note: the comments in the method are from pendo.
     *
     * Any new fields we want to track should be added below the comments, just to make sure we separate them from the reserved pendo ones.
     */
    getAccountObject(accountId, siteStatus) {
        const dev = (siteStatus === 'dev');
        return {
            id: accountId,
            dev: dev
        };
    }
}
PendoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PendoService_Factory() { return new PendoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: PendoService, providedIn: "root" });
