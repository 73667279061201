import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StudioUserSignupService } from 'src/app/auth/studio-user-signup.service';

@Component({
  selector: 'tdms-email-expire',
  templateUrl: './email-expire.component.html',
  styleUrls: ['./email-expire.component.scss']
})
export class EmailExpireComponent implements OnInit {

  email: string;

  isLoading = true;
  analysisSubscription:Subscription;

  constructor(private signupService: StudioUserSignupService,
              private route:ActivatedRoute,) { }

  ngOnInit() {
    this.email = this.route.snapshot.paramMap.get('email');
    this.isLoading = false;
  }

  resendEmail() {
    this.analysisSubscription = this.signupService.resendVerificationEmail(this.email).subscribe(response => {
      console.log("success");
    }, (catchError) => {
      console.error("failed to resend email verification link");
    });
  }

}
