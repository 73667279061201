<div class="container column">

    <h2 class="title">Create a Project</h2>
    <p class="sub_text">The first time you log in, your project dashboard will be empty. To create a project and start your analysis, select <b>Create New Project</b></p>
    <img class="img_center img_size" src="../../../../../assets/images/Documentation/create-main.png" alt="Create new project" >

    <div class="info_tab" (click)="toggleTab(1)">
        <div class="tab_bar">
            <h3 class="tab_title">Search topic</h3>
            <div [ngClass]="isOpen(1) ? 'up_arrow' : 'down_arrow'"></div>
        </div>
        
        <div class="tab_content" *ngIf="isOpen(1)">
            <p class="tab_text">Enter the terms you are interested in researching and select the search icon. You can use symbols (e.g. “ ” for exact match or * as truncation) or common search words (e.g. AND, OR, NOT) to add precision to your search. TDM Studio has the same search capabilities as the ProQuest Platform. To find out more see the <a href="https://proquest.libguides.com/proquestplatform/tips">Platform search tips</a> in the ProQuest LibGuides. </p>
            <img class="img_right img_size" src="../../../../../assets/images/Documentation/create-search.png" alt="Search topic" >
        </div>
    </div>

    <div class="info_tab" (click)="toggleTab(2)">
        <div class="tab_bar">
            <h3 class="tab_title">Refine search</h3>
            <div [ngClass]="isOpen(2) ? 'up_arrow' : 'down_arrow'"></div>
        </div>
        
        <div class="tab_content column" *ngIf="isOpen(2)">
            <p class="tab_text">The refine content page shows the number of documents your search retrieved and provides a sample of the articles. Data visualizations in TDM Studio searches up to 10 newspaper titles depending on your library’s subscriptions. The following titles will be included if your library subscribes to them:</p>
            <div class="row">
                <ul class="tab_text list_space">
                    <li>Chicago Tribune</li>
                    <li>The Globe and Mail</li>
                    <li>The Guardian</li>
                    <li>Los Angeles Times</li>
                    <li>The New York Times</li>
                    <li>South China Morning Post</li>
                    <li>The Sydney Morning Herald</li>
                    <li>The Times of India</li>
                    <li>The Wall Street Journal</li>
                    <li>The Washington Post</li>
                </ul>
                <img class="img_right img_size" src="../../../../../assets/images/Documentation/create-refine.png" alt="Refine search" >
            </div>
            <p class="tab_text">If your search returns more than 10,000 documents, there will be a notification that you need to refine your result set until it is equal to or fewer than 10,000 documents. You can either refine your search terms or apply filters, such as a date range, to bring the result set under 10,000 documents.</p>
        </div>
    </div>

    <div class="info_tab" (click)="toggleTab(3)">
        <div class="tab_bar">
            <h3 class="tab_title">Filter search</h3>
            <div [ngClass]="isOpen(3) ? 'up_arrow' : 'down_arrow'"></div>
        </div>
        
        <div class="tab_content column" *ngIf="isOpen(3)">
            <div class="row">
                <div class="column">
                    <div class="top">
                        <p class="tab_text">Options for filtering datasets: </p>
                        <ul class="tab_text list">
                            <li>Uncheck the box associated with a publication title to deselect</li>
                            <li>Enter a date range in date published filter and select <b>Apply Filters</b></li>
                            <li>Select specific document types (e.g. only news articles) and select <b>Apply Filters</b></li>
                        </ul>
                        <p class="tab_text">Once you have a result set that is fewer than 10,000 documents select the <b>Next: Review project</b> to move to the final step of creating your project.</p>
                    </div>
                    <img class="img_left img_size" src="../../../../../assets/images/Documentation/create-doc.png" alt="Filter search" >
                </div>
                <img class="img_right img_filter" src="../../../../../assets/images/Documentation/create-filters.png" alt="Filter search bar">
            </div>
            
            
        </div>
    </div>

    <div class="info_tab" (click)="toggleTab(4)">
        <div class="tab_bar">
            <h3 class="tab_title">Review <b>created project</b></h3>
            <div [ngClass]="isOpen(4) ? 'up_arrow' : 'down_arrow'"></div>
        </div>
        
        <div class="tab_content" *ngIf="isOpen(4)">
            <p class="tab_text">After viewing the project submission message, you will be returned to the Visualization dashboard while your analysis is created. It will take approximately thirty minutes for the project to be available for viewing but the length of time depends on the analysis type and the number of documents in your project.</p>
            <img class="img_right img_size" src="../../../../../assets/images/Documentation/create-review.png" alt="Review created project" >
        </div>
    </div>

</div>
