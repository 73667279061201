import { Component, EventEmitter, OnInit } from '@angular/core';
import { interval, Observable, forkJoin, Subscription, of } from 'rxjs';
import { VizPublicationService } from '../../analysis-dataset/analysis-refine-content/analysis-refinement.service';
import { VizCorpusResponse, VizProject } from '../../models/project.model';
import { Router } from '@angular/router';
import { GeovizService } from '../../viz/geoviz.service';
import * as _m from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tdms-analysis-projects',
  templateUrl: './analysis-projects.component.html',
  styleUrls: ['./analysis-projects.component.scss']
})
export class AnalysisProjectsComponent implements OnInit {

  maxProjects = 10;

  yourProjects:VizProject[] = [];
  selectedProjects:string[] = [];
  isLoading = true;
  corpusSubscription:Subscription;
  projectSubscription:Subscription;
  deleteSubscription:Subscription;
  geoSubscription:Subscription;
  pollingDeleteSubscription:Subscription;
  pollingCreateSubscription:Subscription;
  hover:string = '';
  failedHover:string = '';

  constructor(private pubService: VizPublicationService, private router: Router, private dialog: MatDialog, private geoService:GeovizService) { }

  ngOnInit() {
    if(localStorage.getItem('vizCreated')){
      this.isLoading = true;
      this.pollProjectsForCreation();
    } else {
      this.getProjects();
    }
    
  }

  onDestroy(){
    if(this.corpusSubscription){this.corpusSubscription.unsubscribe();}
    if(this.projectSubscription){this.projectSubscription.unsubscribe();}
    if(this.deleteSubscription){this.deleteSubscription.unsubscribe();}
    if(this.geoSubscription){this.geoSubscription.unsubscribe();}
    
    if(this.pollingDeleteSubscription){this.pollingDeleteSubscription.unsubscribe();}
    if(this.pollingCreateSubscription){this.pollingCreateSubscription.unsubscribe();}
  }

  selectProject(project:VizProject){
    if(this.selectedProjects.includes(project.id)){
      this.selectedProjects = this.selectedProjects.filter(projectId => projectId != project.id);
    } else {
      this.selectedProjects.push(project.id);
    }
  }

  isLoadingComplete() {
    if(!this.isLoading) {
      this.sortProject(this.isLoading);
      return true;
    }
    return false;
  }

  isInProgress(project:VizProject):boolean{
    if(project.status === "queued"){
      return true;
    }
    if( (this.isGeoAnalysis(project) && this.getGeoStatus(project) === "queued") ||
        (this.isTopicAnalysis(project) && this.getTopicStatus(project) === "queued") ||
        (this.isSentimentAnalysis(project) && this.getSentimentStatus(project) === "queued") ) {
      return true;
    }
    return false;
  }

  goToCreation(){
    this.router.navigateByUrl("analysis/select")
    this.clearStorage();
  }
  

  isGeoAnalysis(project:VizProject):boolean{
    if(project.methods && project.methods.method && project.methods.method.some(method => method.methodType === "geography")){
      return true;
    }
    return false;
  }

  isTopicAnalysis(project:VizProject):boolean{
    if(project.methods && project.methods.method && project.methods.method.some(method => method.methodType === "topic")){
      return true;
    }
    return false;
  }

  isSentimentAnalysis(project:VizProject):boolean{
    if(project.methods && project.methods.method && project.methods.method.some(method => method.methodType === "sentiment")){
      return true;
    }
    return false;
  }

  isAllSelected():boolean{
    return this.selectedProjects.length == this.yourProjects.length;
  }

  isChecked(projectId:string):boolean{
    return this.selectedProjects.indexOf(projectId) > -1;
  }

  selectAllProjects(){
    let yourProjectsIds = [];
    if (!this.isAllSelected()){
      for(let project of this.yourProjects){
        yourProjectsIds.push(project.id);
      }
      this.selectedProjects = yourProjectsIds;
    } else {
      this.selectedProjects = [];
    }
  } 

  getMethodId(project:VizProject, methodType:string):string{
    if( project.methods && 
        project.methods.method && 
        project.methods.method.find(method =>  method.methodType === methodType)) {
        
          return project.methods.method.find(method => method.methodType === methodType).id;
    }
    return '';
  }

  getProjectCount():number{
    return this.yourProjects.filter((project:VizProject) => project.status !== 'deleting').length
  }

  getProjects(){
    this.yourProjects = [];
    this.corpusSubscription = this.pubService.getProjects().subscribe(projectList => {
      if(projectList.corpus.length < 1){
        this.isLoading = false;
      } else {
        projectList.corpus.forEach(corpus => {
          this.getProductInfo(corpus.id, projectList.corpus.length);
        });
      }
      },
      (error => {
        console.log(error);
        this.isLoading = false;
      }));
  }

  getProductInfo(corpusId:string, corpusListLength:number){
    this.projectSubscription = this.pubService.getProjectsDetails(corpusId).subscribe(project => {
      if(project.status === 'dataloaded'){
        this.setStatus(project, corpusListLength);
      }else{
        this.yourProjects.push(project);
        this.isLoading = this.yourProjects.length < corpusListLength;
      }
    },
    (error => {
      console.log(error);
      this.isLoading = false;
      this.sortProject(this.isLoading);
      }));
  }

  pollProjectsUntilDeleted(deletedProjectIds: string[]) {
  
    this.pollingDeleteSubscription = interval(5000).pipe(
      switchMap(() => this.pubService.getProjects()) 
    ).subscribe((response: VizCorpusResponse) => {
      const remainingProjects = response.corpus.filter(project => deletedProjectIds.includes(project.id));
      if (remainingProjects.length === 0) {
        console.log("All deleted projects are no longer in the response.");
        this.pollingDeleteSubscription.unsubscribe();
        this.isLoading = true;
        this.getProjects();
      }
    }, (error) => {
      console.log("Error while polling projects: ", error);
      this.pollingDeleteSubscription.unsubscribe();
    });
  }

  pollProjectsForCreation() {

    this.pollingCreateSubscription = interval(5000).pipe(
      switchMap(() => this.pubService.getProjects()) 
    ).subscribe((response: VizCorpusResponse) => {
      const projects = response.corpus.filter(project => localStorage.getItem('vizCreated') === project.name);
      if (projects.length > 0) {
        console.log("New project is in the response.");
        this.pollingCreateSubscription.unsubscribe();
        localStorage.removeItem('vizCreated');
        this.getProjects();
      }
    }, (error) => {
      console.log("Error while polling projects: ", error);
      this.pollingCreateSubscription.unsubscribe();
    });
  }

  sortProject(isLoading: boolean) {
    if(!isLoading) {
      let tmpProject = this.yourProjects;
      tmpProject.sort((a,b) => {
        let dateA = new Date(a.dateCreated).getTime();
        let dateB =  new Date(b.dateCreated).getTime();
        return dateB > dateA ? 1 : -1;
      });   
      this.yourProjects = tmpProject; 
    }
  }

  setStatus(project:VizProject, corpusListLength:number){

    let methodInfoRequests:Observable<any>[] = [];
    if(this.isGeoAnalysis(project)){methodInfoRequests.push(this.geoService.getGeoCorpusInfo(this.getMethodId(project, "geography")).pipe(catchError(e => of({status:"failed"})))) }
    if(this.isTopicAnalysis(project)){ methodInfoRequests.push(this.geoService.getTopicCorpusInfo(this.getMethodId(project, "topic")).pipe(catchError(e => of({status:"failed"})))) }
    if(this.isSentimentAnalysis(project)){ methodInfoRequests.push(this.geoService.getSentimentCorpusInfo(this.getMethodId(project, "sentiment")).pipe(catchError(e => of({status:"failed"})))) }

    this.geoSubscription = forkJoin(methodInfoRequests).subscribe(methodInfo => {
      let i = 0;
      if(this.isGeoAnalysis(project)){ project.methods.method.find(method => method.methodType === "geography").status = methodInfo[i].status; i++; }
      if(this.isTopicAnalysis(project)){ project.methods.method.find(method => method.methodType === "topic").status = methodInfo[i].status; i++; }
      if(this.isSentimentAnalysis(project)){ project.methods.method.find(method => method.methodType === "sentiment").status = methodInfo[i].status; i++;}

      this.yourProjects.push(project);
      this.isLoading = this.yourProjects.length < corpusListLength;

    },(error => {
      if(this.isGeoAnalysis(project)){ project.methods.method.find(method => method.methodType === "geography").status = "failed" }
      if(this.isTopicAnalysis(project)){ project.methods.method.find(method => method.methodType === "topic").status = "failed" }
      if(this.isSentimentAnalysis(project)){ project.methods.method.find(method => method.methodType === "sentiment").status = "failed"}

      this.yourProjects.push(project);
      this.isLoading = this.yourProjects.length < corpusListLength;
      console.log(error);
    }))

  }

  
  getGeoStatus(project: VizProject): string {
    return this.isGeoAnalysis(project) ? project.methods.method.find(method => method.methodType === "geography").status : '';
  }

  getTopicStatus(project: VizProject): string { 
    return this.isTopicAnalysis(project) ? project.methods.method.find(method => method.methodType === "topic").status : '';
  }

  getSentimentStatus(project: VizProject): string {
    return this.isSentimentAnalysis(project) ? project.methods.method.find(method => method.methodType === "sentiment").status : '';
  }


  getPublications(project:VizProject):string[]{
    if(project.search.filters && project.search.filters.find(filter => filter.name === "pubTitle")){
      return project.search.filters.find(filter => filter.name === "pubTitle").entries.map(pub => pub.searchValue);
    } 
    return [];
  }

  getPubs(project:VizProject):string[]{
    if(project.search.filters && project.search.filters.find(filter => filter.name === "pubTitlesToDisplay")){ 
      return project.search.filters.find(filter => filter.name === "pubTitlesToDisplay").entries.map(pub => pub.searchValue);
    }
    return [];
  }

  getDbs(project:VizProject):string[]{
    if(project.search.filters && project.search.filters.find(filter => filter.name === "datasourcesToDisplay")){
      return project.search.filters.find(filter => filter.name === "datasourcesToDisplay").entries.map(db => db.searchValue);
    }
    return [];
  }

  getDateRange(project: VizProject): string {
    if(project.minDate && project.maxDate){
      return _m(project.minDate).format('MMM DD, YYYY') + ' to ' + _m(project.maxDate).format('MMM DD, YYYY');
    } else if (project.minDate){
      return 'After ' + _m(project.minDate).format('MMM Do, YYYY');
    } else if(project.maxDate){
      return 'Before ' + _m(project.maxDate).format('MMM Do, YYYY');
    }
    return '';
  }

  getDate(project: VizProject): string {
    return _m(project.dateCreated).format('MMM DD, YYYY');
  }

  goToGeoAnalysis(project:VizProject){
    var methodId:string = this.getMethodId(project, "geography");
    if (!localStorage.getItem("geo-map-data-" + methodId)){
      var proj = {
        projectName: project.name,
        count: project.docCount,
        startDate: project.maxDate ? project.minDate : '',
        endDate: project.maxDate ? project.maxDate : '',
      }
      localStorage.setItem("geo-map-data-" + methodId, JSON.stringify(proj));
    }
    
    this.router.navigateByUrl("analysis/viz/geoanalysis/" + methodId);
  }

  goToTopicAnalysis(project:VizProject){
    var methodId:string = this.getMethodId(project, "topic");
    if (!localStorage.getItem("tm-map-data-" + methodId)){
      var proj = {
        projectName: project.name,
        count: project.docCount,
        startDate: project.maxDate ? project.minDate : '',
        endDate: project.maxDate ? project.maxDate : '',
      }
      localStorage.setItem("tm-map-data-" + methodId, JSON.stringify(proj));
    }
    
    this.router.navigate(["analysis/viz/tm/", methodId]);
  }

  goToSentimentAnalysis(project: VizProject) {
    var methodId:string = this.getMethodId(project, "sentiment");
    if (!localStorage.getItem("sa-map-data-" + methodId)){
      var proj = {
        projectName: project.name,
        count: project.docCount,
        startDate: project.maxDate ? project.minDate : '',
        endDate: project.maxDate ? project.maxDate : '',
      }
      localStorage.setItem("sa-map-data-" + methodId, JSON.stringify(proj));
    }
    this.router.navigate(['analysis/viz/sa', methodId ]);
  }

  deleteSelected(){
    this.delete(this.yourProjects.filter(project => this.selectedProjects.indexOf(project.id) > -1));
  }
  

  delete(projects:VizProject[]){
    const dialogRef: MatDialogRef<AnalysisDeleteModal> = this.dialog.open(AnalysisDeleteModal, { disableClose: true, width: '600px', maxWidth:'850px' });
    dialogRef.componentInstance.projects = projects;
    dialogRef.componentInstance.deleteEvent.subscribe((deleteProjects:VizProject[]) => {
      this.isLoading = true;
      this.selectedProjects = [];
      let deletionCalls = []
      deleteProjects.forEach( project => {
        localStorage.removeItem("geo-map-data-" + this.getMethodId(project, "geography"));
        deletionCalls.push(this.pubService.deleteProject(project.id));
        this.yourProjects.find(dataset => dataset.id === project.id).status = 'deleting';
      });
      this.deleteSubscription = forkJoin(deletionCalls).subscribe(response => {
        console.log("Project Deletion Success");
        this.isLoading = false;
        this.pollProjectsUntilDeleted(projects.map(project => project.id));
      }, (error => {
        if(error.status === 200){
          console.log("Project Deletion Success", error);
          this.isLoading = false;
          this.pollProjectsUntilDeleted(projects.map(project => project.id));
        } else {
          console.log("Project Deletion Error: ", JSON.stringify(error));
          this.isLoading = true;
          this.getProjects()
        }
      }));
    });
    
  }

  clearStorage(){

      var clearKeys:string[] = [ 
        "datasetType", 
        "docCount", 
        "length", 
        "selectedDbList", 
        "sReq", 
        "pubList", 
        "searchValue", 
        "sReqWos", 
        "pubFilters", 
        "docFilters", 
        "sourceFilters", 
        "selectedPubs", 
        "yearCardSel",
        "fromDate",
        "toDate"];
    clearKeys.forEach(key => {
      localStorage.removeItem(key);
    })      

    let obj = {
      geoCardSelection: true,
      tmCardSelection: true,
      saCardSelection: true,
    }
    localStorage.setItem('vizcardselection', JSON.stringify(obj));

    this.pubService.clearFilters();

  }

  private clearPreviousSelections() {
    var clearKeys:string[] = [ 
        "datasetType", 
        "docCount", 
        "length", 
        "selectedDbList", 
        "sReq", 
        "pubList", 
        "searchValue", 
        "sReqWos", 
        "pubFilters", 
        "docFilters", 
        "sourceFilters", 
        "selectedPubs", 
        "yearCardSel",
        "fromDate",
        "toDate"];
    clearKeys.forEach(key => {
      localStorage.removeItem(key);
    })      

    this.pubService.clearFilters();
  }

  getInteractablityStatus(project:VizProject):boolean{
    if(project.status === 'failed' || project.status == 'queued'){
      return true;
    }
    if (project.status == 'dataloaded'){
      if(this.isGeoAnalysis(project) && (this.getGeoStatus(project) === 'completed' || this.getGeoStatus(project) === 'failed')){
        return true;
      }
      if(this.isTopicAnalysis(project) && (this.getTopicStatus(project) === 'completed' || this.getTopicStatus(project) === 'failed')){
        return true;
      }
      if(this.isSentimentAnalysis(project)){
        return true;
      }
      return false;
    }
    return false;
    
  }

}

@Component({
  selector: 'analysis-delete-modal',
  templateUrl: 'analysis-delete-modal.html',
  styleUrls: ['./analysis-projects.component.scss']
})
export class AnalysisDeleteModal {
  projects:VizProject[]
  deleteEvent = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<AnalysisDeleteModal>){}

  onDelete() {
    this.deleteEvent.emit(this.projects);
    this.dialogRef.close();
  }


}
