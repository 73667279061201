import { Injectable } from '@angular/core';
import { PendoService } from './pendo.service';

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {

  constructor(private pendoService: PendoService) {
  }

  /**
   * Bind the one trust wrapper to the window.  This allows one trust to call our angular service code.
   */
  public bindWrapperToWindow() {
    // making the service wrapper available on a global level, bound to an angular context.  Allows us to hit angular services outside of angular.
    window['OptanonWrapper'] = this.oneTrustServiceWrapper.bind(this);
  }

  /**
   * This should be the globaly avaliable function that the one trust callback hits.  Determines which cookies are enabled to setup pendo or to remove the Google Tag Manager script
   */
  public oneTrustServiceWrapper() {
    const currentCookieValues = this.getCurrentCookieValues();
//    console.log('One Trust - Wrapper values', currentCookieValues);
    if (currentCookieValues) {
      if (!this.pendoService.isReady() && currentCookieValues['functional']) {
        this.setupPendo(currentCookieValues['functional'], currentCookieValues['targeting']);
      } else {
        this.updatePendoFlags(currentCookieValues['functional'], currentCookieValues['targeting']);
      }
      setTimeout(() => {
        this.reloadPageIfNeeded(currentCookieValues);
      }, 1000);
    }
  }

  /**
   * Setup pendo values. Update targeting cookie value and the doNotProcess flag
   */
  private setupPendo(functionalCookiesEnabled, targetingCookiesEnabled) {
    this.pendoService.initialize(functionalCookiesEnabled, targetingCookiesEnabled);
  }

  /**
   * Update Pendo flags (targeting cookie value and do not process)
   */
  private updatePendoFlags(functionalCookiesEnabled, targetingCookiesEnabled) {
//    console.log('One Trust - Updating Pendo flags');
    if (this.pendoService.isReady()) {
//      console.log('One Trust - Updating dnp and targeting cookies');
      this.pendoService.updateDoNotProcess(!functionalCookiesEnabled);  // doNotProcess is a negative.  It implies that functional cookies are not enabled, hence the !
      this.pendoService.updateTargetingCookieValue(targetingCookiesEnabled);
    } else {
//      console.log('One Trust - Pendo was not ready, did not update flags');
    }
  }

  /**
   * Determine if targeting or performace cookies were turned off by comparing their last two values.
   * If they were turned off (was true, now false), then reload the page to clear the GTM script from being enabled.
   */
  private reloadPageIfNeeded(currentCookieValues) {
    const previousCookieValues = this.getPreviousCookieValues();
    if (previousCookieValues && ((previousCookieValues['performance'] && !currentCookieValues['performance']) || (previousCookieValues['functional'] && !currentCookieValues['functional']))) {
      setTimeout(()=> location.reload(), 1000);
    }
  }

  /**
   * Get the current cookie values from one trust
   */
  private getCurrentCookieValues() {
    const groupsArray = window.dataLayer.filter((item: any) => item.event === 'OneTrustGroupsUpdated');
    console.log(groupsArray)
    if (groupsArray.length > 0 && groupsArray[groupsArray.length - 1]['OnetrustActiveGroups']) {
      return this.getCookieValues(groupsArray[groupsArray.length - 1]);
    }
  }

  /**
   * Get the previous cookie values from one trust
   */
  private getPreviousCookieValues() {
    const groupsArray = window.dataLayer.filter((item: any) => item.event === 'OneTrustGroupsUpdated');
    if (groupsArray.length > 1 && groupsArray[groupsArray.length - 2].OnetrustActiveGroups) {
      return this.getCookieValues(groupsArray[groupsArray.length - 2]);
    }
  }

  /**
   * Get cookie values from the given one trust categories list
   */
  private getCookieValues(nonSplitCategoriesList: any) {
    const cookieValues = {};
    const categoriesList = nonSplitCategoriesList['OnetrustActiveGroups'].split(',');
    cookieValues['functional'] = categoriesList.some((value) => value === 'C0003');
    cookieValues['targeting'] = categoriesList.some((value) => value === 'C0004');
    cookieValues['performance'] = categoriesList.some((value) => value === 'C0002');
    return cookieValues;
  }

}

declare global {
  interface Window { dataLayer: any; }
}